const gradientMap = [
	["#4CB1FF", "#0082E5"],
	["#A73737", "#9C3333"],
	["#FF8008", "#FF9214"],
	["#1D976C", "#93F9B9"],
];

export default (canvas, { radius, borderWidth, courseIndex, greyedOut }) => {
	const context = canvas.getContext("2d");

	if (greyedOut) context.strokeStyle = "grey";
	else {
		const gradient = context.createLinearGradient(
			0,
			0,
			radius * 2,
			radius * 2
		);
		gradient.addColorStop(0, gradientMap[courseIndex][0]);
		gradient.addColorStop(1, gradientMap[courseIndex][1]);

		context.strokeStyle = gradient;
	}

	context.lineCap = "round";
	context.lineWidth = borderWidth;

	return context;
};
