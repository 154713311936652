import breakpoint from "../../breakpoint";
import styled from "styled-components";

export const HeaderWrapper = styled.div`
	width: 100%;
	padding-top: 15px;
`;

export const Title = styled.h6`
	margin: 0;
	padding: 0;
	padding-bottom: 14px;
	grid-column: 1/5;
	grid-row: 1;
	align-self: end;

	font-family: "Cerebri Sans";
	font-size: 18px;
	line-height: 21px;

	${breakpoint("md")`
    grid-column: 1/5;
    font-size: 24px;
    line-height: 28px;
	padding-bottom: 9px;
`};

	${breakpoint("lg")`
    grid-column: 1/10;
    font-size: 24px;
    line-height: 28px;
	padding-bottom: 9px;
`};
`;

export const ButtonWrapper = styled.div`
	grid-column: 5/-1;
	grid-row: 1;
	text-align: right;
	padding-bottom: 14px;
	${breakpoint("md")`
  		grid-column: 5/-1;
		padding-bottom: 9px;
  	`};

	${breakpoint("lg")`
      	grid-column: 10/-1; 
		padding-bottom: 9px; 
  	`};
`;

export const Underline = styled.span`
	grid-column: 1 / -1;
	grid-row: 1;
	border-bottom: 1px solid #f4f1ee;
	display: block;

	${breakpoint("md")`
  	`};

	${breakpoint("lg")` 
  	`};
`;

export const ContentWrapper = styled.div`
	/* padding: 65px 0; */
`;

export const BaseWrapper = styled.div`
	/* position:fixed;
overflow: hidden;
bottom: 0;
z-index: 1000; */
	margin: 0;
	/* padding: 34px 0; */
	background: #111112;

	/* ${breakpoint("md")`
    padding: 57px 0;
`};
 
${breakpoint("lg")`
    padding: 57px 0;
`}; */
`;

export const BaseRightWrapper = styled.div`
	display: inline-block;
	text-align: right;

	grid-column: 6/-1;

	${breakpoint("lg")`
    grid-row: 1;  
    grid-column: 12/13; 
`};
`;

export const BaseHideWrapper = styled.div`
	display: none;
	text-align: right;

	grid-column: 5/6;

	${breakpoint("md")`
    display: inline-block;
  
  `};

	${breakpoint("lg")`
    display: inline-block;
    grid-column: 11/12;
    grid-row: 1;
         
`};
`;

export const Wrapper = styled.div`
	background: ${({ light }) => (light ? "  #F4F1EE" : "#111112")};
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	/* min-height: 100vh; */
	color: ${({ light }) => (light ? "#111112" : "#F4F1EE")};

	${Underline} {
		border-color: ${({ light }) => (light ? "#111112" : "#F4F1EE")};
	}
`;

export const HeadroomPadding = styled.div`
	height: 100px;
	${breakpoint("md")`
        height: 147px;
    `}
	${breakpoint("lg")`
        height: 147px;
    `}
`;

export const HeadroomButtonsPadding = styled.div`
	padding: 34px 0;
	${breakpoint("lg")`
		padding: 57px 0;
    `}
`;
