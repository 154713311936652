import {
  SectionHeading,
  SectionTitle,
  StoryParagraph,
  StoryTitle,
} from "../components/text";
import Container from "../components/Container";

import Grid from "../components/Grid";
import React from "react";
import TeamMembers from "../blocks/TeamMembers";
import Testimonials from "../blocks/Testimonials";
import breakpoint from "../breakpoint";
import fetchOurStory from "../scripts/get/aboutOurStory";
import styled from "styled-components";
import {
  BookNavigationButtons,
  NextLogo,
  NextPreviousButton,
  PreviousLogo,
  StartButton,
} from "../pages/BookSummary/styles";
import CarouselControls from "../components/CarouselControls";

const BlockSection = styled.section`
  margin: 59px 15px;
  grid-column: 1 / -1;

  ${breakpoint("md")`
		grid-column: 1 / -1;
		margin: 78px 0;
    `}

  ${breakpoint("lg")`
		grid-column: 2 / -2;
		margin: 145px auto;
		margin-top: 0;
    `}
`;
const BodyWrapper = styled.div`
  background-color: #f4f1ee;

  padding: 45px 0 65px 0;
  margin: 0;

  ${breakpoint("md")`
		padding: 61px 0 80px 0;
    `}

  ${breakpoint("lg")`
		padding: 101px 0;
    `}
`;

const TeamWrapper = styled.div`
  background-color: #f4f1ee;

  padding: 0;
  padding-top: 65px;
  margin: 0;

  ${breakpoint("md")`
		padding-top: 80px;
    `}

  ${breakpoint("lg")`
		padding-top: 100px;
    `}
`;

const TitlesSection = styled.section`
  margin: 0;
  margin-right: auto;
  margin-bottom: 40px;
  grid-column: 1 / -1;

  ${SectionTitle} {
    color: #111112;
  }

  ${SectionHeading} {
    color: #111112;
  }

  ${breakpoint("lg")`
        grid-column: 1 / 8;
		margin-left: 50px;
		margin-bottom: 70px;
    `};
`;

const AboutContents = () => {
  const [ourStory, setOurStory] = React.useState([]);

  React.useEffect(() => {
    (async function () {
      let ourStory = await fetchOurStory();
      setOurStory(ourStory);
    })();
  }, []);
  return (
    <React.Fragment>
      <Grid>
        <BlockSection>
          <StoryTitle>Our Story</StoryTitle>
          {ourStory.map(({ our_story }) => (
            <StoryParagraph>{our_story}</StoryParagraph>
          ))}
        </BlockSection>
      </Grid>
      <BodyWrapper>
        <Grid>
          <TitlesSection>
            <SectionTitle>Testimonials</SectionTitle>
            <SectionHeading>Don’t just take our word for it…</SectionHeading>
          </TitlesSection>
        </Grid>
        <Container nopad customWidth style={{ display: "block" }}>
          <Testimonials dark />
        </Container>
        <CarouselControls id={1} testis nofloat />
        <TeamWrapper>
          <Grid>
            <TitlesSection>
              <SectionHeading>The team involved</SectionHeading>
            </TitlesSection>
          </Grid>
          {/* <Container style={{ display: "block" }}> */}
          <TeamMembers />
          <CarouselControls id={2} testis nofloat />
          {/* </Container> */}
        </TeamWrapper>
        <Grid>
          <TitlesSection id="donation">
            <SectionTitle>Donate</SectionTitle>
            <SectionHeading>
              The Bible for Life Discipleship Trust
            </SectionHeading>
          </TitlesSection>
          <StoryParagraph paddingLeft>
            <a
              href="https://www.stewardship.org.uk/partners/20380430"
              target="_blank"
            >
              Stewardship Account
            </a>
          </StoryParagraph>
          <StoryParagraph gridColumn>
            Direct Donation:
            <br />
            {/* <br /> */}
            NatWest: 51-70-08 <br />
            Account: 77182901
          </StoryParagraph>
        </Grid>
      </BodyWrapper>
    </React.Fragment>
  );
};

export default AboutContents;
