import breakpoint from "../../../breakpoint";
import styled from "styled-components";

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 48px 1fr 48px;
	grid-template-areas: "left getstarted right";
	column-gap: 10px;

	position: fixed;
	left: 0;
	bottom: 0;
	right: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-image: url(${require("./assets/gradient.svg").default});

	${breakpoint("md")`
        grid-template-columns: 90px 90px . 215px;
    `}
	${breakpoint("lg")`
        grid-template-columns: 90px 90px . 215px;
        grid-template-areas: "left right . getstarted";
    `}
`;

export const Left = styled.div`
	grid-area: left;

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(${({ disabled }) =>
		disabled
			? require("./assets/left-disabled.svg").default
			: require("./assets/left.svg").default});

	width: 48px;
	height: 48px;

	${breakpoint("md")`
        width: 90px;
        height: 90px;
    `}
`;

export const Right = styled.div`
	grid-area: right;

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(${({ disabled }) =>
		disabled
			? require("./assets/right-disabled.svg").default
			: require("./assets/right.svg").default});

	width: 48px;
	height: 48px;

	${breakpoint("md")`
        width: 90px;
        height: 90px;
    `}
`;

export const GetStarted = styled.button`
	background-color: black;
	color: white;
	background: #111112;
	border-radius: 40px;
	display: block;
	padding: 18px 56px;
	font-family: Cerebri Sans;

	appearance: none;
	border: none;

	font-size: 14px;
	line-height: 12px;

	${breakpoint("md")`
font-size: 18px;
	line-height: 12px;
`}
`;
