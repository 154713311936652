// const formatGroup = {
// 	podcasts: (group) =>
// 		group.map(({ podcast_title, podcast_author, podcast_audio }) => ({
// 			title: podcast_title,
// 			author: podcast_author,
// 			src: podcast_audio.url,
// 		})),
// 	videos: (group) =>
// 		group.map(
// 			({ video_title, video_author, video_url, video_thumbnail }) => ({
// 				title: video_title,
// 				author: video_author,
// 				src: video_url,
// 				thumbnail: video_thumbnail,
// 			})
// 		),
// };

export default ({
	id,
	data: { title, description, reading_time, tools_of_engagement, body },
}) => {
	return body.length
		? {
				id,
				tools_of_engagement,
				title: title || "No title",
				description: description.length ? description[0].text : "",
				reading_time: reading_time || "0",
				slug: body[0].slice_type,
				slices: body,
				// items,
				// primary,
		  }
		: null;
};
