import { Option, Overlay, Wrapper } from "./styles";

import React from "react";

export default ({ options, onClose, onChange, show }) => {
	const [selectedIndex, setSelectedIndex] = React.useState(0);

	const handleChange = ({ value, index }) => {
		// Value would be the ID. Can use this for searching in the filterBooks file
		onChange(value);

		setSelectedIndex(index);

		// To allow the user to see the selected state
		setTimeout(onClose, 150);
	};

	return (
		show && (
			<React.Fragment>
				<Wrapper>
					{options.map(({ title, value }, index) => (
						<Option
							selected={selectedIndex === index}
							onClick={() => handleChange({ index, value })}
						>
							{title}
						</Option>
					))}
				</Wrapper>
				<Overlay onClick={onClose} />
			</React.Fragment>
		)
	);
};
