const sofb = [
  {
    question: "1. Purpose:",
    answer:
      "1.1.	The purpose of the Standards of Behaviour for Bible for Life Discipleship Trust (Bible for Life), is to establish clear guidelines for the conduct of Trustees, employees, volunteers, and representatives, ensuring alignment with the charities mission, values, and professional standards.",
    answer2:
      "1.2.	These standards are designed to foster a culture of integrity, respect, and accountability, both within and outside official duties representing Bible for Life.",
  },
  {
    question: "2. Mission and Values:",
    answer:
      "2.1.	At Bible for Life, our mission is to empower individuals to engage with scripture and put Jesus’ teachings into practice. We are committed to fostering a community where men and women of all backgrounds can deepen their understanding of the Bible, grow spiritually, and apply its principles in their daily lives. Our values include:",
    answer2:
      "2.2.	Faithfulness: We uphold the authority and truth of scripture as the foundation of our work and ministry.",
    answer3:
      "2.3.	Inclusivity: We welcome individuals from diverse backgrounds and perspectives, fostering an inclusive community rooted in love and respect.",
    answer4:
      "2.4.	Transformation: We believe in the transformative power of the Bible to change lives and renew minds.",
    answer5:
      "2.5.	Integrity: We conduct ourselves with honesty, transparency, and accountability in all aspects of our operations and interactions.",
  },
  {
    question: "3. Professional Conduct:",
    answer:
      "3.1.	Conduct yourself with professionalism, integrity, and respect at all times, both within and outside of official duties representing Bible for Life.",
    answer2:
      "3.2.	Uphold the values and mission of Bible for Life in all interactions and communications.",
  },
  {
    question: "4. Speaking on Behalf of Bible for Life:",
    answer:
      "4.1.	Only authorised representatives of Bible for Life are permitted to speak on behalf of the charity.",
    answer2:
      "4.2.	Ensure that statements made on behalf of Bible for Life are accurate, truthful, and in alignment with the charities mission, values, and official positions.",
    answer3:
      "4.3.	Don’t make any comments that are derogatory, offensive, discriminatory, untrue, negative, critical, or defamatory comments about the charity, our people, clients, or any part of the group or could constitute unlawful discrimination or harassment.",
  },
  {
    question: "5. Involvement in Political Campaigns:",
    answer:
      "5.1.	Maintain political neutrality while representing Bible for Life, refraining from engaging in partisan political activities or endorsements.",
    answer2:
      "5.2.	Personal involvement in political campaigns should not be linked to or associated with Bible for Life's official platforms or activities.",
  },
  {
    question: "6. Confidentiality and Privacy:",
    answer:
      "6.1.	Respect the confidentiality of sensitive information related to Bible for Life's operations, stakeholders, and organisational matters.",
    answer2:
      "6.2.	Exercise discretion and discernment when discussing internal affairs or confidential matters, ensuring information is shared only with authorised individuals as necessary.",
  },
  {
    question: "7. Conflict of Interest:",
    answer:
      "7.1.	Avoid situations where personal interests or affiliations may conflict with the best interests of Bible for Life.",
    answer2:
      "7.2.	Disclose any potential conflicts of interest to the Board of Trustees or the Chief Executive and refrain from participating in decisions where a conflict exists.",
  },
  {
    question: "8. Responsible Social Media Use:",
    answer:
      "8.1.	Exercise caution and discretion when using social media platforms, refraining from sharing content or engaging in online activities that may reflect negatively on Bible for Life or compromise its reputation.",
    answer2:
      "8.2.	Respect the privacy and dignity of others in online interactions, refraining from cyberbullying, harassment, or other forms of online misconduct.",
    answer3:
      "8.3.	Please refer to our Social Media and Website Policy for more detail and guidance.",
  },
  {
    question: "9. Compliance with Laws and Regulations:",
    answer:
      "9.1.	Adhere to all applicable laws, regulations, and ethical standards governing the activities and operations of Bible for Life.",
  },
  {
    question: "10. Continuous Learning and Improvement:",
    answer:
      "10.1. Commit to ongoing professional development and learning opportunities to enhance knowledge, skills, and effectiveness in fulfilling responsibilities for Bible for Life.",
    answer2:
      "10.2. Embrace feedback and constructive criticism as opportunities for growth and improvement.",
  },
  {
    question: "11. Accountability and Transparency:",
    answer:
      "11.1. Take responsibility for actions and decisions made on behalf of Bible for Life, demonstrating accountability to stakeholders and the broader community.",
    answer2:
      "11.2. Maintain transparency in communications and decision-making processes, ensuring that information is shared openly and honestly with relevant parties.",
  },
  {
    question: "12. Review and Compliance:",
    answer:
      "12.1. This Standards of Behaviour document will be reviewed every two years to ensure its relevance, effectiveness, and alignment with the evolving needs and values of Bible for Life.",
    answer2:
      "12.2. All individuals associated with Bible for Life are expected to comply with the standards outlined in this policy.",
  },

  {
    question: "13. Acknowledgment:",
    answer:
      "13.1. By accepting a role or association with Bible for Life, individuals acknowledge their commitment to upholding the Standards of Behaviour and contributing to the charities mission and values with integrity and dedication. ",
    answer2:
      "13.2. These standards serve as a collective commitment to maintaining a positive and ethical organisational culture.",
  },
];

export default sofb;
