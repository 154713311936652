import { Body, Pin, Role, StyledLink, Title, Wrapper } from "./styles";

export default ({ light, dark, role, title, body, link, hide }) => (
	<Wrapper light={light} dark={dark}>
		<Pin />
		<Role>{role}</Role>
		<Title>{title}</Title>
		<Body>{body}</Body>
		<StyledLink href={link} target="_blank" hide={hide}>Visit website</StyledLink>
	</Wrapper>
);
