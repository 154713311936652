export function setWithExpiry(key, value, ttl) {
  const now = new Date();
  const expiringItem = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(expiringItem));
}

export function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  let expiringItem;
  try {
    expiringItem = JSON.parse(itemStr);
  } catch (error) {
    localStorage.removeItem(key);
    return null;
  }
  const now = new Date();
  if (now.getTime() > expiringItem.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return expiringItem.value;
}

export function getExpiry(key) {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }
  const expiringItem = JSON.parse(itemStr);
  return expiringItem.expiry;
}

export function checkExpiry(title, itemType) {
  if (localStorage.length !== 0) {
    let expiry;

    try {
      expiry = getExpiry(`${itemType}${encodeURI(title)}`);
    } catch (error) {
      expiry = null;
      console.log("expiry error", error);
    }
    if (expiry !== null) {
      if (expiry < 1642005881312) {
        localStorage.clear();
      }
    }
  }
}
