export default [
	"Acts",
	"Amos",
	"1&2 Chronicles",
	"Colossians",
	"1 Corinthians",
	"2 Corinthians",
	"Daniel",
	"Deuteronomy",
	"Ecclesiastes",
	"Ephesians",
	"Esther",
	"Exodus",
	"Ezekiel",
	"Ezra",
	"Galatians",
	"Genesis",
	"Habakkuk",
	"Haggai",
	"Hebrews",
	"Hosea",
	"Isaiah",
	"James",
	"Jeremiah",
	"Job",
	"Joel",
	"John",
	"1 John",
	"2 John",
	"3 John",
	"Jonah",
	"Joshua",
	"Jude",
	"Judges",
	"1 Kings",
	"2 Kings",
	"Lamentations",
	"Leviticus",
	"Luke",
	"Malachi",
	"Mark",
	"Matthew",
	"Micah",
	"Nahum",
	"Nehemiah",
	"Numbers",
	"Obadiah",
	"1 Peter",
	"2 Peter",
	"Philemon",
	"Philippians",
	"Proverbs",
	"Psalms",
	"Revelation",
	"Romans",
	"Ruth",
	"1 Samuel",
	"2 Samuel",
	"Song of Solomon",
	"1 Thessalonians",
	"2 Thessalonians",
	"1 Timothy",
	"2 Timothy",
	"Titus",
	"Zechariah",
	"Zephaniah",
];
