import "./index.css";

import {
  BackgroundImage,
  BlockTitleWrapper,
  BookNavigationButtons,
  BottomWrapper,
  Line,
  NextLogo,
  NextPreviousButton,
  PreviousLogo,
  StartButton,
  Wrapper,
} from "./styles";
import {
  BlockTitle,
  CourseLongDescription,
  CourseShortDescription,
  CourseTitle,
} from "../../components/text";
import { useHistory, useLocation } from "react-router-dom";
import { getWithExpiry } from "../../scripts/expiringStorage";

// import ToolofEngagement from '../../components/Carousel/ToolsOfEngagement';
import Carousel from "../../components/Carousel";
import Container from "../../components/Container";
import Contributors from "./Contributors";
import CourseHero from "../../blocks/CourseHero";
import Grid from "../../components/Grid";
import NewsletterPerBook from "../../components/NewsletterPerBook";
import React from "react";
import { StudyMapCarousel } from "./StudyMapCarousel/StudyMapCarousel";
import ToolsofEngagement from "../../blocks/ToolofEngagement";
import parseURL from "../Book/scripts/parseURL";
import { useGlobalState } from "../../scripts/useGlobalState";
import { useRef } from "react";

const emailSeriesList = ["Matthew", "Acts", "Psalms", "Ephesians"];

export default function BookSummary(props) {
  const location = useLocation();
  const history = useHistory();
  const [book, setBook] = React.useState(null);
  const [activeModule, setActiveModule] = React.useState(null);
  const [getStartedText, setGetStartedText] = React.useState("Get Started");
  const carouselRef = useRef();
  const [pageLoading, setPageLoading] = useGlobalState("loading");
  const [imageLoading, setImageLoading] = useGlobalState("video");
  const [bookTitle, setBookTitle] = React.useState("");
  const [openNewsletter, setOpenNewsletter] = React.useState(false);
  const [esDescription, setESDescription] = React.useState("");
  const [esTitle, setESTitle] = React.useState("");
  const [esTime, setESTime] = React.useState("");
  const [esPopupBody, setESPopupBody] = React.useState("");

  const openModal = () => setOpenNewsletter(true);

  React.useEffect(() => {
    (async function () {
      setPageLoading(true);
      setImageLoading(true);
      const { book } = await parseURL(location.pathname);

      setBook(book);
      setBookTitle(book.title);
      setESDescription(book.emailSeriesDescription);
      setESTitle(book.emailSeriesTitle);
      setESTime(book.emailSeriesTime);
      setESPopupBody(book.emailSeriesPopupBody);

      if (window.localStorage) {
        setActiveModule(getWithExpiry(`book-marker.${encodeURI(book.title)}`));

        getWithExpiry(`book-marker.${encodeURI(book.title)}`)
          ? setGetStartedText("Jump Back In")
          : setGetStartedText("Get Started");
      }
      props.location.state &&
        props.location.state.hasOwnProperty("fromBookItem") &&
        carouselRef.current.scrollIntoView();
    })();
  }, []);

  React.useEffect(() => {
    book && !imageLoading && setPageLoading(false);
  }, [book, imageLoading, setPageLoading]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // const handleBegin = async () => {
  //   let course = book.courses[0];

  //   history.push(
  //     `/book/${encodeURI(book.title)}/${course.slug}/${encodeURI(
  //       course.modules[0].title
  //     )}`
  //   );
  // };

  // accessing active slide from local memory
  const handleBeginCurrentIndex = () => {
    //encoded the uri for remembering active course on carousel
    let currentActiveIndex =
      getWithExpiry(`index-${encodeURI(book.title)}`) == null
        ? 0
        : getWithExpiry(`index-${encodeURI(book.title)}`);
    let course = activeModule
      ? book.courses.filter(
          ({ modules }) => !!modules.find(({ id }) => id === activeModule)
        )
      : book.courses[currentActiveIndex];
    let module =
      activeModule && course[0].modules.find(({ id }) => id === activeModule);
    history.push(
      `/book/${encodeURI(book.title)}/${
        activeModule ? course[0].slug : course.slug
      }/${
        activeModule
          ? encodeURI(module.title)
          : encodeURI(course.modules[0].title)
      }`
    );
  };

  const emailSeries = [
    {
      type: "Email series",
      minutes: "" + esTime,
      title: "" + esTitle + " " + bookTitle,
      description: "" + esDescription,
      body: esPopupBody,
    },
  ];

  return (
    book && (
      <React.Fragment>
        <NewsletterPerBook
          title={emailSeries[0].title}
          time={emailSeries[0].minutes}
          groupTitle={bookTitle}
          body={emailSeries[0].body}
          openNewsletter={openNewsletter}
          stateChanger={setOpenNewsletter}
        />

        <BackgroundImage>
          <Wrapper>
            <CourseHero
              keywords={book.keywords}
              image={book.image}
              // onClick={handleBeginCurrentIndex}
              // text={getStartedText}
            >
              <CourseTitle style={{ fontWeight: "bold", color: "#F4F1EE" }}>
                {book.title}
              </CourseTitle>
              <CourseShortDescription
                style={{ fontWeight: "bold", color: "#F4F1EE" }}
              >
                {book.shortDescriptor}
              </CourseShortDescription>
              <CourseLongDescription style={{ color: "#F4F1EE" }}>
                {book.longDescriptor}
              </CourseLongDescription>
            </CourseHero>

            {emailSeriesList.includes(bookTitle) && (
              <Container card toi>
                <ToolsofEngagement notitle style={{ marginBottom: "0px" }}>
                  <Carousel
                    type="toolOfEngagement"
                    onOpenModal={openModal}
                    tools={emailSeries}
                  />
                </ToolsofEngagement>
              </Container>
            )}

            <Grid fullWidth2 id="studyMap">
              <BlockTitleWrapper>
                <Grid fullWidth2 style={{ padding: 0, rowGap: 0, margin: 0 }}>
                  <BlockTitle style={{ margin: 0 }}>Study Map</BlockTitle>
                  {book.contributors.hasOwnProperty("name") && (
                    <Contributors contributors={book.contributors} />
                  )}
                  <Line />
                </Grid>
              </BlockTitleWrapper>
            </Grid>
            <div ref={carouselRef} />
            <Container card id="StudyMapCarouselWrapper">
              <StudyMapCarousel
                //passing title prop
                title={book.title}
                activeModule={activeModule}
                courses={book.courses}
                getStartedFunction={handleBeginCurrentIndex}
                getStartedText={getStartedText}
              />
            </Container>
          </Wrapper>

          <BottomWrapper mobile>
            <BookNavigationButtons>
              <NextPreviousButton className="study-map-swiper-prev">
                <PreviousLogo more />
              </NextPreviousButton>
              <StartButton onClick={handleBeginCurrentIndex}>
                {getStartedText}
              </StartButton>
              <NextPreviousButton next className="study-map-swiper-next">
                <NextLogo more />
              </NextPreviousButton>
            </BookNavigationButtons>
          </BottomWrapper>
        </BackgroundImage>
      </React.Fragment>
    )
  );
}
