import React from "react";
import { SMALL_SCREEN_SCALE } from "../CardImage";
import breakpoint from "../../../../../breakpoint";
import initContext from "./initContext";
import loadAnimation from "./loadAnimation";
import mouseEnterAnimation from "./mouseEnterAnimation";
import styled from "styled-components";

const Canvas = styled.canvas`
	z-index: 1;
	width: 100%;
	height: 100%;
`;

export default ({ radius, borderWidth, courseIndex, greyedOut }) => {
	const ref = React.useRef(null);
	const SCALE = 2,
		WIDTH = radius * 2;

	React.useEffect(() => {
		if (!ref.current) return;

		const context = initContext(ref.current, {
			radius: radius * SCALE,
			borderWidth: borderWidth * SCALE,
			courseIndex,
			greyedOut,
		});

		loadAnimation(context, radius * SCALE, borderWidth);

		const mouseEnterAnimationWithBoundParameters = mouseEnterAnimation.bind(
			null,
			context,
			radius * SCALE,
			borderWidth
		);

		ref.current.addEventListener(
			"mouseenter",
			mouseEnterAnimationWithBoundParameters
		);

		return () =>
			ref.current.removeEventListener(
				"mouseenter",
				mouseEnterAnimationWithBoundParameters
			);
	}, [ref, greyedOut]);

	return (
		<Canvas
			$width={WIDTH}
			$height={WIDTH}
			width={WIDTH * SCALE}
			height={WIDTH * SCALE}
			ref={ref}
		/>
	);
};
