import { PopRole, StoryParagraph } from "../../../../components/text";

import Grid from "../../../../components/Grid";
import breakpoint from "../../../../breakpoint";
import styled from "styled-components";

export const Wrapper = styled.header`
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	margin-top: 32px;

    ${breakpoint("md")`
        margin-top: 52px;
	`}
    ${breakpoint("lg")`
        margin-top: 80px;
	`}
`;

export const ImageWrapper = styled.div`
	display: none;
	grid-column: 1 / -1;
	width: 220px;
	height: 220px;
	border-radius: 6px;

	background-image: url(${({ image }) => image});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	position: relative;

	${breakpoint("lg")`
		grid-column: 1 / 3;
		display: block;
    `}
`;

export const TextWrapper = styled.div`
	grid-column: 1 / -1;
	padding: 0;
	margin: 0;

	${breakpoint("lg")`
		grid-column: 1 / 8;
	`}
`;

export const Play = styled.button`
	grid-area: play;
	border: 0;

	background-image: url(${({ image }) => image});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	border-radius: 50%;

	width: 60px;
	height: 60px;
	display: block;

	${breakpoint("md")`
        width: 100px;
        height: 100px;
    `};
	${breakpoint("lg")`
        width: 100px;
        height: 100px;
    `};
`;

export const Time = styled(StoryParagraph)`
	grid-area: time;
	text-align: right;
	color: #f4f1ee;

	${breakpoint("lg")`
		padding-right: 30px;
	`}
`;

export const Slider = styled.div`
	grid-area: slider;
	align-self: center;
`;

export const PlayerWrapper = styled.div`
	grid-column: 1 / -1;
	display: grid;
	grid-template-areas:
		"play slider slider"
		"play time time";

	grid-template-columns: 78.24px 1fr 67px; //154.76px
	grid-template-rows: 60px 12px;

	padding-bottom: 50px;

	${breakpoint("md")`
		grid-column: 1 / -1;
		grid-template-columns: 161px 1fr 109px; //430px
		grid-template-rows: 72px 28px;
		padding-bottom: 100px;
	`}

	${breakpoint("lg")`
		grid-column: 4 / -1;
		grid-template-columns: 165px 1fr 109px;
		grid-template-rows: 72px 28px;
		padding-bottom: 50px;
	`}
`;

export const Thumbnail = styled.div`
	grid-area: button;

	width: 45px;
	height: 45px;
	border-radius: 6px;
	vertical-align: middle;
	margin: 11px 0;

	background-image: linear-gradient(
			rgba(17, 17, 18, 0.46),
			rgba(17, 17, 18, 0.46)
		),
		url(${({ image }) => image});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	position: relative;

	background-blend-mode: normal;
	/* opacity: 1; */

	${breakpoint("md")`
        width: 70px;
        height: 70px;
		margin: 17px 0;
    `}

	${breakpoint("lg")`
        width: 70px;
        height: 70px;
		margin: 17px 0;
    `}
`;

export const Title = styled(PopRole)`
	grid-area: title;
	vertical-align: bottom;
	margin: 0;
	padding: 0;
	padding-top: 16px;
	color: #f4f1ee;

	${breakpoint("md")`
		padding-top: 22px;
	`};
	${breakpoint("lg")`
		padding-top: 22px;
	`};
`;

export const Author = styled(StoryParagraph)`
	grid-area: author;
	vertical-align: middle;
	margin: 0;
	padding: 0;
	color: rgba(228, 224, 221, 0.5);
`;

export const PlayButton = styled.button`
	grid-area: button;
	position: absolute;
	top: 5px;
	left: 5px;
	border: 0;

	background-image: url(${({ image }) => image});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	z-index: 10;
	border-radius: 50%;

	width: 35px;
	height: 35px;
	display: block;

	${breakpoint("md")`
		top: 7px;
		left: 7px;
        width: 56px;
        height: 56px;
    `};
	${breakpoint("lg")`
		top: 7px;
		left: 7px;
        width: 56px;
        height: 56px;
    `};
`;

export const ButtonWrapper = styled.div`
	grid-area: button;
	/* ${(active) => (active ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.65)")}; */

	${PlayButton} {
		/* background-image: ${({ active }) =>
			active
				? "../assets/icons/pause.svg"
				: "../assets/icons/play.svg"} */
		display: ${({ active }) => (active ? "block" : "none")};
	}
`;

export const AudioListWrapper = styled.ul`
	border-top: 1px solid rgba(244, 241, 238, 0.5);
	margin-top: 11px;
	grid-column: 1 / -1;
	padding: 0;
	margin: 0;

	${breakpoint("md")`
		grid-column: 1 / -1;
		padding-top: 18px;

		
	`}

	${breakpoint("lg")`
		grid-column: 4 / -1;
		padding-top: 18px;
		
	`}
`;

export const Duration = styled(StoryParagraph)`
	grid-area: duration;
	text-align: center;
	color: rgba(228, 224, 221, 0.5);
`;

export const ListItem = styled.li`
	grid-area: item;
	box-sizing: border-box;
	border-bottom: 1px solid rgba(244, 241, 238, 0.5);

	display: grid;
	grid-template-areas:
		"title ."
		"author duration";

	grid-template-columns: 1fr 14px;
	grid-template-rows: 39px 27px;
	/* padding: 0; */
	margin: 0;
	margin-left: 22px;
	text-align: left;

	${breakpoint("md")`
		grid-template-columns: 1fr 70px;
		grid-template-rows: 62px 51px;
	
		margin-left: 19px;
	`}

	${breakpoint("lg")`
		grid-template-columns: 1fr 70px;
		grid-template-rows: 62px 51px;
		
		margin-left: 19px;
	`}
`;

export const AudioContentWrapper = styled.div`
	/* max-width: 922px; */
	width: 300px;
	width: 100%;

	display: grid;
	grid-template-areas: "button item";
	grid-template-columns: 45px 1fr;
	grid-template-rows: 65px;
	padding: 0;

	margin: 0;

	&:hover,
	:active,
	:focus {
		${PlayButton} {
			background-image: ${({ hoverState }) =>
				hoverState
					? "../assets/icons/pause.svg"
					: "../assets/icons/play.svg"};
		}

		${Thumbnail} {
			/* background-color: rgba(0, 0, 0, 0); */
		}

		cursor: pointer;
	}

	${breakpoint("md")`

		// width: 700px;
		width: 100%;
		// padding: 13px 0;
		// padding-top: 35px;

		grid-template-columns: 70px 1fr;
		grid-template-rows: 110px;
		
	`}

	${breakpoint("lg")`

		width: 922px;
		width: 100%;
		// padding: 13px 0;
		// padding-top: 35px;

		grid-template-columns: 70px 1fr;
		grid-template-rows: 110px;
		
	`}
`;

export const AudioPlayerWrapper = styled.div`
	grid-column: 1 / -1;
	padding: 0;
	margin: 0;

	${breakpoint("md")`
		grid-column: 1 / -1;

		
	`}

	${breakpoint("lg")`
		grid-column: 4 / -1;
		
	`}
`;
