import { rest } from "lodash";
import React from "react";
import { BookNavigationButtons, NextLogo, NextPreviousButton, PreviousLogo } from "../pages/BookSummary/styles";



const CarouselControls = ({id, ...rest}) => {
    return <React.Fragment>
        <BookNavigationButtons {...rest}>
            <NextPreviousButton className={`study-map-swiper-prev${id || ''}`} >
                <PreviousLogo more />
            </NextPreviousButton>
            <NextPreviousButton next className={`study-map-swiper-next${id || ''}`}>
                <NextLogo more />
            </NextPreviousButton>
        </BookNavigationButtons>
    </React.Fragment>
}


export default CarouselControls;