import breakpoint from "../../breakpoint";
import styled from "styled-components";

export const SearchIcon = styled.img.attrs({
	src: require("../../assets/icons/Search.svg").default,
})`
	height: 21px;
	margin-left: 15px;
	text-align: center;
	${breakpoint('lg')`
		height: 21px`}
	
`;

export const PanelGrid = styled.div`
	display: grid;
	column-gap: 18px;
	row-gap: 18px;
	grid-template-columns: 1fr;

	${breakpoint("md")`
        grid-template-columns: 1fr 1fr;
    `}
`;

export const SearchBar = styled.input`
	grid-column: 1 / -1;
	border: none;
	padding: 12px;
	box-sizing: border-box;
	border-bottom: 1px solid #e4e0dd;
`;

export const ButtonWrapper =styled.div`
	padding: 0;
	display: ${({show}) => show ? "none" : "block"};

	position: relative;
`;

export const ButtonPlacement = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;

	padding: 26px 60px;
	margin: 75px 0 0 0;

	${breakpoint("md")`
		margin: 71px 0 0 0;
	`}

	${breakpoint("lg")`
		margin: 125px 0 0 0;
	`}

	
`;

export const MoreBooks = styled.div`
	position: relative;
	top: 0;
	left: 0;
	display: block;
	grid-column: span 1;

	height: 140px;
	margin-bottom: 45px;

	background: linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 0%, #FFFFFF 100%), linear-gradient(180deg, #111111 0%, rgba(17, 17, 18, 0.3) 100%); 
	opacity: 0.3;

	${({ hide }) =>
		hide &&`
		display: none;
	`}

	${breakpoint("md")`
		// margin: 75px 0;
		// height: 136px;
		margin-bottom: 75px;
		${({ hide }) =>
		hide &&`
			display: block;
		`}
	`}

	${breakpoint("lg")`
		height: 190px;
		margin-bottom: 150px;

		${({ hide }) =>
		hide &&`
			display: block;
		`}
	`}
`;

// @-webkit-keyframes AnimationName {
//     0%{background-position:0% 50%}
//     50%{background-position:100% 50%}
//     100%{background-position:0% 50%}
// }
// @-moz-keyframes AnimationName {
//     0%{background-position:0% 50%}
//     50%{background-position:100% 50%}
//     100%{background-position:0% 50%}
// }
// @keyframes AnimationName {
//     0%{background-position:0% 50%}
//     50%{background-position:100% 50%}
//     100%{background-position:0% 50%}
// }