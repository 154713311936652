import BorderAnimation from "./BorderAnimation";
import React from "react";
import breakpoint from "../../../../breakpoint";
import styled from "styled-components";

const BORDER_WIDTH = 5;

export const SMALL_SCREEN_SCALE = 0.75;

const Wrapper = styled.div`
	position: relative;

	margin: 0 auto 20px;
	width: fit-content;
	cursor: pointer;

	border-radius: 100%;
	overflow: hidden;

	width: ${({ radius }) =>
		`calc(${radius * SMALL_SCREEN_SCALE}px + ${BORDER_WIDTH * 2}px)`};
	height: ${({ radius }) =>
		`calc(${radius * SMALL_SCREEN_SCALE}px + ${BORDER_WIDTH * 2}px)`};

	${breakpoint("md")`
        margin: 0 auto 40px;
        
        width: ${({ radius }) => `calc(${radius}px + ${BORDER_WIDTH * 2}px)`};
        height: ${({ radius }) => `calc(${radius}px + ${BORDER_WIDTH * 2}px)`};
    `}
`;

const CardImage = styled.div`
	pointer-events: none;

	z-index: 2;

	box-sizing: border-box;
	background-color: white;
	display: block;

	border-radius: 100%;

	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	position: absolute;
	left: ${BORDER_WIDTH * SMALL_SCREEN_SCALE}px;
	top: ${BORDER_WIDTH * SMALL_SCREEN_SCALE}px;

	width: calc(100% - ${BORDER_WIDTH * SMALL_SCREEN_SCALE * 2}px);
	height: calc(100% - ${BORDER_WIDTH * SMALL_SCREEN_SCALE * 2}px);

	${breakpoint("md")`
        left: ${BORDER_WIDTH}px;
        top: ${BORDER_WIDTH}px;

        width: calc(100% - ${BORDER_WIDTH * 2}px);
        height: calc(100% - ${BORDER_WIDTH * 2}px);
    `}

	${({ whatYouNeedToKnow }) =>
		whatYouNeedToKnow &&
		`
        background-image: url(${require("../../../../assets/modulesIcons/1_what-you-need-to-know_book-landing-illustrations.png")});
    `}

	${({ theHearOf }) =>
		theHearOf &&
		`
        background-image: url(${require("../../../../assets/modulesIcons/2_understanding_book-landing-illustrations.png")});
    `}

${({ livingIt }) =>
		livingIt &&
		`
        background-image: url(${require("../../../../assets/modulesIcons/3_living-it_book-landing-illustrations.png")});
    `}

${({ makingDisciples }) =>
		makingDisciples &&
		`
        background-image: url(${require("../../../../assets/modulesIcons/4_making-disciples_book-landing-illustrations.png")});
    `}
`;

export default ({ children, radius, onClick, courseIndex, ...props }) => {
	const [visited, setVisited] = React.useState(false);

	const handleClick = () => {
		setVisited(true);
		onClick();
	};

	return (
		<Wrapper onClick={handleClick} radius={radius}>
			<BorderAnimation
				radius={radius}
				borderWidth={BORDER_WIDTH}
				courseIndex={courseIndex}
				greyedOut={visited}
			/>
			<CardImage radius={radius * 2 - BORDER_WIDTH * 2} {...props}>
				{children}
			</CardImage>
		</Wrapper>
	);
};
