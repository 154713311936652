// Import Swiper styles
import "swiper/swiper.scss";
import "./index.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import React from "react";
import Team from "./Team";
import Testimonial from "./Testimonial";
import ToolofEngagement from "./ToolsOfEngagement";
import SwiperCore, { Navigation } from "swiper";

SwiperCore.use([Navigation]);

const Carousel = ({
  children,
  loop = false,
  type,
  slideWidth = 615,
  onOpenModal,
  tools,
  id,
}) => {
  const [cssMode, setCssMode] = React.useState(false);

  React.useEffect(() => {
    setCssMode(window.innerWidth < 700);

    window.addEventListener(
      "resize",
      () => {
        setCssMode(window.innerWidth < 700);
      },
      false
    );
  }, []);

  const handleClick = (swiper) => {
    if (typeof onOpenModal === "function") {
      onOpenModal(tools[swiper.clickedIndex]);
    }
  };

  const getSlides = () => {
    if (type === "toolOfEngagement") {
      return tools.map(
        ({ type, minutes, title, description, content, light }, index) => (
          <SwiperSlide key={index}>
            <Carousel.ToolofEngagement
              key={`toe-${index}`}
              type={type}
              minutes={type === "Email series" ? minutes : minutes + " mins"}
              title={title}
              shortDescription={description}
              reading={type === "Reading"}
              hearing={type === "Hearing"}
              worship={type === "Worship"}
              studying={type === "Studying"}
              memorising={type === "Memorising"}
              meaning={type === "Meanings"}
              emailseries={type === "Email series"}
              light={light}
              email={type === "Email series"}
            />
          </SwiperSlide>
        )
      );
    } else {
      return children.map((child, key) => (
        <SwiperSlide key={key}>{child}</SwiperSlide>
      ));
    }
  };

  return (
    <Swiper
      // This fixes issues on mobile not positioning slides properly
      cssMode={cssMode}
      onClick={handleClick}
      slidesPerView="auto"
      preventClicks={false}
      preventClicksPropagation={false}
      loop={loop}
      style={{ overflow: "visible" }}
      navigation={
        id
          ? {
              prevEl: `.study-map-swiper-prev${id || ""}`,
              nextEl: `.study-map-swiper-next${id || ""}`,
              disabledClass: `disabled`,
            }
          : {}
      }
    >
      {getSlides()}
    </Swiper>
  );
};

Carousel.Testimonial = Testimonial;
Carousel.Team = Team;
Carousel.ToolofEngagement = ToolofEngagement;

export default Carousel;
