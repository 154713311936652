const smandwp = [
  {
    question: "1. Purpose:",
    answer:
      "1.1. The purpose of the Social Media and Website Policy for Bible for Life Discipleship Trust (Bible for Life) is to provide guidelines and expectations for the appropriate use of social media platforms and the website.",
    answer2:
      "1.2. Both are to be utilised by employees, volunteers, and representatives to ensure alignment with the charities mission, values, and professional standards.",
  },
  {
    question: "2. Mission and Values:",
    answer:
      "2.1.	At Bible for Life, our mission is to empower individuals to engage with scripture and put Jesus’ teachings into practice. We are committed to fostering a community where men and women of all backgrounds can deepen their understanding of the Bible, grow spiritually, and apply its principles in their daily lives. Our values include:",
    answer2:
      "2.2.	Faithfulness: We uphold the authority and truth of scripture as the foundation of our work and ministry.",
    answer3:
      "2.3.	Inclusivity: We welcome individuals from diverse backgrounds and perspectives, fostering an inclusive community rooted in love and respect.",
    answer4:
      "2.4.	Transformation: We believe in the transformative power of the Bible to change lives and renew minds.",
    answer5:
      "2.5.	Integrity: We conduct ourselves with honesty, transparency, and accountability in all aspects of our operations and interactions.",
  },
  {
    question: "3. Professional Conduct:",
    answer:
      "3.1.	Embody the mission and values of Bible for Life in all social media interactions and website content.",
    answer2:
      "3.2.	Reflect the spirit of faithfulness, inclusivity, transformation, integrity, and collaboration in your online engagement.",
  },
  {
    question: "4. Accuracy and Truthfulness:",
    answer:
      "4.1.	Ensure that content shared on social media platforms and the website is rooted in the truth of scripture and aligns with the principles of accuracy and integrity.",
    answer2:
      "4.2.	Verify information from reliable sources before sharing, promoting discernment and responsible engagement with online content.",
    answer3:
      "4.3.	Avoid discussing, engaging with, or directly sharing anything that is a rumour.",
    answer4:
      "4.4.	Don’t make any comments that are derogatory, offensive, discriminatory, untrue, negative, critical, or defamatory comments about the charity, our people, clients, or any part of the group or could constitute unlawful discrimination, harassment or cyber-bullying or post any images or video clips that are discriminatory, or which may constitute unlawful harassment or cyber-bullying.",
  },
  {
    question: "5. Monitoring and Moderation:",
    answer:
      "5.1.	Monitor social media channels and website content regularly to ensure alignment with the mission, values, and guidelines of Bible for Life.",
    answer2:
      "5.2.	Implement moderation measures to address inappropriate or harmful content promptly, maintaining a safe and edifying online environment for all participants.",
  },
  {
    question: "6. Copyright:",
    answer:
      "6.1.	It is critical that any user of Bible for Life’s website or Social Media profiles abide by the laws governing copyright, under the Copyright, Designs and Patents Act 1988.",
    answer2:
      "6.2.	Never use or adapt someone else's images or written content without permission. Failing to acknowledge the source/author/resource citation, where permission has been given to reproduce content, is also considered a breach of copyright.",
    answer3:
      "6.3.	Unless indicated, all Scriptures on Bible for Life’s website are taken from the Holy Bible, New International Version ®, NIV ® Copyright © 1973, 1978, 1984, 2011 by Biblica, Inc. Used with permission. All rights reserved worldwide.",
  },
  {
    question: "7. Protection and Intervention:",
    answer:
      "7.1.	The responsibility for measures of protection and intervention lies first with the social networking site itself. Different social networking sites offer different models of interventions in different areas.",
    answer2:
      "7.2.	For more information, refer to the guidance available on the social networking site itself.",
  },
  {
    question: "8. Under 18s and Vulnerable People:",
    answer:
      "8.1.	Young and vulnerable people face risks when using social networking sites. They may be at risk of being bullied, publishing sensitive and personal information on their profiles, or from becoming targets for online grooming.",
    answer2:
      "8.2.	Where known, when communicating with young people under 18-years-old via social media, staff and volunteers should ensure the online relationship with Bible for Life follows the same rules as the offline 'real-life' relationship. ",
    answer3:
      "8.3.	Users should ensure that young people have been made aware of the risks of communicating and sharing information online and given guidance on security/privacy settings as necessary. Users should also ensure that the site itself is suitable for the young person and Bible for Life’s content and other content is appropriate for them.",
  },
  {
    question: "9. Confidentiality and Privacy:",
    answer:
      "9.1.	Safeguard the confidentiality and privacy of Bible for Life's stakeholders, demonstrating respect for their personal information and experiences.",
    answer2:
      "9.2.	Respect the privacy of individuals when sharing content on social media, obtaining consent before posting personal information or photos.",
  },
  {
    question: "10. Response to Feedback:",
    answer:
      "10.1. Respond to feedback and inquiries from followers and visitors to our social media platforms promptly and courteously, demonstrating a commitment to open communication.",
  },
  {
    question: "11. Handling Negative Comments & Messages:",
    answer:
      "11.1. In the event of negative comments or inappropriate messages, respond calmly and respectfully. Evaluate the situation and consider whether a public or private response is appropriate. If necessary, provide clarification or address concerns privately to resolve the issue amicably. Escalate if necessary. ",
  },
  {
    question: "12. Content Creation and Sharing:",
    answer:
      "12.1. Create and share content that reflects the mission and values of Bible for Life, offering resources and insights that inspire spiritual growth and deepen biblical understanding.",
    answer2:
      "12.2. Exercise discernment and sensitivity in selecting and sharing content, avoiding material that may contradict or undermine our mission and values.",
  },
  {
    question: "13. Engagement and Interaction:",
    answer:
      "13.1. Engage with followers, supporters, and stakeholders in a spirit of grace, humility, and mutual respect, fostering meaningful dialogue and community building.",
    answer2:
      "13.2. Prioritise kindness and empathy in all interactions, cultivating a culture of encouragement, support, and accountability within our online community.",
  },
  {
    question: "14. Review and Revision:",
    answer:
      "14.1. This policy will be reviewed every two years to ensure its alignment with the evolving needs and aspirations of Bible for Life, with revisions made as necessary to uphold our mission and values in the digital sphere.",
  },
  {
    question: "15. Acknowledgement:",
    answer:
      "15.1. By participating in social media interactions and contributing content to the charities website, employees, volunteers, and representatives affirm their commitment to embodying the mission and values of Bible for Life in their online presence and engagement.",
  },
];

export default smandwp;
