import breakpoint from "../../../breakpoint";
import styled from "styled-components";

export const SearchIcon = styled.img.attrs({
	src: require("../../../assets/icons/Search.svg").default,
})`
	height: 33px;
	display: block;
	margin-right: 21px;
	${breakpoint('md')`
	height: 21px`}
`;

export const BookshelfIcon = styled.img.attrs({
	src: require("../../../assets/icons/Bookshelf.svg").default,
})`
	height: 33px;
	display: block;
	${breakpoint('md')`
	height: 21px`}
`;

export const Input = styled.input`
	border: none;
	background-color: transparent;
	border-bottom: 1px solid #a1a0a5;
	flex: 1;
	font-size: 18px;
	outline: none;
`;

export const InputGroup = styled.div`
	grid-area: input-group;
	position: relative;
	display: flex;
	background-color: #f4f1ee;
	align-items: center;
	justify-content: flex-end;
	padding: 15px 20px;
	height: 63px;
	box-sizing: border-box;
	margin: 0 -18px;

	${breakpoint("md")`
		margin: 0;
		background-color: transparent;
	`}
`;

export const SortGroup = styled.div`
	border-bottom: 1px solid #a1a0a5;
	display: flex;
	justify-content: space-between;
	width: calc(100% - 36px);
	max-width: 400px;
	margin: 28px auto 15px;

	${breakpoint("md")`
		margin: 28px 18px 15px;
	`}
`;

export const SortUnderline = styled.div`
	background-color: #000000;
	height: 3px;

	position: absolute;
	will-change: left;
	transition: left 0.3s;

	${({ left, width, top, height }) => `
		left: ${left}px;
		transform: translateY(${height}px);
		width: ${width}px;
	`}
`;

export const SortOption = styled.div`
	padding-bottom: 2px;
	margin-bottom: -1px;
	font-size: 16px;
	font-weight: 500;
	color: #a1a0a5;
	cursor: pointer;

	${({ active }) => active && `color: black;`}

	${breakpoint("md")`
	font-size: 22px`}
`;

export const Wrapper = styled.div`
	display: grid;
	grid-template-areas:
		"input-group"
		"sort-group";
	align-items: center;
	max-width: 1400px;
	width: calc(100% - 18px - 18px);
	margin-left: auto;
	margin-right: auto;

	${Input} {
		display: none;
	}

	${({ inputActive }) =>
		inputActive &&
		`
		${SortGroup} { display: none; }
        ${InputGroup} { justify-content: space-between; }
		
        ${BookshelfIcon} { display: none; }
        ${Input} { display: block; }
		
    `}

	${breakpoint("md")`
		grid-template-areas: "sort-group input-group";
		margin-top: 18px;
		margin-bottom: 18px;

		height: 71px;

		${({ inputActive }) => inputActive && `grid-template-areas: ". input-group .";`}
	`}
`;
