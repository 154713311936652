export default ({
	id,
	data: {
		bio,
		contributor_image,
		contributor_link,
		contributor_name,
		contributor_role,
	},
}) => ({
	id,
	bio: bio.map(({ text }) => text).join("\n"),
	image: contributor_image.url,
	link: contributor_link.url,
	name: contributor_name,
	role: contributor_role,
});
