import "./dropdown.css";
import "./popover.css";

import {
  ChapterNumber,
  ChunkWrapper,
  Description,
  DropDownButtonWrapper,
  DropDownIcon,
  ExtaDescription,
  PageDescriptionWrapper,
  PageTitle,
  PageTitleWrapper,
  Section,
  SliceSummary,
  SummaryIcon,
  Wrapper,
} from "./styles";
import DropDownModal from "../../../../components/DropDownModal";
import { PopoverBody, UncontrolledPopover } from "reactstrap";

import Button from "../../../../components/Button";
import Grid from "../../../../components/Grid";
import Headroom from "react-headroom";
import React from "react";
import { RichText } from "prismic-reactjs";
import { RichText as RichTextHtml } from "prismic-dom";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import flatten from "lodash.flatten";
import { gsap } from "gsap";
import { parse as parseMarkdown } from "marked";
import { useLocation } from "react-router-dom";

gsap.registerPlugin(ScrollToPlugin);

const VerseByVerse = ({ mod }) => {
  const location = useLocation();

  const { items, primary } = mod.slices[0];
  const [activeSliceIndex, setActiveSliceIndex] = React.useState();
  const [dropdownModalOpen, setDropdownModalOpen] = React.useState(false);
  const toggle = () => setDropdownModalOpen(!dropdownModalOpen);

  let sliceTitles = ["null"];
  if (
    mod.slices &&
    mod.slices[0].hasOwnProperty("primary") &&
    mod.slices[0].primary.hasOwnProperty("slice_title")
  ) {
    sliceTitles = flatten(
      mod.slices
        .map(
          ({ primary }) =>
            primary &&
            primary.slice_title &&
            primary.slice_title
              .replace(/[^\u2013\u2014\d:.-]|/g, "")
              .replace(/\u2013\u2014|-/g, " - ")
              .replace(/\:$/, "")
        )
        .filter((x) => x !== "")
    );
  }

  const handleSliceChange = (index) => {
    // setActiveSliceIndex(index);
    ScrollDown(index);
    setDropdownModalOpen(false);
  };

  const ScrollDown = (index) => {
    var id = document.getElementById(index);
    gsap.to(window, {
      duration: 1,
      delay: 0.0,
      scrollTo: { y: id, offsetY: 60 },
    });
  };

  // Remove slices that don't have number-titles
  const cleanseSlices = (slice) => {
    return (
      slice &&
      slice.primary &&
      slice.primary.slice_title &&
      slice.primary.slice_title
        .replace(/[^\u2013\u2014\d:.-]|/g, "")
        .replace(/\u2013\u2014|-/g, " - ")
        .replace(/\:$/, "") !== ""
    );
  };

  const LOCAL_STORAGE_SCROLL_OFFSET_LABEL = `scrollYoffset-${mod.title}-${primary.slice_title}`;

  React.useEffect(() => {
    const handleScroll = () => {
      // Map out all the number-title slices according to scroll offset
      const offsetMap = chunks.map((chunk) => {
        return window.scrollY - chunk.offset;
      });

      const offsetFromTop = window.scrollY;

      localStorage.setItem(LOCAL_STORAGE_SCROLL_OFFSET_LABEL, offsetFromTop);

      // This is the index of the relevantTitle
      let relevantIndex =
        chunks.length - 1 - offsetMap.filter((n) => n < 0).length;
      if (relevantIndex === -1) relevantIndex = 0;
      // This is the chunk that we have scrolled into
      // const relevantChunk = chunks.map(({ primary }) => primary)[relevantIndex];
      // This is the title of said chunk. But in the case where the current
      // relevant chunk is not a number-title, we use the first number-title
      //const relevantTitle  = relevantChunk ? relevantChunk.slice_title : chunks[0].primary.slice_title;

      // console.log(relevantIndex);

      setActiveSliceIndex(relevantIndex);
    };

    const chunks = [];
    let count = 0;
    mod.slices.forEach((slice) => {
      if (cleanseSlices(slice)) {
        chunks.push({
          primary: slice.primary,
          offset: document.getElementById(count++).offsetTop - 60,
        });
      }
    });

    const SCROLL_DURATION = 1;

    // Array of slices that have number-titles (arranged as {primary, offset})
    // (offset is the offsetTop of the particular slice)

    // Don't auto-scroll if there's an anchor point.

    if (location.hash === "")
      gsap.to(window, {
        duration: SCROLL_DURATION,
        delay: 0.0,
        scrollTo: {
          y: localStorage.getItem(LOCAL_STORAGE_SCROLL_OFFSET_LABEL),
          offsetY: 0,
        },
      });
    else
      setTimeout(() => {
        const id = location.hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 0);

    setTimeout(
      () => window.addEventListener("scroll", handleScroll),
      SCROLL_DURATION * 1000
    );

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  let count = 0;
  return (
    <React.Fragment>
      <Headroom disableInlineStyles id="vxvDropdown">
        <DropDownButtonWrapper>
          <Button
            dark
            id="PopoverLegacy"
            type="button"
            onClick={() => setDropdownModalOpen(true)}
          >
            {sliceTitles[activeSliceIndex]} <DropDownIcon />
          </Button>
        </DropDownButtonWrapper>
      </Headroom>
      <UncontrolledPopover
        id="dropdownPopover"
        isOpen={dropdownModalOpen}
        toggle={toggle}
        trigger="legacy"
        target="PopoverLegacy"
        placement="bottom-end"
        html="true"
        style={{
          border: "none",
        }}
      >
        <PopoverBody
          style={{
            padding: "0",
          }}
        >
          <DropDownModal onChange={handleSliceChange} options={sliceTitles} />
        </PopoverBody>
      </UncontrolledPopover>
      <Wrapper>
        <Grid>
          <PageTitleWrapper>
            <PageTitle>{mod.title}</PageTitle>
          </PageTitleWrapper>
          {mod.slices.map(({ primary, items }, index) => (
            <React.Fragment key={index}>
              {primary &&
              primary.slice_title &&
              primary.slice_title
                .replace(/[^\u2013\u2014\d:.-]|/g, "")
                .replace(/\u2013\u2014|-/g, " - ")
                .replace(/\:$/, "") !== "" ? (
                <PageDescriptionWrapper id={count++}>
                  <ChapterNumber>{primary.slice_title}</ChapterNumber>
                  <Description>
                    {RichText.render(primary.slice_description)}
                  </Description>
                  <ExtaDescription>
                    {RichText.render(primary.slice_body)}
                  </ExtaDescription>
                </PageDescriptionWrapper>
              ) : (
                <PageDescriptionWrapper>
                  <ChapterNumber>{primary.slice_title}</ChapterNumber>
                  <Description>
                    {RichText.render(primary.slice_description)}
                  </Description>
                  <ExtaDescription>
                    {RichText.render(primary.slice_body)}
                  </ExtaDescription>
                </PageDescriptionWrapper>
              )}
              <Section>
                {items.map(({ chunk, chunk_summary }, index) => {
                  function processHeaderTags(tag, text, pText, pText2, h5Text) {
                    let textWithoutStrongTags = text.replace(
                      /<strong>|<\/strong>/g,
                      ""
                    );

                    let id = textWithoutStrongTags
                      .replace(/[^\w\s-]/g, "") // Remove invalid characters
                      .replace(/—|–/g, "-") // Replace en-dashes and em-dashes with hyphens
                      .replace(/\s+/g, "") // Replace spaces with hyphens
                      .replace(/-+/g, "-") // Replace consecutive hyphens with a single hyphen
                      .replace(/-$/, "") // Remove trailing hyphens
                      .toLowerCase();
                    return `<${tag}><a id='${id}' href='#${id}'>${text}</a></${tag}>${
                      h5Text ? `<h5>${h5Text}</h5>` : ""
                    }${pText ? `<p class='${id}'>${pText}</p>` : ""}${
                      pText2 ? `<p class='${id}'>${pText2}</p>` : ""
                    }`;
                  }

                  const s = RichTextHtml.asHtml(chunk)
                    .replace(
                      new RegExp(String.fromCharCode(194), "g"),
                      String.fromCharCode(32)
                    )
                    .replace(new RegExp(String.fromCharCode(160), "g"), "")
                    .replace(/\|<\/p><p><\/p><p>\|/g, "|\n\n|") // End of table line
                    .replace(/\|<\/p><p><\/p><p><\/p><p>\|/g, "|\n\n\n|")
                    .replace(
                      /\|<\/p><p><\/p><p><\/p><p><\/p><p>\|/g,
                      "|\n\n\n\n|"
                    )
                    .replace(/\|<\/p><p><\/p><p>/g, "|\n") // End of the table
                    .replace(/\|<\/p><p>\|/g, "|\n|") // end of table row
                    .replace(/<\/p><p><\/p><p>\|/g, "\n\n|")
                    .replace(/<\/p>\|/g, "\n|")
                    .replace(/<p>\|/g, "|")
                    .replace(/\|<\/p>/g, "|")
                    .replace(/\| <\/p>/g, "|")
                    .replace(
                      /<h3>(.*?)<\/h3>(?:\s*<h5>(.*?)<\/h5>)?(?:\s*<p>(.*?)<\/p>)?(?:\s*<p>(.*?)<\/p>)?/g,
                      (_, h3Text, h5Text, pText1, pText2) =>
                        processHeaderTags("h3", h3Text, pText1, pText2, h5Text)
                    )
                    .replace(
                      /<h4>(.*?)<\/h4>(?:\s*<h5>(.*?)<\/h5>)?(?:\s*<p>(.*?)<\/p>)?(?:\s*<p>(.*?)<\/p>)?/g,
                      (_, h4Text, h5Text, pText1, pText2) =>
                        processHeaderTags("h4", h4Text, pText1, pText2, h5Text)
                    );

                  return (
                    <div>
                      <div key={index}>
                        <ChunkWrapper
                          dangerouslySetInnerHTML={{
                            __html: parseMarkdown(s),
                          }}
                        />
                      </div>
                      {chunk_summary &&
                        chunk_summary.length > 0 &&
                        chunk_summary[0].text !== "" && (
                          <SliceSummary>
                            <SummaryIcon />
                            {RichText.render(chunk_summary)}
                          </SliceSummary>
                        )}
                    </div>
                  );
                })}
              </Section>
            </React.Fragment>
          ))}
        </Grid>
      </Wrapper>
    </React.Fragment>
  );
};

export default VerseByVerse;
