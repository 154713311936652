import React from "react";
import styled from "styled-components";
import breakpoint from "../breakpoint";


export const CenterButtonWrapper = styled.div`
	text-align: center;
	${breakpoint("md")`
      text-align: left;
    `};
     
    ${breakpoint("lg")`
        text-align: left;   
    `};
`;
const Wrapper = styled.button`
	appearance: none;
	border: none;
	border-radius: 40px;
	background-color: #ffffff;
	padding: 14px 20px;
	box-sizing: border-box;
	min-width: 71px;

	font-family: 'Cerebri Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 12px;
	text-align: center;

	cursor: pointer;
	vertical-align: bottom;
	display: inline-block;

	${breakpoint("md")`
      	font-size: 18px;
		line-height: 12px;
		padding: 26.5px 60px;

		
    `};
     
    ${breakpoint("lg")`
        font-size: 18px;
		line-height: 12px;
		padding: 26.5px 60px;
    `};


	${({ dark }) =>
		dark &&
		`
		background-color: #111112;
		color: #F4F1EE;	
	`}

	${({ darklightborder }) =>
		darklightborder &&
		`
		background-color: #111112;
		color: #F4F1EE;
		border: 1px solid #FFFFFF;
	`}

	${({ light }) =>
		light &&
		`
		border: 1px solid #111112;	
	`}

	${({ footer }) =>
		footer &&
		`
		background-color: #f4f1ee;
		color: #111112;	
		margin-bottom: 28px;

		${breakpoint("md")`
			padding: 18px 20px;
			cursor: pointer;
    	`};
	`}

	${({ book }) =>
		book &&
		`
		border:none;
		border-bottom: 1px solid rgb(17,17,18,0.1);
		border-radius: 0;
		background-color: transparent;
		min-width: 97px;

		font-size: 18px;
		line-height: 18px;
		color: #111112;	
		
		padding: 5px 0;
		margin-bottom: 10px;
	`}

	${({ next }) =>
		next &&
		`
		position: absolute;
		right: 7px;
		margin-top: 144px;
		max-width: 10px;
		max-height: 50px;

		border: none;
		background-color:  transparent;
		border-radius: 0;
		color: #62636B;
	`}
`;

const Button = ({ children, dark, footer, book, next, light, darklightborder, style, ...props }) => {
	return (
		<Wrapper
			{...props}
			dark={dark}
			footer={footer}
			book={book}
			next={next}
			style={style}
			light={light}
			darklightborder={darklightborder}
		>
			{children}
		</Wrapper>
	);
};

export default Button;
