import { HashLink as Link } from "react-router-hash-link";
import styled from "styled-components";

//styled(Link)`
export const StyledLink = styled(Link)`
	text-decoration: none;
	&:focus,
	&:hover,
	&:visited,
	&:link,
	&:active {
		text-decoration: none;
	}
	cursor: pointer;
`;
