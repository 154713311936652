import {
	Author,
	PlayButton,
	PlayerWrapper,
	TextWrapper,
	Thumbnail,
	Title,
	VideoContent,
	VideoWrapper,
	Wrapper,
} from "./styles";
import {
	CourseTitle,
	VideoModuleDescription,
} from "../../../../components/text";

import Grid from "../../../../components/Grid";
import React from "react";
import ReactPlayer from "react-player";

const Video = ({ mod }) => {
	const { items, primary } = mod.slices[0];

	const [playing, setPlaying] = React.useState(false);
	const [activeIndex, setActiveIndex] = React.useState(0);
	const [playerLink, setPlayerLink] = React.useState(
		items[activeIndex].video_url.url
	);

	const PlayImgBttn = require("../../../../assets/icons/play.svg").default;
	const PauseImgBttn = require("../../../../assets/icons/pause.svg").default;
	const [buttonImg, setButtonImg] = React.useState(PlayImgBttn);

	const [thumbnails, setThumbnails] = React.useState();

	React.useEffect(() => {
		(async function () {
			const thumbnails = [];

			for (const { video_url } of items) {
				// console.log({ video_url });

				let thumbnail = "";

				if (video_url.url.includes("youtube")) {
					thumbnail = `https://img.youtube.com/vi/${video_url.url
						.split("?v=")
						.slice(-1)}/sddefault.jpg`;
				} else if (video_url.url.includes("vimeo")) {
					const res = await fetch(
						"https://vimeo.com/api/v2/video/" +
							video_url.url.split("/").slice(-1) +
							".json"
					);
					const [{ thumbnail_medium }] = await res.json();

					thumbnail = thumbnail_medium;
				}

				thumbnails.push(thumbnail);
				// https://vimeo.com/74861955
			}
			setThumbnails(thumbnails);
			// console.log("My thumbnails", thumbnails);
		})();
	}, []);

	const PlayVideo = (url, index) => {
		setPlayerLink(url);
		setPlaying(true);
		setActiveIndex(index);

		setButtonImg(PauseImgBttn);
		if (playerLink === url && buttonImg === PauseImgBttn) {
			setPlaying(false);
			setButtonImg(PlayImgBttn);
		}
	};

	return (
		<Wrapper>
			<Grid>
				<TextWrapper>
					<CourseTitle
						style={{
							color: "#F4F1EE",
						}}
					>
						{mod.title}
					</CourseTitle>
					<VideoModuleDescription>
						{mod.description}
					</VideoModuleDescription>
				</TextWrapper>
				<PlayerWrapper className="player-wrapper">
					<ReactPlayer
						id="player"
						vimeo
						controls="true"
						playing={playing}
						className="react-player"
						width="100%"
						height="100%"
						style={{
							position: "absolute",
							top: "0",
							left: "0",
						}}
						url={playerLink}
					/>
				</PlayerWrapper>
				{thumbnails &&
					items.map(
						(
							{
								video_title,
								video_author,
								video_thumbnail,
								video_url,
							},
							index
						) => (
							<VideoWrapper
								onClick={() => PlayVideo(video_url.url, index)}
							>
								<Thumbnail
									image={
										thumbnails[index] || video_thumbnail.url
									}
								>
									<VideoContent
										key={index}
										active={index === activeIndex}
									>
										<PlayButton
											image={buttonImg}
										></PlayButton>
										<Title>{video_title}</Title>
										<Author>{video_author} </Author>
									</VideoContent>
								</Thumbnail>
							</VideoWrapper>
						)
					)}
			</Grid>
		</Wrapper>
	);
};

export default Video;
