import Search from "js-worker-search";
import uniq from "lodash.uniq";

export default (books) => ({
	byCategory: async (categoryId) => {
		if (categoryId === "all") {
			return books;
		}

		const search = new Search();

		for (const book of books) {
			search.indexDocument(
				book.id,
				book.categories.map(({ category }) => category.id).join(" ")
			);
		}

		const relevantIds = await search.search(categoryId);

		return books.filter(({ id }) => relevantIds.includes(id));
	},
	byQuery: async (query) => {
		const primarySearch = new Search();
		const secondarySearch = new Search();

		for (const book of books) {
			primarySearch.indexDocument(book.id, book.title);
			secondarySearch.indexDocument(
				book.id,
				[
					book.keywords,
					...book.categories
						.map(({ category }) => category.id)
						.join(" "),
				].join(" ")
			);
		}

		const relevantIds = uniq([
			...(await primarySearch.search(query)),
			...(await secondarySearch.search(query)),
		]);

		return books.filter(({ id }) => relevantIds.includes(id));
	},
});
