import { Link } from "react-router-dom";
import breakpoint from "../../../breakpoint";
import styled from "styled-components";

export const Wrapper = styled.div`
	padding: 24px 20px 20px 20px;
	box-sizing: border-box;
	border-radius: 13px;
	background-color: #eeeeee; 
	color: #111112;

	display: grid;
	grid-template-areas:
		"pin role"
		"title title"
		"body body"
		"link link";
	grid-template-columns: 18px 1fr;
	grid-template-rows: 27px 50px 130px 15px;

	max-width: 615px;

	width: 280px;
	height: 275px;

	${breakpoint("md")`
		grid-template-columns: 38px 1fr;
		padding: 57px 30px 28px 60px;
		grid-template-rows: 53px 79px 163px 32px;
		border-radius: 13px;
		width: 615px;
		height: 400px;

 	`};
	${breakpoint("lg")`
		grid-template-columns: 38px 1fr;
		padding: 57px 30px 28px 60px;
		grid-template-rows: 53px 79px 163px 32px;
		border-radius: 13px;
		width: 615px;
		height: 400px;
	`};

	${({ dark }) =>
		dark &&
		`
		background-color: #111112;

		${Role}{
			color: #A1A0A5;
		}

		${Title}{
			color: #F4F1EE;
		}

		${Body}{
			color: #A1A0A5;
		}

		${StyledLink}{
			color: #F4F1EE;
			&:hover{
				text-decoration-color: #F4F1EE;
				color: #F4F1EE;
			}
		}

	`}

	${({ light }) =>
		light &&
		`
		background-color: white;

	`}
`;

const pinImage = require("../../../assets/icons/Map Pin.svg").default;

export const Pin = styled.div`
	grid-area: pin;
	background-image: url("${pinImage}");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 25px;
	height: 28.29px;

	${breakpoint("md")`
		width: 38px;
		height: 43px;
	`};
	${breakpoint("lg")`
		width: 38px;
		height: 43px;
	`};
`;

export const Role = styled.h6`
	font-family: 'Cerebri Sans';
	font-size: 8px;
	line-height: 9px;
	/* identical to box height */

	text-transform: uppercase;

	color: #111112;
	grid-area: role;
	align-self: center;
	margin: 0;

	${breakpoint("md")`
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 2px;
	`};
	${breakpoint("lg")`
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 2px;
	`};
`;

export const Title = styled.h2`
	font-family: 'Cerebri Sans';
	font-size: 24px;
	line-height: 28px;

	color: #111112;

	grid-area: title;
	margin: 0;

	${breakpoint("md")`
	
		font-size: 40px;
		line-height: 37px;
	`};
	${breakpoint("lg")`

		font-size: 40px;
		line-height: 37px;
	`};
`;

export const Body = styled.p`
	font-family: 'Cerebri Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;

	color: #111112;

	grid-area: body;
	margin: 0;

	${breakpoint("md")`
		font-size: 22px;
		line-height: 28px;
	`};
	${breakpoint("lg")`
		font-size: 22px;
		line-height: 28px;
	`};
`;

export const StyledLink = styled.a`
	grid-area: link;
	font-family: 'Cerebri Sans';
	font-size: 12px;
	line-height: 9px;
	display: block;
	
	color: #111112;

	text-decoration: underline;
	text-decoration-color: rgba(17, 17, 18, 0.1);
	text-decoration-thickness: 1px;
	text-underline-offset: 4px;
	&:hover{
		text-decoration: underline;
		text-decoration-color: #111112;
		color: #111112;
	}
	width: 71px;

	${({ hide }) =>
		hide &&
		`
		display: none;
	`}

	${breakpoint("md")`
		
		font-size: 18px;
		line-height: 18px;
		width: 107px;
	`};
	${breakpoint("lg")`
		
		font-size: 18px;
		line-height: 18px;
		width: 107px;
	`};
`;
