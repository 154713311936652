import React from "react";
import { useParams } from "react-router-dom";

export default ({ mod }) => {
	const { courseSlug } = useParams();

	return (
		<React.Fragment>
			<header>{mod.title}</header>
			<p>Book in a picture module</p>
		</React.Fragment>
	);
};
