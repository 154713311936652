import {
	ButtonPlacement,
	ButtonWrapper,
	MoreBooks,
	PanelGrid,
	SearchIcon,
} from "./styles";
import {
	checkExpiry,
	getExpiry,
	getWithExpiry,
	setWithExpiry,
} from "../../scripts/expiringStorage";
import { useHistory, useLocation } from "react-router-dom";

import Button from "../../components/Button";
import Container from "../../components/Container";
import { LoadingText } from "../../components/Loading";
import Panel from "./Panel";
import React from "react";
import SearchBar from "./SearchBar";
import fetchBooks from "../../scripts/get/books";
import filter from "../../scripts/filterBooks";
import getBook from "../../scripts/get/book";
import sort from "../../scripts/sortBooks";
import { useGlobalState } from "../../scripts/useGlobalState";

export default ({ heroSearch, onSearchStateChanger, setHeroSearch }) => {
	const history = useHistory();
	const [query, setQuery] = React.useState(
		new URLSearchParams(useLocation().search).get("q") || ""
	);
	const [books, setBooks] = React.useState([]);
	const [selection, setSelection] = React.useState([]);
	const [delimiter, setDelimiter] = React.useState(4);
	const [sortType, setSortType] = React.useState("Popular");
	const [category, setCategory] = React.useState("all");
	const [buttonHidden, setButtonHidden] = React.useState(false);
	const [allBooks, setAllBooks] = React.useState([]);
	const [isBookLoading, setIsBookLoading] = useGlobalState("books");
	React.useEffect(() => {
		(async function () {
			let books = await fetchBooks();

			// Filter out hidden books if they're not supposed to show
			if (process.env.REACT_APP_SHOW_HIDDEN_BOOKS !== "TRUE") {
				books = books.filter(({ hide_book }) => !hide_book);
			}

			setBooks(books);

			let allbooks = await Promise.all(
				books.map(async ({ title }) => await getBook(title))
			);
			setAllBooks(allbooks);

			const selection = await filter(books).byQuery(query);
			// setSelection(selection);

			setSelection(sort(selection).by(sortType));
			selection.map(({ title }) => {
				checkExpiry(title, "book-marker.");
				checkExpiry(title, "index-");
			});

			if (query) {
				document.getElementById("search-results").scrollIntoView();
			}
		})();
	}, []);

	React.useEffect(() => {
		books.length > 0 &&
			allBooks.length > 0 &&
			selection.length > 0 &&
			setIsBookLoading(false);
	}, [books, allBooks, selection]);

	React.useEffect(() => {
		setButtonHidden(delimiter >= selection.length);
	}, [selection, delimiter]);

	const increaseDelimiter = () => {
		if (delimiter < books.length) {
			setDelimiter(delimiter + 4);
		}
	};

	React.useEffect(() => {
		(async function () {
			let selection = await filter(books).byQuery(query);
			selection = await filter(selection).byCategory(category);

			setSelection(sort(selection).by(sortType));
			selection.map(({ title }) => {
				checkExpiry(title, "book-marker.");
				checkExpiry(title, "index-");
			});
		})();
	}, [query, sortType, category]);

	return (
		<React.Fragment>
			<SearchBar
				value={query}
				sortType={sortType}
				category={category}
				onSort={(sortType) => setSortType(sortType)}
				onSearch={({ target }) => setQuery(target.value)}
				onCategory={(category) => setCategory(category)}
				heroSearch={heroSearch}
				setHeroSearch={setHeroSearch}
			/>
			<Container id="search-results">
				<PanelGrid>
					{selection.length > 0 ? (
						selection
							.slice(0, delimiter)
							.map(
								(
									{
										title,
										shortDescriptor,
										longDescriptor,
										image,
										slug,
										id,
									},
									key
								) => {
									let activeModule = getWithExpiry(
										`book-marker.${encodeURI(title)}`
									);
									let course =
										activeModule &&
										allBooks &&
										allBooks
											.find((el) => el.id === id)
											.courses.filter(
												({ modules }) =>
													!!modules.find(
														({ id }) =>
															id === activeModule
													)
											);
									let module =
										activeModule &&
										course &&
										course[0].modules.find(
											(el) => el.id === activeModule
										);
									let link = `/book/${encodeURI(title)}/${
										activeModule && course[0].slug
									}/${
										activeModule && encodeURI(module.title)
									}`;

									return (
										<Panel.Wrapper image={image} key={key}>
											<Panel.Content>
												<Panel.Title>
													{title}
												</Panel.Title>
												<Panel.ShortDescriptor>
													{shortDescriptor}
												</Panel.ShortDescriptor>
												<Panel.LongDescriptor>
													{longDescriptor}
												</Panel.LongDescriptor>
												<Panel.Button
													onClick={() =>
														activeModule
															? history.push(link)
															: history.push(
																	`/book/${encodeURI(
																		title
																	)}`
															  )
													}
													// ? history.push(link) : history.push(`/book/${encodeURI(title)}`)
												>
													{window.localStorage &&
													activeModule
														? "Jump Back In"
														: "Explore"}
												</Panel.Button>
												<Panel.ButtonMobile
													onClick={() =>
														activeModule
															? history.push(link)
															: history.push(
																	`/book/${encodeURI(
																		title
																	)}`
															  )
													}
												>
													{window.localStorage &&
													activeModule
														? "Jump Back In"
														: "Explore"}
												</Panel.ButtonMobile>
											</Panel.Content>
										</Panel.Wrapper>
									);
								}
							)
					) : (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								width: "100%",
								gridColumn: "1 / -1",
								margin: "200px 0px",
							}}
						>
							<LoadingText>Loading</LoadingText>
						</div>
					)}

					{!buttonHidden && (
						<ButtonWrapper style={{ gridColumn: "1/-1" }}>
							<PanelGrid>
								<MoreBooks />
								<MoreBooks hide />
								<ButtonPlacement>
									<Button
										onClick={increaseDelimiter}
										dark
										style={{
											display: "block",
											margin: "0 auto",
											fontWeight: "bold",
										}}
									>
										Load more books
									</Button>
									<Button
										onClick={onSearchStateChanger}
										dark
										style={{
											margin: "10px auto",
											fontWeight: "bold",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										Search
										<SearchIcon />
									</Button>
								</ButtonPlacement>
							</PanelGrid>
						</ButtonWrapper>
					)}
				</PanelGrid>
			</Container>
		</React.Fragment>
	);
};
