import slugify from "slugify";

const formatModule = ({
  module_title,
  module: { slug, id },
  reading_time,
}) => ({
  slug,
  id,
  title: module_title,
  reading_time,
});

export default ({ data, id, slugs }) => {
  const {
    hide_book,
    contributors,
    image,
    long_descriptor,
    short_descriptor,
    keywords,
    title,
    categories,
    body,
    email_series_title,
    email_series_description,
    email_series_time,
    email_series_popup_body,
  } = data;

  return {
    hide_book,
    id,
    contributors: contributors || [],
    slug: slugs[0],
    image: image
      ? image.url
      : require("../../assets/images/no-image.png").default,
    longDescriptor: long_descriptor.length
      ? long_descriptor.map(({ text }) => text).join("<br/>")
      : "",
    shortDescriptor: short_descriptor.length ? short_descriptor[0].text : "",
    title: title || "Untitled",
    emailSeriesTitle: email_series_title || "A month in ",
    emailSeriesDescription:
      email_series_description || "Daily study in your inbox",
    emailSeriesTime: email_series_time || "30 days",
    emailSeriesPopupBody:
      email_series_popup_body ||
      "Peceive one email a day for the next month, unpacking the depth (Opt out at any time).",
    keywords: keywords ? keywords.split(", ") : [],
    categories: categories || [],
    courses: body.map(
      ({ items, primary: { course_title, course_description } }) => ({
        title: course_title,
        slug: slugify(course_title, { lower: true }),
        description: course_description,
        modules: items.map(formatModule),
      })
    ),
    compile: function () {
      return [
        // this.contributors.map(({ name }) => name),
        // this.image,
        // this.longDescriptor,
        this.category,
        this.keywords,
        this.title,
      ].join(" ");
    },
  };
};
