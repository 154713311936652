import Grid from "./Grid";
import React from "react";
import breakpoint from "../breakpoint";
import styled, { keyframes } from "styled-components";
import ReactPlayer from "react-player";
import { Subheading } from "../components/text";
import { CenterButtonWrapper } from "../components/Button";
import videoForHome from "../assets/videos/BFLHomepageVideo.mp4";
import { useGlobalState } from "../scripts/useGlobalState";

const Wrapper = styled.header`
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	min-height: 280px;
	width: 100%;

	padding: 33px 0;
	box-sizing: border-box;
	background-size: 600%;
	background-image: linear-gradient(325deg, #f6d5f7, #fbe9d7, #c8e7f7);

	${breakpoint("md")`
		min-height: 440px;
		padding: 50px 0;
		// height: 80vh;
    `}
	${breakpoint("lg")`
		min-height: 730px;
		padding: 122px 48px;
		// padding-top: 192px;
		height: 80vh;
    `}
`;

const TextWrapper = styled.div`
	grid-column: 1 / -1;
	margin: 0 16px;
	/* margin: 0 10vw; */

	${Subheading}{
		margin-bottom: 36px;
	}

	@media only screen and (min-width: 490px) and (max-width: 700px) {
		margin: 0 10vw;

	}

	${CenterButtonWrapper}{
			text-align: center;
		}

	${breakpoint("md")`
		margin: 0;

		${Subheading}{
			margin-bottom: 70px;
		}

		${CenterButtonWrapper}{
			text-align: left;
		}
	`}

	${breakpoint("lg")`
		grid-column: 1 / 7;

		${Subheading}{
			margin-bottom: 140px;
		}

		${CenterButtonWrapper}{
			text-align: left;
		}
	`}
`;

const AsideWrapper = styled.div`
	display: none;
	height: 486px;
	width: 486px;
	pointer-events: none;

	/* background: #C4C4C4; */

	padding: 0;
	margin: 0;

	background-image: ${({url}) => url || '#C4C4C4'};
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

	border-radius: 50%;

	position: relative;
	//added new break points for video responsiveness
	@media screen and (min-width: 1185px) {
		grid-column: 8 / -1;
		display: block;
	}

	/* justify-content: center; */

	&:after{
		content:"";
		padding-bottom: 100%;
		display: block;
	}

`;

export const PlayerWrapper = styled.div`
	position: absolute;
	height: 486px;
	width: 486px;
`;

const Banner = ({ image, children, aside, playerLink, className, urlThumb }) => {
	const [videoLoading, setVideoLoading] = useGlobalState('video')
	const [loading, setLoading] = useGlobalState('loading')
	return (
		<Wrapper image={image} className={className}>
			<Grid banner>
				<TextWrapper>{children}</TextWrapper>
				{aside && <AsideWrapper url={urlThumb} >

					<video autoPlay loop muted playsInline onCanPlayThrough={() => setVideoLoading(false)}
						style={{
							position: "absolute",
							left: '0',
							top: '0',
							objectFit: "cover",
							width: "486px",
							height: "486px",
							borderRadius:"50%"

						}}>
						<source
							type="video/mp4"
							src={videoForHome}

						/>
					</video>

					{/* <PlayerWrapper className="player-wrapper">
						<ReactPlayer
							id="player"
							// vimeo
							// playing="false"
							controls ="true"
							className="react-player"
							width="100%"
							height="100%"
							style={{
								position: "absolute",
								top: "0",
								left: "0",
							}}
							url={playerLink}
							playsinline="false"
						/>
					</PlayerWrapper> */}
				</AsideWrapper>}
			</Grid>
		</Wrapper>
	);
};

export default Banner;
