import React from "react";
import styled from "styled-components";

const ANIMATION_DURATION = 230;

const Wrapper = styled.div.attrs({ className: "card-overlay-video-wrapper" })`
  position: absolute;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: opacity ${ANIMATION_DURATION}ms;
  will-change: opacity;

  ${({ show }) =>
    show
      ? `opacity: 1; pointer-events: all;`
      : `opacity: 0; pointer-events: none;`}

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export default ({ src, show, onHide }) => {
  const videoRef = React.useRef(null);

  const clickOffListener = (event) => {
    if (!event.target.className.includes("card-overlay-video-wrapper")) {
      event.stopPropagation();
      onHide();
    }
  };

  const handleVideoEnd = () => {
    if (videoRef.current) {
      setTimeout(() => {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
        videoRef.current.load(); // recommended on SO — https://stackoverflow.com/questions/8402158/html5-video-javascript-controls-restart-video
      }, ANIMATION_DURATION);
      onHide();
    }
  };

  React.useEffect(() => {
    if (videoRef.current) {
      if (show) {
        videoRef.current.play();

        window.addEventListener("click", clickOffListener);
      } else {
        setTimeout(() => {
          videoRef.current.pause();
          videoRef.current.currentTime = 0;
          videoRef.current.load(); // recommended on SO — https://stackoverflow.com/questions/8402158/html5-video-javascript-controls-restart-video
        }, ANIMATION_DURATION);

        window.removeEventListener("click", clickOffListener);
      }
    }

    return () => window.removeEventListener("click", clickOffListener);
  }, [show, videoRef]);

  return (
    <Wrapper show={show}>
      <video
        ref={videoRef}
        muted
        // loop
        controls={false}
        className={"card-overlay-video-wrapper-video"}
        onEnded={handleVideoEnd}
      >
        <source src={src} type="video/mp4" />
      </video>
    </Wrapper>
  );
};
