import * as Styled from "./styles";

export default ({photo, title, role, bio, link, hide }) => (
	<Styled.Wrapper>
        <Styled.Photo image={photo} />
        <Styled.Bio>{bio}</Styled.Bio>
        <Styled.DesktopLink href={link} target="_blank" hide={hide}>View website</Styled.DesktopLink>
        <Styled.Role>{role}</Styled.Role>
        <Styled.Title>{title}</Styled.Title>
        <Styled.MobileLink href={link} target="_blank" hide={hide}>View website</Styled.MobileLink>
        
        
            
	</Styled.Wrapper>
);