import { VideoTitle } from "../../../../components/text";
import breakpoint from "../../../../breakpoint";
import styled from "styled-components";

export const Wrapper = styled.header`
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	margin-top: 32px;

    ${breakpoint("md")`
        margin-top: 52px;
	`}
    ${breakpoint("lg")`
        margin-top: 80px;
	`}
`;

export const TextWrapper = styled.div`
	grid-column: 1 / -1;
	padding: 0;
	margin: 0;
	text-align: center;
`;

export const PlayerWrapper = styled.div`
	position: relative;
	padding-top: 56.25%;
	margin: 0 auto;

	height: 100%;
	width: 100%;

	grid-column: 1 / -1;
`;

export const VideoWrapper = styled.div`
	height: 100%;
	width: 100%;
	grid-column: 1 / -1;
	text-align: center;

	${breakpoint("md")`
        grid-column: span 2;
    `};

	${breakpoint("lg")`
        grid-column: span 3;
    `};
`;

export const Thumbnail = styled.div`
	width: 300px;
	height: 130px;
	border-radius: 8px;
	position: relative;
	margin: 0 auto;
	
	background-image: url(${({ image }) => image});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	&:before{
		position: absolute;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		opacity: 0.7;
		/* background-color: red; */
	}
	
	${breakpoint("md")`
        width: 235px;
        height: 155px;
    `}

	${breakpoint("lg")`
        width: 285px;
        height: 155px;
    `}
`;

export const PlayButton = styled.button`
	grid-area: button;
	border: 0;

	background-image: url(${({ image }) => image});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	z-index: 10;
	border-radius: 50%;

	width: 35px;
	height: 35px;
	display: ${(props) => (props.active ? "none" : "block")};

	${breakpoint("md")`
        width: 60px;
        height: 60px;
    `};
	${breakpoint("lg")`
        width: 60px;
        height: 60px;
    `};
`;

export const Title = styled(VideoTitle)`
	grid-area: title;
	padding-bottom: 3px;
	margin: 0;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const Author = styled(VideoTitle)`
	padding-bottom: 1px;
	grid-area: author;
	color: #d8d8d8;
	font-weight: normal;
	line-height: 20px;
	margin: 0;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const VideoContent = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	max-width: 300px;
	max-height: 130px;
	width: 100%;
	height: 100%;
	background-color: ${(props) =>
		props.active ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.65)"};

	border-radius: 8px;

	${PlayButton} {
		display: ${({ active }) => (active ? "block" : "none")};
	}

	&:hover,
	:active,
	:focus {
		background-color: rgba(0, 0, 0, 0);
		cursor: pointer;
	}

	box-sizing: border-box;
	display: grid;
	grid-template-areas:
		"button"
		"title"
		"author";

	padding: 11px 16px;
	margin: 0;
	grid-template-rows: 65px 22px 21px;
	text-align: left;
	overflow: hidden;

	${breakpoint("md")`
        max-width: 235px;
        max-height: 155px;
        grid-template-rows: 90px 22px 21px;
    `};

	${breakpoint("lg")`
        max-width: 285px;
        max-height: 155px;
        grid-template-rows: 90px 22px 21px;
    `};
`;
