import {
	ChapterTitle,
	Content,
	PageDescription,
	PageSection,
	PageTitle,
	PageTitleWrapper,
	Section,
	Underline,
	Wrapper,
} from "./styles";

import Grid from "../../../../components/Grid";
import React from "react";
import { RichText } from "prismic-dom";
import { parse as parseMarkdown } from "marked";

const Text = ({ mod }) => {
	const { items, primary } = mod.slices[0];

	return (
		<Wrapper>
			<Grid>
				<PageTitleWrapper>
					<PageTitle>{mod.title}</PageTitle>
					<PageDescription>{mod.description}</PageDescription>
				</PageTitleWrapper>
				{mod.slices.map(({ primary, items }) => (
					<React.Fragment>
						<PageSection>{primary.slice_title}</PageSection>
						<Underline></Underline>
						{items.map(({ section_title, section_content }) => {
							const s = RichText.asHtml(section_content)
								.replace(
									new RegExp(String.fromCharCode(194), "g"),
									String.fromCharCode(32)
								)
								.replace(
									new RegExp(String.fromCharCode(160), "g"),
									""
								)
								.replace(/\|<\/p><p><\/p><p>\|/g, "|\n\n|") // End of table line
								.replace(
									/\|<\/p><p><\/p><p><\/p><p>\|/g,
									"|\n\n\n|"
								)
								.replace(
									/\|<\/p><p><\/p><p><\/p><p><\/p><p>\|/g,
									"|\n\n\n\n|"
								)
								.replace(/\|<\/p><p><\/p><p>/g, "|\n") // End of the table
								.replace(/\|<\/p><p>\|/g, "|\n|") // end of table row
								.replace(/<\/p><p><\/p><p>\|/g, "\n\n|")
								.replace(/<\/p>\|/g, "\n|")
								.replace(/<p>\|/g, "|")
								.replace(/\|<\/p>/g, "|")
								.replace(/\| <\/p>/g, "|");

							const m = parseMarkdown(s);

							return (
								<Section>
									<Grid fullwidth style={{ padding: 0 }}>
										<ChapterTitle>
											{section_title}
										</ChapterTitle>
										<Content>
											<div
												dangerouslySetInnerHTML={{
													__html: m,
												}}
											/>
										</Content>
									</Grid>
								</Section>
							);
						})}
					</React.Fragment>
				))}
			</Grid>
		</Wrapper>
	);
};

export default Text;
