import {
	BookshelfIcon,
	Input,
	InputGroup,
	SearchIcon,
	SortGroup,
	SortOption,
	SortUnderline,
	Wrapper,
} from "./styles";

import PopupList from "../../../components/PopupList";
import React from "react";
import fetchCategories from "../../../scripts/get/categories";

const data = [
	{
		title: "Pentateuch",
	},
    {
		title: "History books",
	},
    {
		title: "Wisdom literature",
	},
    {
		title: "Major prophets",
	},
    {
		title: "Minor prophets",
	},
    {
		title: "Gospels & Acts",
	},
    {
		title: "Paul's letters",
	},
    {
		title: "General letters",
	},

]

export default ({
	value,
	onSearch,
	sortType,
	onSort,
	category,
	onCategory,
	heroSearch,
	setHeroSearch
}) => {
	const [inputActive, setInputActive] = React.useState(false);
	const [bookshelfActive, setBookshelfActive] = React.useState(false);
	const [sortUnderlinePos, setSortUnderlinePos] = React.useState({});
	const inputRef = React.useRef(null);
	const [categories, setCategories] = React.useState([]);

	const updateUnderlinePos = (sortType) => {
		if (document.querySelector(`.sort-option-${sortType}`)) {
			const { left, height, width } = document
				.querySelector(`.sort-option-${sortType}`)
				.getBoundingClientRect();

			setSortUnderlinePos({ left, height, width });
		}
	};

	const handleResize = () => {
		const activeOption = document.querySelector("[data-active-option]")
			.dataset.activeOption;

		updateUnderlinePos(activeOption);
	};

	const sortList = (categories) => {
		const sortedList = [];
		
		for ( var sortedIndex = 0; sortedIndex < data.length; sortedIndex++){
			for (var index = 0; index < categories.length; index++ ){
				if(data[sortedIndex].title==categories[index].title){
					sortedList.push(categories[index]);
				}
			}
		}

		setCategories(sortedList);
	}

	React.useEffect(() => {
		(async function () {
			const categories = await fetchCategories();

			sortList(categories);

		})();

		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	

	React.useEffect(updateUnderlinePos.bind(null, sortType), [sortType]);

	const handleCategoryChange = (value) => {
		onCategory(value);
	};

	React.useEffect(() => {
		if (inputRef.current && inputActive ) {
			inputRef.current.focus();
		}
	}, [inputActive]);

	React.useEffect(() => {
		if (heroSearch && !inputActive) {
		  setTimeout(() => {
			setInputActive(true);
		  }, 100); 
		}
	  }, [heroSearch, inputActive]);


	const handleBlur = () => {
		setInputActive(false); 
		setHeroSearch(false);
	}

	return (
		<Wrapper inputActive={inputActive}>
			<InputGroup>
				<SearchIcon onClick={() => {setInputActive(true);}} />
				<Input
					ref={inputRef}
					value={value}
					onChange={onSearch}
					onBlur={()=>handleBlur()}
					onFocus={() => setInputActive(true)}
				/>
				<BookshelfIcon onClick={() => setBookshelfActive(true)} />

				<PopupList
					options={[
						{ value: "all", title: "All" },
						...categories.map(({ title, id}) => ({
							title,
							value: id,
						})),
					]}
					onChange={handleCategoryChange}
					show={bookshelfActive}
					onClose={() => setBookshelfActive(false)}
				/>
			</InputGroup>
			<SortGroup data-active-option={sortType}>
				{["Popular", "Traditional", "Alphabetical"].map((sort, key) => (
					<SortOption
						key={key}
						className={`sort-option-${sort}`}
						active={sort === sortType}
						onClick={() => onSort(sort)}
					>
						{sort}
					</SortOption>
				))}
				<SortUnderline {...sortUnderlinePos} />
			</SortGroup>
		</Wrapper>
	);
};
