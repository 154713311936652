import getEase from "./getEase";
import radians from "./radians";

export default (context, radius, borderWidth) => {
	const numberOfFrames = 45;

	const render = (frameIndex) => {
		const head = radians(
			getEase(Math.max(0, frameIndex), 0, 360, numberOfFrames)
		);

		context.clearRect(0, 0, radius * 2, radius * 2);
		context.beginPath();

		context.arc(radius, radius, radius - borderWidth, radians(0), head);
		context.stroke();

		if (frameIndex < numberOfFrames)
			window.requestAnimationFrame(render.bind(null, frameIndex + 1));
	};

	render(0);
};
