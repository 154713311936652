import { DropDownText } from "./text";
import Grid from "./Grid";
import { Link } from "react-router-dom";
import React from "react";
import breakpoint from "../breakpoint";
import styled from "styled-components";

const Wrapper = styled.div`
	width: 300px;
	max-width: 300px;
	right: 0;
	margin: 0;

	background:  rgba(216,216,216, 0.4)!important;
	/* rgba(98,99,107, 0.4); 
	#D8D8D8*/
	/* mix-blend-mode: normal; */

	/* opacity: 0.4; */
	border-radius: 12px;

	overflow: hidden;

	${breakpoint("md")`
        width:  531px;
        max-width: 531px;
    `};

	${breakpoint("lg")`
       max-width: 531px;
       width:  531px;
    `};
`;

const Line = styled.div`
	padding: 0;
	margin: 0;
	border-bottom: 0.544256px solid rgba(98, 99, 107, 0.5);
`;

const StyledLink = styled(Link)`
	text-decoration: none;
	color: #62636B; 
	/* #62636B; */

	${breakpoint("md")`
    `};

	${breakpoint("lg")`
    `};

	&:focus,
	&:visited,
	&:link,
	&:active {
		text-decoration: none;
	}
	&:hover, &:focus,&:active {
		${DropDownText} {
			color: #252525;
			background: rgba(255, 255, 255, 0.4);
		}

		background: #ffffff;
	}
`;

export const ChapterWrapper = styled.div`
	margin: 0;
	padding: 14px 25px;
	display: block;
	width: 100%;

	${breakpoint("md")`
        padding: 28px 55px;
    `};

	${breakpoint("lg")`
        padding: 28px 55px;
    `};
`;

// export const PopoverWrapper =  styled.div`
// 	max-width: 1440px;
// 	width: 100%auto;
// 	margin-right: auto;
// `;

const DropDownModal = ({ options, onChange}) => {
	return (
		<Wrapper>
            {/* <StyledLink>
                <DropDownText>
                    <ChapterWrapper>{options}</ChapterWrapper>
                </DropDownText>
            </StyledLink> */}
            {
                options.map((option, index) => (
                    <StyledLink onClick={() => onChange(index)}>
                        <DropDownText>
                            <ChapterWrapper>{option}</ChapterWrapper>
                        </DropDownText>
                        <Line />
                    </StyledLink>
                ))
            }
            <Line />
        </Wrapper>
        

	);
};

export default DropDownModal;
