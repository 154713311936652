import {
	ArtistName,
	CourseTitle,
	StoryParagraph,
	VideoModuleDescription,
} from "../../../../components/text";

import breakpoint from "../../../../breakpoint";
import styled from "styled-components";

export const Wrapper = styled.div`
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	margin-top: 32px;

    ${breakpoint("md")`
        margin-top: 52px;
	`}
    ${breakpoint("lg")`
        margin-top: 80px;
	`}
`;

export const TextWrapper = styled.div`
	grid-column: 1 / -1;
	padding: 0;
	margin: 0;
	text-align: center;
`;

export const ModuleTitle = styled(CourseTitle)`
	color: #f4f1ee;
	margin-bottom: 5px;
	padding: 0;

	${breakpoint("md")`
        margin-bottom: 20px;
    
    `};
	${breakpoint("lg")`
        margin-bottom: 20px;
    
    `};
`;

export const ImageWrapper = styled.div`
	/* display: block; */
	margin: 0 auto 30px auto;
	padding: 0;
	grid-column: 1 / -1;
	width: 100%;
	max-width: 300px;
	max-height: 100%;

	position: relative;

	${breakpoint("md")`
        width: 100%;
        max-width: 784px;
        max-height: 100%;
        margin: 0 auto 50px auto;
    `}

	${breakpoint("lg")`
        width: 100%;
        max-width: 1360px;
        max-height: 100%;
        margin: 0 auto 50px auto;
    `}
`;

export const Picture = styled.img`
	width: 100%;
	height: 100%;
	/* background-image: url(${({ image }) => image}); */
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	border-radius: 6px;

	${breakpoint("lg")`
        border-radius: 20px;
    `}
`;

export const ArtworkTitle = styled(VideoModuleDescription)`
	opacity: 1;
	margin-bottom: 5px;
	padding: 0;

	${breakpoint("md")`
        margin-bottom: 10px;
    `};

	${breakpoint("lg")`
        margin-bottom: 10px;
    `};
`;

export const ArtworkDescription = styled(StoryParagraph)`
	color: #f4f1ee;
`;

export const LeftSideWrapper = styled.div`
	grid-column: 1 / -1;
	padding: 0;
	margin: 0;

	${breakpoint("md")`
        grid-column: 1 / 3;
    `};

	${breakpoint("lg")`
        grid-column: 1 / 4;
    `};
`;

export const RightSideWrapper = styled.div`
	grid-column: 1 / -1;

	${breakpoint("md")`
        grid-column: 3 / -1;
            
    `};

	${breakpoint("lg")`
        grid-column: 5 / 10;
    `};
`;

export const PictureGroup = styled.div`
	margin: 0;
	margin-bottom: 50px;
	padding: 0;
	box-sizing: border-box;

	${breakpoint("lg")`
        margin-bottom: 100px; 
    `};
`;
