import Button, { CenterButtonWrapper } from "../components/Button";
import { Heading, Subheading } from "../components/text";

import AboutContents from "../blocks/AboutContents";
import Banner from "../components/AboutBanner";
import Footer from "../components/Footer";
import { HashLink as Link } from "react-router-hash-link";
import React from "react";
import smoothscroll from "smoothscroll-polyfill";
import { useGlobalState } from "../scripts/useGlobalState";

smoothscroll.polyfill();

const About = () => {
  const [loading, setLoading] = useGlobalState("loading");
  const [teamLoading, setTeamLoading] = useGlobalState("team");
  const [testimonialsLoading, setTestimonialsLoading] =
    useGlobalState("testimonials");
  const [videoLoading, setVideoLoading] = useGlobalState("video");

  React.useEffect(() => {
    setLoading(true);
    setTeamLoading(true);
    setTestimonialsLoading(true);
    setVideoLoading(true);
  }, []);
  React.useEffect(() => {
    !teamLoading && !testimonialsLoading && !videoLoading && setLoading(false);
  }, [teamLoading, testimonialsLoading, videoLoading]);
  return (
    <React.Fragment>
      <Banner
        image={require("../assets/images/about-bg.jpg")}
        playerLink="https://player.vimeo.com/video/343697417?title=0&byline=0&portrait=0&playsinline=0&autopause=0&app_id=122963"
      >
        <Heading style={{ color: "#f4f1ee" }}>About</Heading>
        <Subheading style={{ color: "#f4f1ee" }}>
          The story of Bible for life and all the wonderful people involved.
        </Subheading>
        <CenterButtonWrapper>
          <Link smooth="true" to="/#search">
            <Button>Return to books</Button>
          </Link>
        </CenterButtonWrapper>
      </Banner>
      <AboutContents />
      <Footer />
    </React.Fragment>
  );
};

export default About;
