import breakpoint from "../../../../breakpoint";
import styled from "styled-components";

export const Wrapper = styled.div`
	background-color: #f4f1ee;
	border: 1px solid #fffef2;
	border-radius: 21px;
	box-shadow: 0px 0px 30px rgba(151, 151, 151, 0.2);
	padding: 17px 19px;
	box-sizing: border-box;
	width: 233px;
	height: 100%;
	margin: 0;
	position: relative;

	${breakpoint("md")`
		width: 422px;
		padding: 71px 31px 47px 31px;
	`}

	ul {
		padding: 0;
		list-style-type: none;
		${breakpoint("md")`
		`}
	}

	.card-overlay-video-wrapper {
		border-radius: 21px;
		overflow: hidden;
	}
`;

export const CardLine = styled.div`
	margin: 0;
	border-bottom: 2px solid #979797;
	padding: 16px 0 0 0;
	display: block;

	${breakpoint("md")`
		padding: 40px 0 0 0;
	`}
`;

export const CardTitle = styled.div`
	position: relative;

	width: 195px;
	height: 46px;

	display: flex;
	align-content: flex-start;
	justify-content: center;
	flex-direction: column;

	margin: 0 auto;

	${breakpoint("md")`
		align-content: center;
		justify-content: center;
		width: 192px;
		height: 72px; 
	`}

	h4 {
		position: absolute;
		align-items: center;

		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 23px;

		${breakpoint("md")`
			text-align: center;
			align-self: center; 

			font-size: 28px;
			line-height: 36px;
		`}
	}
`;

export const ListItem = styled.li`
	padding: 12px 4px;
	border-bottom: 1px solid #979797;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;
	position: relative;
	vertical-align: middle;
	/* height: 48px; */

	h6 {
		font-weight: inherit;
		font-size: inherit;
		width: calc(100% - 60px);
		margin: 0;
		color: #111112;
	}

	span {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 8px;
		text-align: right;
		color: #62636b;
		display: block;
	}

	button {
		display: none;
		background: #ffffff;
		border-radius: 40px;
		padding: 8px 14px 6px;
		box-sizing: border-box;
		appearance: none;
		border: none;
		font-size: 11px;
		line-height: 12px;
		margin-left: auto;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 8px;
	}

	&:hover {
		background-color: #e4e0dd;

		button {
			display: block;
		}
		span {
			display: none;
		}
	}

	${({ active }) =>
		active &&
		`
		background-color: #e4e0dd;
	`}

	${breakpoint("md")`
			padding: 17px 21px;
			font-size: 16px;
            line-height: 20px;
		`}
`;
