import getEase from "./getEase";
import initContext from "./initContext";
import radians from "./radians";

export default (context, radius, borderWidth) => {
	const numberOfFrames = 60,
		displacement = 7;

	const render = (frameIndex) => {
		const tail = radians(
			getEase(
				Math.max(0, frameIndex - displacement),
				0,
				359,
				numberOfFrames - displacement
			)
		);
		const head = radians(
			getEase(
				Math.min(frameIndex, numberOfFrames - displacement),
				0,
				360,
				numberOfFrames - displacement
			)
		);

		context.clearRect(0, 0, radius * 2, radius * 2);
		context.beginPath();

		context.arc(radius, radius, radius - borderWidth, head, tail);
		context.stroke();

		if (frameIndex < numberOfFrames)
			window.requestAnimationFrame(render.bind(null, frameIndex + 1));
	};

	render(0);
};
