
import {ModuleTitle, ModuleDescription, StoryParagraph} from "../../../../components/text";
import breakpoint from "../../../../breakpoint";
import styled from "styled-components";

export const Wrapper = styled.header`
	margin: 0;
	padding: 0;
	box-sizing: border-box;
    background-color: rgba(244, 241, 238, 1);

    margin-top: 32px;

    ${breakpoint("md")`
        margin-top: 52px;
	`}
    ${breakpoint("lg")`
        margin-top: 80px;
	`}
`;

export const PageTitleWrapper = styled.div`
    grid-column: 1 / -1;
	padding: 0;
	margin: 0;

	${breakpoint("lg")`
		grid-column: 1 / 8;
	`}
`;

export const PageTitle = styled(ModuleTitle)`
    color: #111112;
	margin-bottom: 18px;

	${breakpoint("md")`
		margin-bottom: 53px;
    `};
     
    ${breakpoint("lg")`
		margin-bottom: 50px;
    `};
`;

export const PageDescription = styled(ModuleDescription)`
    color: #111112;
    
	margin-bottom: 40px;

    ${breakpoint("md")`
		margin-bottom: 50px;
    `};
     
    ${breakpoint("lg")`
		margin-bottom: 50px;
    `};

`;


export const Content = styled(StoryParagraph)`
    grid-column: 1 / -1; 

    color: #62636B;

    h4{
        color: #111112;
        font-weight: bold;
        font-size: 13px;
        line-height: 15px;
        margin-bottom: 6px;
    }
    ${breakpoint("md")`
        h4{
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 16px;
        }
    `};

    ${breakpoint("lg")`
		grid-column: 5 / -1;
        h4{
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 30px;
        }
    `};
`;