import "./custom.css";

import * as Styles from "./styles";

import { Link, useLocation } from "react-router-dom";
import { getWithExpiry, setWithExpiry } from "../../scripts/expiringStorage.js";

import Button from "../../components/Button";
import Carousel from "../../components/Carousel";
import Grid from "../../components/Grid";
import Headroom from "react-headroom";
import Modal from "../../components/Modal";
import ModuleSwitch from "./ModuleSwitch";
import React from "react";
import ToolsofEngagement from "../../blocks/ToolofEngagement";
import getNextURL from "./scripts/nextURL";
import getPrevURL from "./scripts/prevURL";
import parseURL from "./scripts/parseURL";
import { useGlobalState } from "../../scripts/useGlobalState";

export default () => {
	const location = useLocation();
	const [book, setBook] = React.useState();
	const [course, setCourse] = React.useState();
	const [mod, setMod] = React.useState();

	const [modalState, setModalState] = React.useState(false);
	const [modalTitle, setModalTitle] = React.useState();
	const [modalType, setModalType] = React.useState();
	const [modalDescription, setModalDescription] = React.useState();
	const [modalContent, setModalContent] = React.useState();
	const [loading, setLoading] = useGlobalState("loading");

	// referring to verse by verse empty div at bottom of content
	const footerRef = React.useRef();
	const openModal = ({ type, title, description, content }) => {
		var footer = document.getElementById("moduleFooter");
		footer.style.display = "none";

		// var nav = document.getElementById("navigation");
		// nav.style.display = "none";
		setModalType(type);
		setModalTitle(title);
		setModalDescription(description);
		setModalContent(content);
		setModalState(true);
	};

	const closeModal = () => {
		var footer = document.getElementById("moduleFooter");
		footer.style.display = "block";

		//scroll to empty div at bottom page
		footerRef.current.scrollIntoView();
		// var nav = document.getElementById("navigation");
		// nav.style.display = "block";
		setModalState(false);
	};

	React.useEffect(() => {
		(async function () {
			// Could add a window.scrollTo here and
			// replace the `a` tags with `Link`s
			setLoading(false);
			const { book, course, mod } = await parseURL(location.pathname);
			setBook(book);
			setCourse(course);
			setMod(mod);

			if (window.localStorage) {
				setWithExpiry(
					`book-marker.${encodeURI(book.title)}`,
					mod.id,
					2592000000
				);
			}

			if (window.location.hash) {
				const targetId = window.location.hash.substring(1);
				const targetElement = document.getElementById(targetId);
				const targetParagraph =
					document.getElementsByClassName(targetId);

				function removeStylesFromTarget() {
					if (targetElement) {
						targetElement.style.color = "black";
					}
					if (targetParagraph[0]) {
						targetParagraph[0].style.color = "#62636B";
						targetParagraph[0].style.fontWeight = "normal";
					}
					if (targetParagraph[1]) {
						targetParagraph[1].style.color = "#62636B";
						targetParagraph[1].style.fontWeight = "normal";
					}
				}

				if (targetElement) {
					targetElement.style.scrollMarginTop = "30px";
					targetElement.style.color = "#ff8f10";
				}
				if (targetParagraph[0]) {
					targetParagraph[0].style.color = "#ff8f10";
				}
				if (targetParagraph[1]) {
					targetParagraph[1].style.color = "#ff8f10";
				}

				document.addEventListener("click", function (event) {
					if (event.target !== targetElement) {
						removeStylesFromTarget();
					}
				});
			}

			window.onscroll = function (ev) {
				const headroomFooter =
					document.getElementById("headroom-footer");

				if (headroomFooter) {
					if (
						window.innerHeight + window.scrollY >=
						document.body.offsetHeight
					) {
						headroomFooter.classList.remove("headroom--unpinned");
						headroomFooter.classList.add("headroom--pinned");
					}
				}
			};
		})();
	}, [location]);

	const nextURL = book && getNextURL({ book, url: location.pathname });
	const prevURL = book && getPrevURL({ book, url: location.pathname });

	return book && course && mod ? (
		<React.Fragment>
			{modalState && (
				<Modal
					onClose={() => closeModal()}
					title={modalTitle}
					type={modalType}
					description={modalDescription}
					content={modalContent}
				/>
			)}
			<Styles.Wrapper
				id="moduleFooter"
				light={["text", "verse_by_verse", "prayer"].includes(mod.slug)}
			>
				<Styles.HeaderWrapper>
					<Grid>
						<Styles.Title style={{ zIndex: 20 }}>
							{course.title}
						</Styles.Title>
						<Styles.ButtonWrapper style={{ zIndex: 20 }}>
							<Link
								to={{
									pathname: `/book/${encodeURI(book.title)}`,
									state: { fromBookItem: true },
								}}
							>
								<Button
									light={[
										"podcast",
										"picture",
										"video",
									].includes(mod.slug)}
									dark={[
										"text",
										"verse_by_verse",
										"prayer",
									].includes(mod.slug)}
									style={{
										border: "1px solid #FFFFFF",
										padding: "11px 20px 9px",
										fontSize: "14px",
									}}
								>
									Contents
								</Button>
							</Link>
						</Styles.ButtonWrapper>
						<Styles.Underline />
					</Grid>
				</Styles.HeaderWrapper>

				<Styles.ContentWrapper>
					<ModuleSwitch book={book} course={course} mod={mod} />

					{mod.tools_of_engagement &&
					mod.tools_of_engagement.length ? (
						<Grid fullWidth>
							<ToolsofEngagement
								light={[
									"text",
									"verse_by_verse",
									"prayer",
								].includes(mod.slug)}
							>
								<Carousel
									type="toolOfEngagement"
									onOpenModal={openModal}
									tools={mod.tools_of_engagement}
								></Carousel>
							</ToolsofEngagement>
						</Grid>
					) : (
						<Styles.HeadroomPadding />
					)}
				</Styles.ContentWrapper>

				<Headroom
					id="headroom-footer"
					style={{ bottom: "0" }}
					disableInlineStyles
				>
					<Styles.HeadroomButtonsPadding>
						<Grid style={{ paddingTop: "0", paddingBottom: "0" }}>
							{prevURL && (
								<a href={prevURL}>
									<Button
										darklightborder={[
											"podcast",
											"picture",
											"video",
										].includes(mod.slug)}
										light={[
											"text",
											"verse_by_verse",
											"prayer",
										].includes(mod.slug)}
										style={{
											padding: "11px 20px 9px",
											fontSize: "14px",
										}}
									>
										Previous
									</Button>
								</a>
							)}
							<Styles.BaseHideWrapper>
								<Link
									to={{
										pathname: `/book/${encodeURI(
											book.title
										)}`,
										state: { fromBookItem: true },
									}}
								>
									<Button
										darklightborder={[
											"podcast",
											"picture",
											"video",
										].includes(mod.slug)}
										light={[
											"text",
											"verse_by_verse",
											"prayer",
										].includes(mod.slug)}
										style={{
											padding: "11px 20px 9px",
											fontSize: "14px",
										}}
									>
										Contents
									</Button>
								</Link>
							</Styles.BaseHideWrapper>
							{nextURL && (
								<Styles.BaseRightWrapper>
									<a href={nextURL}>
										<Button
											// lightnoborder={["podcast", "picture", "video"].includes(mod.slug)}
											dark={[
												"text",
												"verse_by_verse",
												"prayer",
											].includes(mod.slug)}
											style={{
												padding: "11px 20px 9px",
												fontSize: "14px",
											}}
										>
											Next
										</Button>
									</a>
								</Styles.BaseRightWrapper>
							)}
						</Grid>
					</Styles.HeadroomButtonsPadding>
				</Headroom>
				<div ref={footerRef} />
			</Styles.Wrapper>
		</React.Fragment>
	) : (
		""
	);
};
