const fp = [
  {
    question: "1. Purpose:",
    answer:
      "1.1. The purpose of the Financial Policy for Bible For Life Discipleship Trust (Bible for Life) is to establish guidelines and procedures for the management of financial resources in alignment with the charities mission, values, and regulatory requirements. ",
    answer2:
      "1.2. This policy aims to ensure transparency, accountability, and effective stewardship of funds to support the charitable objectives of Bible For Life.",
  },
  {
    question: "2. Accounting Policies:",
    answer:
      "2.1.	Financial Statements are prepared on an accruals and prepayments basis following the guidance for accounting for smaller charities issued by the Charities Commission.",
    answer2:
      "2.2. The Financial Statements are not subject to Independent Examination or audit due to the charity’s annual income being under £25,000.",
  },
  {
    question: "3. Funds Management:",
    answer:
      "3.1.	Unrestricted General Funds: These funds are available for use in accordance with the charitable objects of Bible For Life at the discretion of the Trustees.",
    answer2:
      "3.2.	Restricted Funds: Any funds received for specific purposes or projects will be managed and utilised in accordance with the donors' intentions and restrictions outlined at the time of donation.",
  },
  {
    question: "4. Budgeting and Financial Planning:",
    answer:
      "4.1.	Develop an annual budget that reflects the financial needs and objectives of Bible For Life, including income projections, expenses, and anticipated funding requirements for programs and activities.",
    answer2:
      "4.2.	Review and monitor budget performance regularly to ensure alignment with financial goals and Bible for Life’s priorities.",
    answer3:
      "4.3.	Engage Trustees and relevant stakeholders in the budgeting and planning process to foster transparency and accountability.",
  },
  {
    question: "5. Financial Controls and Procedures:",
    answer:
      "5.1.	Establish and maintain appropriate internal controls to safeguard assets, prevent fraud, and ensure the accuracy and reliability of financial records.",
    answer2:
      "5.2.	Segregate financial duties and responsibilities among staff and volunteers to minimise the risk of errors or irregularities.",
    answer3:
      "5.3.	Implement procedures for the authorisation and documentation of financial transactions, including expenditures, reimbursements, and revenue collection.",
  },
  {
    question: "6. Financial Reporting:",
    answer:
      "6.1.	Prepare regular financial reports, including income statements, balance sheets, and cash flow statements, to provide the Charity Commission with timely and accurate information on the financial performance and position of Bible For Life.",
    answer2:
      "6.2.	Distribute financial reports to the Charity Commission in a clear and understandable format, highlighting key trends, variances, and significant financial matters.",
    answer3:
      "6.3.	Trustees are required by the Charity Commission to include their reserves policy in their annual report or state that they do not have one.",
  },
  {
    question: "7.  Compliance and Governance:",
    answer:
      "7.1.	Ensure compliance with all relevant laws, regulations, and accounting standards governing the financial operations of charities, including reporting requirements to regulatory authorities.",
    answer2:
      "7.2.	Adhere to the highest standards of governance and ethical conduct in financial management and decision-making processes, guided by the principles of transparency, accountability, and integrity.",
  },
  {
    question: "8. Gift Aid:",
    answer:
      "8.1.	Gift Aid administration is overseen by the Stewardship Trust, following their guidelines and procedures for Gift Aid claims and compliance with HMRC regulations.",
  },
  {
    question: "9. Reserves:",
    answer:
      "9.1.	Bible For Life maintains a Reserves Policy to ensure financial stability, support operational needs, and mitigate risks.",
    answer2:
      "9.2.	The Chief Executive and the Board of Trustees establishes and periodically reviews a target level of reserves based on factors such as operational expenses, revenue volatility, and potential risks.",
    answer3:
      "9.3.	Unrestricted Reserves: Available for general operational needs and emergency expenditures, providing flexibility and stability in managing day-to-day operations.",
    answer4:
      "9.4.	Designated Reserves: Funds allocated for specific purposes or projects identified by the Board of Trustees, ensuring financial support for strategic initiatives, capital investments, or future expansions.",
    answer5:
      "9.5. The Chief Executive and the Board of Trustees oversees reserves management, ensuring adherence to established policies and guidelines while maintaining liquidity and maximising returns through prudent investment strategy.",
  },
  {
    question: "10. Conflict of Interest:",
    answer:
      "10.1. The Chief Executive, Trustees, staff, and volunteers of Bible For Life are expected to disclose any potential conflicts of interest that may arise in the context of financial management, decision-making, or engagement with external stakeholders.",
    answer2:
      "10.2. Any individual with a conflict of interest shall abstain from participating in discussions or decisions related to matters in which they have a personal or financial interest.",
    answer3:
      "10.3. Disclosure of conflicts of interest should be made promptly and transparently to the Chief Executive, who will assess the situation and take appropriate measures to ensure impartiality and integrity in financial transactions and governance processes.",
    answer4:
      "10.4. The Chief Executive, Trustees, staff, and volunteers are encouraged to uphold the highest standards of ethical conduct and prioritise the best interests of Bible For Life and its stakeholders in all financial matters and interactions.",
  },
  {
    question: "11. Fundraising and Donor Stewardship:",
    answer:
      "11.1. Implement effective fundraising strategies and practices to diversify revenue streams and support the long-term sustainability of Bible For Life's programs and initiatives.",
    answer2:
      "11.2. Cultivate strong relationships with donors and supporters through transparent communication, timely acknowledgment of contributions, and responsible stewardship of donated funds.",
  },
  {
    question: "12. Review and Revision:",
    answer:
      "12.1. This Financial Policy will be reviewed every two years to ensure its effectiveness, relevance, and compliance with evolving regulatory requirements and best practices in financial management.",
    answer2:
      "12.2. Amendments or updates to the policy will be made as necessary to address changing circumstances or needs of the charity.",
  },
  {
    question: "13. Acknowledgment:",
    answer:
      "13.1. By adopting this Financial Policy, Bible For Life reaffirms its commitment to responsible financial stewardship, integrity, and accountability in the pursuit of its charitable mission and objectives.",
    answer2:
      "13.2. All Trustees, staff members, and volunteers are expected to adhere to the principles and guidelines outlined in this policy.",
  },
];

export default fp;
