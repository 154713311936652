import breakpoint from "../breakpoint";
import styled from "styled-components";

export const MainWrapper = styled.main`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	column-gap: 15px;
	row-gap: 15px;
	max-width: 625px;

	${breakpoint("md")`
        grid-template-columns: repeat(12, 1fr); 
        max-width: 1250px;
    `}
`;
