import Button from "../components/Button";
import Grid from "../components/Grid";
import React from "react";
import breakpoint from "../breakpoint";
import styled from "styled-components";
import buttonLogo from "../assets/images/mountains-poly.png";
import { useGlobalState } from "../scripts/useGlobalState";

const Wrapper = styled.header`
	position: relative;
	min-height: 240px;
	width: 100%;
	box-sizing: border-box;
	padding: 48px 12px 21px 12px;

	/* background-image: url(${({ image }) => image}); */
	background: linear-gradient(
			180deg,
			rgba(17, 17, 18, 0) ${({isActive}) => isActive ? '100%' : '0%'},
			rgba(17, 17, 18, 0.7) 100%
		),
		url(${({ image }) => image}), #c4c4c4;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	
	${({ isActive }) =>
		isActive &&
		`
		transition: background 0.3s ease;
	`
	}


	transition: background 0.3s ease;
	${breakpoint("md")`
		min-height: 338px;
		padding: 38px 54px 53px 54px;
    `}
	${breakpoint("lg")`
		min-height: 613px;
		padding: 36px 36px 55px 105px;
    `}
`;

const TagWrapper = styled.div`
	margin: 0;
	display: none;
	opacity: ${({isActive}) => isActive ? '0' : '1'};
	transition: opacity 0.3s ease;
	${breakpoint("md")`
		display: none;
    `}

	${breakpoint("lg")`
	 	grid-column: 8 / 13;
		display: flex;
		justify-content: flex-end;
		flex-flow: row wrap !important;
		flex-shrink: 3;

    `}
`;

const Tag = styled.a`
	display: block;
	align-self: start;

	margin: 5px 5px;
	padding: 10px 20px;

	background: #ffffff;
	border-radius: 40px;

	font-family: "Cerebri Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	line-height: 12px;
	text-align: center;
	color: #000000;
	display: none;
	${({isActive}) => isActive && 'cursor: default;'}
	${breakpoint("lg")`
		display: none;
    `}

	${breakpoint("lg")`
		display: block;
    `}

	&:focus, &:hover, &:visited, &:link, &:active {
		text-decoration: none;
		color: #000000;
	}
`;

const ButtonWrapper = styled.div`
	grid-column: 1 / -1;
	justify-self: end;
	padding: 0;
	margin: 0;
	display: none;

	${breakpoint("lg")`
		display: block;
	`}
`;

// export const ImageWrapper = styled.img`
// 	grid-column: 1 / -1;

// 	width: 240px;
// 	height: 240px;
// 	border-radius: 13px;

// 	background-image: url(${({ image }) => image});
// 	background-repeat: no-repeat;
// 	background-size: cover;
// 	background-position: center;

// 	display: none;

// 	${breakpoint("md")`
// 		display: none;
//     `}

// 	${breakpoint("lg")`
// 		grid-column: 1 / 4;
// 		display: block;
//     `}
// `;

const TextWrapper = styled.div`
	grid-column: 1 / -1;
	padding: 0;
	margin: 0;
	opacity: ${({isActive}) => isActive ? '0' : '1'};
	transition: opacity 0.3s ease;
	${({isActive}) => isActive && 'cursor: default;'}
	${breakpoint("md")`
		grid-column: 1 / 6;
		margin-top: 38px;
		margin-bottom: 53px;
	`}

	${breakpoint("lg")`
		grid-column: 1 / 10;
		margin-top: 22px;
		margin-bottom: 64px;
	`}
`;

const CourseHero = ({ children, keywords, image, onClick, text }) => {
	const [isActive, setIsActive] = React.useState(false);
	const [imageLoading, setImageLoading] = useGlobalState('video');
	let imageFull = image.split('?auto=compress,')[0]

	React.useEffect(() => {setImageLoading(true)},[])
	return (
		<Wrapper onLoad={() => setImageLoading(false)} image={imageFull} isActive={isActive}>
			<Grid style={{padding: 0, }} noPadding>
				<TagWrapper isActive={isActive}>
					{keywords.map((keyword, index) => (
						<Tag
							href={!isActive && `/?q=${encodeURIComponent(keyword)}`}
							key={`keyword-${index}`}
							isActive={isActive}
						>
							{keyword}
						</Tag>
					))}
				</TagWrapper>
				<TextWrapper isActive={isActive}>{children}</TextWrapper>
				<ButtonWrapper style={{columnGap: '15px'}}>
					<Button
					 	onClick={() => setIsActive(!isActive)}
						style={{height: '65px', width: '65px', padding: '0px', marginRight: '15px'}}
						 >
						<img width={33} src={buttonLogo}/>
					</Button>
					{/* <Button
						onClick={onClick}
					>
						{text}
					</Button> */}
				</ButtonWrapper>
			</Grid>
		</Wrapper>
	);
};

export default CourseHero;
