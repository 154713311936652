const cp = [
  {
    question: "1. Aim:",
    answer:
      "1.1. The aim of the complaints policy for Bible for Life Discipleship Trust (Bible for Life) is to provide a transparent, fair, and accessible process for individuals to voice their concerns, feedback, or complaints regarding any aspect of our services, materials, or interactions.",
  },
  {
    question: "2. Definitions:",
    answer:
      "2.1.	Complaint: A formal expression of dissatisfaction or concern raised by an individual or group regarding any aspect of Bible for Life's activities, services, materials, or conduct.",
    answer2:
      "2.2. Complainant: Any individual or group who submits a complaint or expresses dissatisfaction with Bible for Life's activities.",
    answer3:
      "2.3. Respondent: The individual, team, or department within Bible for Life responsible for addressing and resolving the complaint.",
    answer4:
      "2.4. Third Party: Any person or organisation not directly involved in the complaint but may be affected by its outcome.",
  },
  {
    question: "3. Complaints:",
    answer:
      "3.1.	Bible for Life welcomes complaints as an opportunity to improve its services and materials.",
    answer2:
      "3.2.	Complaints may relate to but are not limited to website content, materials, interactions with staff, guest scholars, or any other aspect of Bible for Life's operations.",
    answer3:
      "3.3.	The formal complaints procedure is intended to ensure that all complaints are handled fairly, consistently, and wherever possible, resolved to the complaint’s stratification.",
  },
  {
    question: "4. Responsibilities:",
    answer:
      "4.1.	Bible for Life: It is the responsibility of Bible for Life to ensure that complaints are handled promptly, fairly, and impartially.",
    listTitle: "4.2. Bible for Life will:",
    listItems: [
      "Acknowledge the formal complaint in writing;",
      "Respond within 12 weeks;",
      "Deal responsibility and sensitively with the complaint and",
      "Take action where appropriate.",
    ],
    listTitle2: "4.3. The complainant is to:",
    listItems2: [
      "Bring their complaint, in writing, to the Chief Executives attention normally within 12 weeks of the issue arising;",
      "Raise concerns promptly and directly with Chief Executive;",
      "Explain the problem as clearly and as fully as possible, including any action taken to date; and",
      "Allow the Chief Executive a reasonable time to deal with the matter and recognise that some circumstances may go beyond Bible For Life’s control.",
    ],
    answer4:
      "4.4.	Trustees, Staff and Volunteers: All Trustees, staff and volunteers associated with Bible for Life must be familiar with the complaints policy and procedures, and they should provide necessary assistance to complainants.",
    answer5:
      "4.5.	If you wish to bring a complaint against the Chief Executive or a member of the Board then please do so via the Charity Commissions <a href='https://forms.charitycommission.gov.uk/raising-concerns/' target='_blank'>website</a>.",
    answer6:
      "4.6. If you wish to bring a complaint against the Charity Commission specifically, this can be done via their <a href='https://forms.charitycommission.gov.uk/raising-concerns/' target='_blank'>Complaints Form</a>.",
  },
  {
    question: "5. Confidentiality:",
    answer:
      "5.1.	All complaints will be handled with the utmost confidentiality. Information related to complaints will only be shared with individuals directly involved in the complaint resolution process. ",
    answer2:
      "5.2.	The privacy of those making complaints will be respected throughout the procedure.",
    answer3:
      "5.3.	Personal information will be protected in accordance with relevant data protection laws.",
    answer4:
      "5.4. In cases where the disclosed information suggests illegal activity, Bible for Life reserves the right to alert the appropriate law enforcement agencies and relevant authorities in accordance with legal obligations and safeguarding protocols.",
  },
  {
    question: "6. Complaints Procedure:",
    answer:
      "6.1.	Submission of Complaint: Complaints can be submitted via an online form available on the Bible for Life website. It should be emailed to: <a href='mailto:info@bibleforlife.co.uk'>info@bibleforlife.co.uk</a>.",
    answer2:
      "6.2.	Acknowledgment: Upon receipt of a complaint, Bible for Life will acknowledge receipt within 10 working days.",
    answer3:
      "6.3.	Investigation: The complaint will be thoroughly investigated by the Chief Executive or another team member within Bible for Life.",
    answer4:
      "6.4.	Resolution: Bible for Life will strive to resolve complaints promptly and inform the complainant of the outcome within 12 weeks.",
  },
  {
    question: "7. Appeals Process:",
    answer:
      "7.1. If the complainant is not satisfied with the resolution, they may request a review of the decision.",
    answer2:
      "7.2.	The complainant should submit a formal request for a review of the decision within a specified timeframe, usually within 10 working days from receiving the initial response.",
    answer3:
      "7.3.	An impartial party, such as the Chair of the Board or Trustee who has not previously been involved in the complaint, will be assigned to conduct the review.",
    answer4:
      "7.4.	The reviewer will thoroughly examine all aspects of the complaint, including any new evidence or information provided by the complainant.",
    answer5:
      "7.5.	The reviewer may seek clarification from both the complainant and the relevant parties involved in the initial resolution process.",
    answer6:
      "7.6.	After a comprehensive review, the reviewer will make a determination on the complaint and communicate the decision to the complainant within 12 weeks.",
    answer7:
      "7.7.	The decision reached through the review process will be considered final and binding, marking the conclusion of the complaint’s procedure.",
    answer8:
      "7.8. Throughout the review process, confidentiality and fairness will be maintained, and all parties involved will be treated with respect and impartiality.",
  },
  {
    question: "8. Feedback and Improvement:",
    answer:
      "8.1.	Feedback received through the complaints process will be used to identify areas for improvement within Bible for Life's operations, services, and materials.",
    answer2:
      "8.2. Regular reviews of complaints and feedback will inform our continuous improvement efforts.",
  },
  {
    question: "9. Review:",
    answer:
      "9.1.	This complaints policy will be reviewed every two years to ensure its effectiveness and relevance.",
    answer2:
      "9.2.	Any necessary updates or revisions will be made to reflect changes in legislation, best practices, or organisational procedures.",
  },
  {
    question: "10. Contact Information:",
    answer:
      "For submitting complaints or inquiries regarding the complaints policy, please contact Reverend Nick Crawley at:",
    answer2:
      "<a href='mailto:info@bibleforlife.co.uk'>info@bibleforlife.co.uk</a>",
    answer3:
      "Thank you for your commitment to helping us improve our services and materials at Bible for Life. Your feedback is invaluable to us.",
  },
];

export default cp;
