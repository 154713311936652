import Prismic from "prismic-javascript";
import formatToe from "../format/toolOfEngagement";

export default async (toolIDs) => {
	const api = await Prismic.api("https://bibleforlife.cdn.prismic.io/api/v2");

	const { results } = await api.query(
		Prismic.Predicates.any("document.id", toolIDs)
	);

	return results.map(formatToe);
};
