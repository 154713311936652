import * as Styled from "./styles";

import {
	ArtistName,
	VideoModuleDescription,
} from "../../../../components/text";

import Carousel from "../../../../components/Carousel";
import Grid from "../../../../components/Grid";
import Modal from "../../../../components/Modal";
import React from "react";
import ToolsofEngagement from "../../../../blocks/ToolofEngagement";
import fetchToe from "../../../../scripts/get/toolOfEngagement";
import { RichText } from "prismic-reactjs"; //prismic-dom";


const Picture = ({ mod }) => {
	const {items, primary} = mod.slices[0];

	return (
		<React.Fragment>
			<Styled.Wrapper>
				<Grid>
					<Styled.TextWrapper>
						<Styled.ModuleTitle>{mod.title}</Styled.ModuleTitle>
						<VideoModuleDescription>
							{mod.description}
						</VideoModuleDescription>
					</Styled.TextWrapper>
				</Grid>
			</Styled.Wrapper>
			<Styled.PictureGroup>
				{items.map(
					({
						artwork_title,
						artwork_description,
						artwork_author,
						artwork,
					}) => (
						<Grid>
							<Styled.ImageWrapper>
								<Styled.Picture
									style={{
										borderRadius: "6px",
									}}
									src={artwork.url}
									
								/>
							</Styled.ImageWrapper>
							<Styled.LeftSideWrapper>
								<Styled.ArtworkTitle>
									{artwork_title}
								</Styled.ArtworkTitle>

								<ArtistName>{artwork_author}</ArtistName>
							</Styled.LeftSideWrapper>

							<Styled.RightSideWrapper>
								<Styled.ArtworkDescription>
									{RichText.render(artwork_description)}
								</Styled.ArtworkDescription>
							</Styled.RightSideWrapper>
						</Grid>
					)
				)}
			</Styled.PictureGroup>
		</React.Fragment>
	);
};
export default Picture;
