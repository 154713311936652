import styled, { keyframes, css } from "styled-components";
// import { media } from "./media";
import breakpoint from "../../breakpoint";


export const animationLength = 500;

const SlideIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-50px);
    }
    
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const SlideOut = keyframes`
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(-50px);
    }
`;

const FadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const FadeOut = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
`;

export const Outer = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	z-index: 999;
	display: flex;
	justify-content: start;
	align-items: center;
	flex-direction: column;
	overflow: scroll;
	min-height: min-content;
	overflow: hidden;

	${({ hidden }) => hidden && `display: none`};
`;

export const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	animation-delay: 0.5s;
	${({ exiting }) =>
		exiting
			? css`
					animation: ${animationLength}ms ${FadeOut} forwards;
			  `
			: css`
					animation: ${animationLength}ms ${FadeIn} forwards;
			  `}
`;

export const ScrollWrapper = styled.div`
	/* margin: auto; */
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	max-height: 100%;
	/* overflow: auto; */
	padding: 84px 0;
	
`;

export const Wrapper = styled.div`
	position: relative;
	border-radius: 10px;
	
	padding: 0 40px;
	background-color:  #F4F1EE;
	box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
	/* usually 151 */

	/* margin-top: 84px;
	margin-left: auto;
	margin-right: auto; */
	width: calc(100% - 20px);
	max-width: 500px;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: center;
	animation-delay: 0.5s;
	${({ exiting }) =>
		exiting
			? css`
					animation: ${animationLength}ms ${SlideOut} forwards;
			  `
			: css`
					animation: ${animationLength}ms ${SlideIn} forwards;
			  `}
`;

export const Title = styled.div`
	width: 70%;
	font-family: 'Cerebri Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 30px;
	color: black;
	text-align: left;
	margin: 44px auto 30px;
	text-align: center;

	${breakpoint("md")`
		font-style: normal;
		font-weight: 400;
		font-size: 54px;
		line-height: 69px;

	`}
`;

export const CloseButton = styled.button`
	display: inline-block;
	background: url(${require("../../assets/icons/CloseDark.svg").default}) no-repeat 50% 50%;
	background-size: contain;
	background-color: transparent;
	border-radius: 100%;
	width: 26px;
	height: 26px;
	position: absolute;
	border: none;
	cursor: pointer;
	top: 20px;
	right: 20px;
`;

export const Paragraph = styled.p`
	margin: 0 auto 30px;
	font-family: 'Cerebri Sans';
	color: black;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	/* text-align: justify; */

	
	${breakpoint("md")`
		font-weight: 400;
		font-size: 18px;
		line-height: 26px;
	`}
	
`;

export const Form = styled.form`
	border: 2px solid black;
	border-radius: 30px;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	margin: 0px auto 37px;
	width: 100%;
	
	display: flex;
	/* justify-content: center; */
	align-items: center;

`;

export const Email = styled.input.attrs({
	type: "email",
	name: "EMAIL",
	placeholder: "Enter Your Email Address"
})`
	text-align: left;
	appearance: none;
	border: none;
	background-color: transparent;
	outline: none;
	font-family: 'Cerebri Sans';
	width: 100%;
	display: block;

	margin: 10px;
	margin-left: 20px;

	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;

	${breakpoint("md")`
		font-weight: 400;
		font-size: 18px;
		line-height: 25px;
	`}
`;

export const Submit = styled.input.attrs({
	type: "submit",
	value: ""
})`
	background-image: url(${require("../../assets/icons/Next-Selected.svg").default});
	background-size: contain;
	background-color: transparent;
	background-repeat: no-repeat;
	width: 30px;
	height: 30px;
	margin: 2px;
	appearance: none;
	border: none;
`;

export const IconWrapper = styled.div`
	margin: 0 auto 39px;
`;

export const Logo = styled.div`
	background-image: url(${require("../../assets/icons/bfl-reverse-Logo.svg").default});
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 43px;
	height: 43px;
`;

export const SuccessIcon = styled(Logo)`
	/* background-image: url(/assets/tick.png); */
`;

export const StatusText = styled.p`
	font-family: 'Cerebri Sans';
	font-size: 20px;
	margin-bottom: 10px;
	color: inherit;

	a {
		color: inherit;
	}
`;

export const StatusWrapper = styled.div`
	margin: 0 40px;
`;
