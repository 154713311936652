import styled from "styled-components";
import breakpoint from "../../../breakpoint";
import {TeamFullName, TeamRole} from "../../../components/text";

import { Link } from 'react-router-dom';

export const Photo = styled.div`
    display: block;
    grid-area: photo;
    padding: 0;
    margin: 0;
	background-image: url(${({ image }) => image});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 281px;
    height: 186px;
    border-radius: 5.61563px 5.61563px 0px 0px;

    ${breakpoint("md")`
        border-radius: 13px 13px 0px 0px;
        width: 300px;
        height: 300px;
    `};
     
    ${breakpoint("lg")`
        border-radius: 13px 13px 0px 0px;
        width: 300px;
        height: 300px;   
    `};

`;


export const DesktopLink = styled.a`
    text-decoration: none;
    display: none;
    grid-area: bioLink;
    visibility: hidden;

    font-family: 'Cerebri Sans';
    font-size: 18px;
    line-height: 18px;

    color: #F4F1EE;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(244, 241, 238, 0.1);
    width: 100%;
    padding: 0 20px;

    &:focus, &:hover, &:visited, &:link, &:active {
        color: #F4F1EE;
    }

    ${({ hide }) =>
		hide &&
		`
		display: none;
	`}

    ${breakpoint("md")`
        display: block;
        padding: 0 30px;
        ${({ hide }) =>
		hide &&
		`
            display: none;
        `}
    `};
    ${breakpoint("lg")`
        display: block;
        padding: 0 30px;
        ${({ hide }) =>
		hide &&
		`
		    display: none;
	    `}
    `};

    

`;

export const MobileLink = styled.a`
    font-family: 'Cerebri Sans';
    font-size: 12px;
    line-height: 9px;

    grid-area: link;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(244, 241, 238, 0.1);
    padding: 0 31px;
    text-align: right;
    width: 100%;
    color: #111112;
    &:focus, &:hover, &:visited, &:link, &:active {
        /* text-decoration: none; */
        color: #111112;
    }

    ${breakpoint("md")`
        display: none;
    `};
    ${breakpoint("lg")`
        display: none;
    `};

    ${({ hide }) =>
		hide &&
		`
		display: none;
	`}

`;


export const Bio = styled.div`
    grid-area: bio;
    display: none;
    background: linear-gradient(126.22deg, #D79D6D 9.67%, #D35B49 95.25%);
    padding: 15px 20px;
    border-radius: 5.61563px 5.61563px 0px 0px;
    width: 281px;
    height: 186px;
    max-height: 186px;
    
  text-overflow: ellipsis;
    
    color: #F4F1EE;


    ${breakpoint("md")`
        padding: 35px 30px;
        width: 300px;
        height: 300px;
        max-height: 300px;
        border-radius: 13px 13px 0px 0px;
    `};
    ${breakpoint("lg")`
        padding: 35px 30px;  x
        width: 300px;
        height: 300px;
        
        max-height: 300px;
        border-radius: 13px 13px 0px 0px;
    `};

`;

export const Role = styled(TeamRole)`
    grid-area: role;
    padding: 15px 21px 0 21px;
    ${breakpoint("md")`
    padding: 38px 22px 0 39px;

    `};
    ${breakpoint("lg")`
    padding: 38px 22px 0 39px;

    `};

`;

export const Title = styled(TeamFullName)`
    grid-area: name;
    padding: 7px 21px 0 21px;
    ${breakpoint("md")`
    padding: 8px 22px 0 39px;

    `};
    ${breakpoint("lg")`
    padding: 8px 22px 0 39px;

    `};

`; 


export const Wrapper = styled.div`
	box-sizing: border-box;
	border-radius: 13px;
	background-color: #FFFFFF;
	color: #111112;

	max-width: 300px;
	width: 281px;
    height: 296px;

    display: grid;
    grid-template-areas:
        "photo"
        "bio"
        "bioLink"
        "role"
        "name"
        "link";
    
    padding: 0;
    margin: 0;
    grid-template-rows: 186px 0 0 42px 38px 14px;

    ${breakpoint("md")`
        width: 300px;
        height: 460.5px;
        grid-template-rows: 300px 0 0 80px 43px 0px;
    `};
     
    ${breakpoint("lg")`
        width: 300px;
        height: 460.5px; 
        grid-template-rows: 300px 0 0 80px 43px 0px;
    `};

    &:hover{
        grid-template-rows:  0 186px 0 42px 38px 14px;
        ${Photo}{
            display: none;
        }
        ${Bio}{
            display: block;
        }

        ${breakpoint("md")`
            grid-template-rows: 0 249px 51px 80px 43px 0px;
            ${Photo}{
                display: none;
            }
            ${Bio}{
                display: block;
            }
            ${DesktopLink}{
                visibility: visible;
            }
        
        `};
        
        ${breakpoint("lg")`
            grid-template-rows: 0 249px 51px 80px 43px 0px;
            ${Photo}{
                display: none;
            }
            ${Bio}{
                display: block;
            }
            ${DesktopLink}{
                visibility: visible;
            }
        `};
    }

`;