import { BookParagraph, BookSubtitle, BookTitle } from "../components/text";

import Grid from "../components/Grid";
import React from "react";
import breakpoint from "../breakpoint";
import styled from "styled-components";

const HeaderWrapper = styled.div`
	width: 100%;
	padding-top: 15px;

	${({ light }) =>
		light &&
		`
		${Title}{
            color: #111112;
        }

        ${Underline}{
            border-bottom: 1px solid #111112;
        }
	`}
`;

const Title = styled.h6`
	margin: 0;
	padding: 0;
	grid-column: 1/5;
	align-self: end;

	font-family: "Cerebri Sans";
	font-size: 18px;
	line-height: 21px;

	color: #f4f1ee;

	${breakpoint("md")`
    grid-column: 1/5;
    font-size: 24px;
    line-height: 28px;
`};

	${breakpoint("lg")`
    grid-column: 1/10;
    font-size: 24px;
    line-height: 28px;
`};
`;

const Underline = styled.span`
	grid-column: 1 / -1;
	border-bottom: 1px solid #f4f1ee;
	display: block;
`;

const Wrapper = styled.div`
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	margin-bottom: 146px;
	grid-column: 1/-1;
	${breakpoint("md")`	
		margin-bottom: 157px;
    `}
	${breakpoint("lg")`
		margin-bottom: 157px;
    `}
`;

const CaruselWrapper = styled.div`
	margin-top: 27px;
	/* margin-left: 18px; */
	${breakpoint("md")`	
		margin-top: 56px;
    `}
	${breakpoint("lg")`
		margin-top: 54px;
    `}
`;

const ToolsofEngagement = ({ children, light, notitle, style }) => {
	return (
		<Wrapper style={style}>
			<HeaderWrapper light={light} style={{display: notitle ? 'none':'block'}}>
				<Grid toe>
					 <Title>Tools of engagement</Title>
					<Underline />
				</Grid>
			</HeaderWrapper>
			<CaruselWrapper>{children}</CaruselWrapper>
		</Wrapper>
	);
};

export default ToolsofEngagement;
