import styled from "styled-components";

export const Wrapper = styled.div`
	position: absolute;
	top: 50px;
	z-index: 100;
	max-width: 530px;
	width: calc(100% - 36px);

	background: rgba(255, 255, 255, 0.8);
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.191898);
	backdrop-filter: blur(54.3656px);
	border-radius: 8px;
	overflow: hidden;

	/* margin-top: 570px; */
`;

export const Option = styled.div`
	font-family: "Cerebri Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 22px;

	color: #62636b;

	cursor: pointer;
	z-index: 99;
	border-bottom: 1px solid #62636b40;
	padding: 25px 50px;
	box-sizing: border-box;

	&:hover {
		background-color: #ffffff70;
		color: #252525;
	}

	${({ selected }) =>
		selected &&
		`font-weight: bold; background-color: #ffffff70; color: #252525;`}
`;

export const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: #00000040;
`;
