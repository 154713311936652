import { CardLine, CardTitle, ListItem, Wrapper } from "./styles";

import CardImage from "./CardImage";
import { Link } from "react-router-dom";
import OverlayVideo from "./OverlayVideo";
import React from "react";
import whatYouNeedToKnowVideo from "../../../../assets/videos/what_you_need_you_know.mp4";
import theHearOfItVideo from "../../../../assets/videos/the_heart_of_it.mp4";
import livingItVideo from "../../../../assets/videos/living_it.mp4";
import kingdomDiscipleshipVideo from "../../../../assets/videos/kingdom_discipleship.mp4";

const overlayVideoURLs = [
  whatYouNeedToKnowVideo,
  theHearOfItVideo,
  livingItVideo,
  kingdomDiscipleshipVideo,
];

export default ({
  courseIndex,
  title,
  modules,
  activeModule,
  course,
  ...props
}) => {
  const [showOverlayVideo, setShowOverlayVideo] = React.useState(false);

  return (
    <Wrapper {...props}>
      {courseIndex !== undefined && (
        <OverlayVideo
          show={showOverlayVideo}
          src={overlayVideoURLs[courseIndex]}
          onHide={() => setShowOverlayVideo(false)}
        />
      )}

      <CardImage
        radius={150}
        courseIndex={courseIndex}
        whatYouNeedToKnow={courseIndex === 0}
        theHearOf={courseIndex === 1}
        livingIt={courseIndex === 2}
        makingDisciples={courseIndex === 3}
        onClick={() =>
          setShowOverlayVideo((showOverlayVideo) => !showOverlayVideo)
        }
      />
      <CardTitle>
        <h4>{title}</h4>
      </CardTitle>
      <CardLine />
      <ul>
        {modules
          .filter((mod) => !!mod)
          .map(({ id, title, reading_time }, modIndex) => (
            <ListItem active={id === activeModule} key={`module-${modIndex}`}>
              <h6>{title}</h6>
              <span>
                {reading_time} min
                {+reading_time > 1 ? "s" : ""}
              </span>
              <Link
                to={`${window.location.pathname}/${course.slug}/${encodeURI(
                  title
                )}`}
                style={{
                  color: "unset",
                  textDecoration: "unset",
                }}
              >
                <button>Get started</button>
              </Link>
            </ListItem>
          ))}
      </ul>
    </Wrapper>
  );
};
