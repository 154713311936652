import {
	Content,
	PageDescription,
	PageTitle,
	PageTitleWrapper,
	Wrapper,
} from "./styles";

import Grid from "../../../../components/Grid";
import React from "react";
import { RichText } from "prismic-reactjs";

const Prayer = ({ mod }) => {
	return (
		<Wrapper>
			<Grid>
				<PageTitleWrapper>
					<PageTitle>{mod.title}</PageTitle>
					<PageDescription>{mod.description}</PageDescription>
				</PageTitleWrapper>
				{mod.slices.map(({ primary }) => (
					<Content>{RichText.render(primary.module_content)}</Content>
				))}
			</Grid>
		</Wrapper>
	);
};

export default Prayer;
