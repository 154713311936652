import { TeamFullName, TeamRole } from "../../../components/text";

import { Link } from "react-router-dom";
import breakpoint from "../../../breakpoint";
import styled from "styled-components";

export const Description = styled.p`
	grid-area: description;
	padding: 0;
	margin: 0;

	font-family: "Cerebri Sans";
	font-style: normal;
	font-weight: normal;

	display: none;
	color: #f4f1ee;

	${breakpoint("md")`
        display: block;
        font-size: 14px;
        line-height: 18px;
    `};

	${breakpoint("lg")` 
        display: block;
        font-size: 14px;
        line-height: 18px;
    `};
`;

export const Title = styled.h6`
	grid-area: title;
	padding: 0;
	padding-bottom: 5px;
	margin: 0;

	font-family: "Cerebri Sans";
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;

	white-space: nowrap;
	overflow: hidden;
  	text-overflow: ellipsis;
	width: calc(185px - 30px);

	color: #f4f1ee;

	${({ email }) =>
		email &&
		`
		font-size: 14px;
        line-height: 18px;
		width: 203px;
		`
	}

	${breakpoint("md")`
        font-size: 22px;
        line-height: 26px;

		width: calc(393px - 40px);
    `};

	${breakpoint("lg")`
        font-size: 22px;
        line-height: 26px;
    `};
`;

export const Time = styled.p`
	grid-area: time;
	justify-self: end;
	padding: 0;
	margin: 0;

	font-family: "Cerebri Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;

	color: #e4e0dd;

	display: none;

	${breakpoint("md")`
        display: inline-block;
    `};

	${breakpoint("lg")`
        display: inline-block;
    `};
`;

export const Type = styled.p`
	grid-area: type;

	font-family: "Cerebri Sans";
	font-size: 12px;
	line-height: 14px;
	font-style: normal;
	font-weight: bold;
	text-transform: uppercase;

	color: #e4e0dd;
	padding-bottom: 4px;

	${breakpoint("md")`
        font-size: 14px;
        line-height: 16px;
    `};

	${breakpoint("lg")`
        font-size: 14px;
        line-height: 16px;
    `};
`;

export const Wrapper = styled.div`
	box-sizing: border-box;
	border-radius: 13px;
	background: linear-gradient(125.43deg, #ffad4a 11.44%, #e18e45 95.81%);
	color: #111112;

	max-width: 393px;
	width: 185px;
	height: 72px;

	display: grid;
	grid-template-areas:
		"type time"
		"title title"
		"description description";

	grid-template-columns: 185px 0;
	grid-template-rows: 20px 23px 0;

	padding: 16px 20px;

	${breakpoint(
		"md"
	)`width: 393px;height: 129px;grid-template-columns: 290px 63px;grid-template-rows: 22px 53px 22px;`}

	${({ email }) =>
		email &&
		`
		width: 233px;
		`
	}

	${breakpoint("lg")`
        width: 393px;
        height: 129px; 
        grid-template-columns: 290px 63px;
        grid-template-rows: 22px 53px 22px;
    `}

	${({ reading }) =>
		reading &&
		`
		//brown
		background: linear-gradient(127.49deg, #A73737 12.45%, #9C3333 100%);
	`}
	${({ hearing }) =>
		hearing &&
		`
		//blue
		background: linear-gradient(127.49deg, #4CB1FF 12.45%, #0082E5 100%);
	`}
	${({ worship }) =>
		worship &&
		`
		//orange
		background:  linear-gradient(127deg, #FF8008 12.45%, #FF9214 100%);
		//lilac
		// background: linear-gradient(127deg, #A3BFF7 12.45%, #DAE2F8 100%);
		// linear-gradient(127.49deg, #FF8008 12.45%, #FF9214 100%);
	`}
	${({ studying }) =>
		studying &&
		`
		//dark grey
		background: linear-gradient(127.49deg, #283048 12.45%, #3F485C 100%);
	`}
	${({ memorising }) =>
		memorising &&
		`
		//grey
		background:  linear-gradient(127.49deg, #76809A 12.45%, #8D96AD 100%);
	`}
	${({ meaning }) =>
		meaning &&
		`
		//grey
		background:  linear-gradient(127.49deg, #1D976C 12.45%, #93F9B9 100%);
	`}

	${({ emailseries }) => emailseries &&`
		//dark green
		background: linear-gradient(135.38deg, #1D976C 0%, #396248 100%);
	`}

	// Blue Green - 
	/* background: linear-gradient(127deg, #1CD8D2 12.45%, #93EDC7 100%); */
	// #58E3CD, 80%

	//more options
	/* Orange - background: linear-gradient(127deg, #FF8008 12.45%, #FF9214 100%); */
	/* #FF890E, 80% */

	

	&:hover{
		cursor:pointer;
	}

`;
