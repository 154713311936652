import "./nav.css";
import * as GrIcons from "react-icons/gr";
import React, { useState } from "react";
import Headroom from "react-headroom";
import breakpoint from "../breakpoint";
import styled from "styled-components";
import NewsletterModal from "./NewsletterModal";

const Wrapper = styled.nav`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 40px;
  /* height: 40px; */
  background-color: #000000;

  z-index: 5;
  padding: 0;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 40px;
  max-width: 1440px;
  background-color: #000000;

  display: flex;
  align-items: center;

  padding: 0;
  padding-left: 24px;
  padding-right: 13px;
  box-sizing: border-box;

  margin: 0 auto;

  ${breakpoint("lg")`
        padding: 0 32px;
		padding-right: 42px;
		height: 70px;
    `}
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: #f4f1ee;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: #f4f1ee;
  }
  cursor: pointer;
`;

const Logo = styled.img.attrs({
  src: require("../assets/icons/logo.svg").default,
})`
  width: 25px;
  display: block;
  margin-right: 32px;

  ${breakpoint("lg")`
        width: 40px;
    `};
`;

const MenuLogo = styled.img.attrs({
  src: require("../assets/icons/Mobile Menu icon.svg").default,
})`
  width: 25px;
`;

const Tagline = styled.h1`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 36px;

  color: white;

  margin: 0;
  margin-right: auto;

  ${breakpoint("xs", "lg")`
		display: none;
	`}

  ${breakpoint("lg")`
		font-size: 28px;
	`}
`;
const Item = styled.a`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;

  color: white;
  text-decoration: none !important;

  margin: 0;
  margin-left: 32px;
  cursor: pointer;

  &:hover {
    color: #a1a0a5;
    cursor: pointer;
  }
`;

const MobileItems = styled.section`
  margin-left: auto;
  ${breakpoint("lg")`
        display: none;
    `}
`;

const DesktopItems = styled.section`
  display: none;

  ${breakpoint("lg")`
        display: block;
    `}
`;

const Padding = styled.div`
  height: 40px;
  background-color: #000000;
  ${breakpoint("lg")`
        height: 70px;
    `}
`;

const navItems = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "About",
    link: "/about",
  },
];

const ListWrapper = styled.ul`
  margin: 0;
  padding: 0;
  height: 100%;
  list-style-type: none;
  padding: 65px 0 0 28px;

  ${breakpoint("md")`
        
      `};

  ${breakpoint("lg")`
        
      `};
`;

const ListItem = styled.a`
  margin: 0;
  padding: 0;
  text-decoration: none;

  font-family: "Cerebri Sans";
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;

  color: #f4f1ee;
  padding-bottom: 18px;
  cursor: pointer;

  &:hover {
    color: #a1a0a5;
    text-decoration: none;
    cursor: pointer;
  }
`;

const CloseSideBarIconWrapper = styled.div`
  top: 0;
  right: 0;
  position: relative;
`;

const CloseSideBarIcon = styled.img.attrs({
  src: require("../assets/icons/Subtract.svg").default,
})`
  width: 20px;
  position: absolute;
  top: 9px;
  right: 27px;
`;

const SideBar = styled.nav`
  background-color: #111112;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;
  //	position: fixed;
  //	top: 0;
  //	right: ;

  width: 230px;

  margin: 0 0 0 auto;

  ${breakpoint("md")`
        width: 310px;
      `};
`;

const SidebarWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1500;

  pointer-events: ${({ show }) => (show ? "all" : "none")};

  ${SideBar} {
    transform: translateX(${({ show }) => (show ? "0" : "100%")});
    transition: ${({ show }) => (show ? "350ms" : "850ms")};
  }
`;

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  z-index: 6;
`;

const Nav = () => {
  const [sidebar, setSideBar] = useState(false);
  const [openNewsletter, setOpenNewsletter] = useState(false);

  const showSidebar = () => setSideBar(!sidebar);
  const closeSidebar = () => setSideBar(false);

  const handleOpenNewsletter = () => {
    setOpenNewsletter(!openNewsletter);
  };

  return (
    <React.Fragment>
      <NewsletterModal
        openNewsletter={openNewsletter}
        stateChanger={setOpenNewsletter}
      />
      <SidebarWrapper show={sidebar}>
        <SideBar className={sidebar ? "nav-menu active" : "nav-menu"}>
          <CloseSideBarIconWrapper onClick={showSidebar}>
            <CloseSideBarIcon />
          </CloseSideBarIconWrapper>
          <ListWrapper onClick={showSidebar}>
            {navItems.map(({ title, link }, key) => (
              <ListItem key={key} href={link}>
                {title}
              </ListItem>
            ))}
            <ListItem href="/about/#donation">Donate</ListItem>
            <ListItem onClick={handleOpenNewsletter}>Subscribe</ListItem>
          </ListWrapper>
        </SideBar>
      </SidebarWrapper>
      <Headroom id="navigation" disableInlineStyles>
        <Wrapper>
          <ContentWrapper>
            {sidebar && <Overlay onClick={closeSidebar}></Overlay>}
            <StyledLink href="/">
              <Logo />
            </StyledLink>
            <Tagline>
              <StyledLink href="/">Bible for Life</StyledLink>
            </Tagline>

            <MobileItems>
              <Item onClick={showSidebar}>
                <MenuLogo />
              </Item>
            </MobileItems>

            <DesktopItems>
              {navItems.map(({ title, link }, key) => (
                <Item key={key} href={link}>
                  {title}
                </Item>
              ))}
              <Item href="/about/#donation">Donate</Item>
              <Item onClick={handleOpenNewsletter}>Subscribe</Item>
            </DesktopItems>
          </ContentWrapper>
        </Wrapper>
        <Padding />
      </Headroom>
    </React.Fragment>
  );
};

export default Nav;
