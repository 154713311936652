import { PopName, PopRole, PopAboutTitle, StoryParagraph } from "../components/text";

import Grid from "../components/Grid";
import React from "react";
import breakpoint from "../breakpoint";
import styled from "styled-components";

import * as IoIcons from "react-icons/io";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
    width: 300px;
    max-width: 300px;
    right: 0;
    margin: 0;
    padding: 17px 0;
    
    background: #F4F1EE;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.191898);
    border-radius: 12px;
    display: block;

    ${breakpoint("md")`
        padding: 37px 0;
        width:  531px;
        max-width: 531px;
    `};
     
    ${breakpoint("lg")`
       max-width: 531px;
       width:  531px;
       padding: 37px 0;
    `};

   

`;

const WebsiteWrapper = styled.div`
    grid-column: 1/ -1;  
    display: block;
    text-align: right;

    ${({ hide }) =>
		hide &&
		`
		display: none;
	`}
`;
const Website = styled.a`
	font-family: 'Cerebri Sans';
	font-size: 9px;
    line-height: 10px;

    color: #62636B;
	padding-bottom: 4px;
	border-bottom: 0.544256px solid rgba(98, 99, 107, 0.4);
    
	text-decoration: none;
	&:focus,
	&:hover,
	&:visited,
	&:link,
	&:active {
		text-decoration: none;
		color: #111112;
	}

    margin: 0 17px;

	${breakpoint("md")`
		font-size: 18px;
		line-height: 18px;
        margin: 0 27px;
	`};
	${breakpoint("lg")`
		font-size: 18px;
		line-height: 18px;
        margin: 0 27px;
	`};
`;

const ContributorText = styled.div`
    grid-column: 1/-1;
    margin: 0;
    padding: 15px 17px;

    ${breakpoint("md")`
        padding: 34px 27px;
    `};
     
    ${breakpoint("lg")`
        padding: 34px 27px;
    `};

`;

const Pic = styled.img`
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    width: 50px;
	height: 50px;
	

	background-image: url(${({ image }) => image});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	${breakpoint("md")`
		width: 62px;
		height: 62px;
    `};
     
    ${breakpoint("lg")`
		width: 62px;
		height: 62px;
    `};
    
    border-radius: 50%;
`;

const StyledLink =styled(Link)`
	text-decoration: none;
	position: absolute;
	top: 12px;
	right: 12px;

    ${breakpoint("md")`
        top: 22px;
	    right: 22px;
    `};
     
    ${breakpoint("lg")`
        top: 22px;
	    right: 22px;
    `};


	&:focus, &:hover, &:visited, &:link, &:active {
		text-decoration: none;
	}
`;

const Line = styled.div`
    padding: 0;
    margin: 0;
    margin-top: 8px;
    border-top: 0.544256px solid rgba(98, 99, 107, 0.5);

    ${breakpoint("md")`
        margin-top: 20px;
    `};
     
    ${breakpoint("lg")`
        margin-top: 20px;
    `};
`;

const CloseButton = styled.img.attrs({
	src: require("../assets/icons/Small Close.svg").default,
})`
    text-align: right;
    width: 14px;
    height: 14px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
    ${breakpoint("md")`
        width: 27px;
        height: 27px;
    `};
     
    ${breakpoint("lg")`
        width: 27px;
        height: 27px;
    `};
`;


const PopoverModal = ({image, name, role, bio, link, hide, onClose, children, style}) => {

	return (
		<Wrapper style={style}>
            <Grid>
                <StyledLink to="#" onClick={onClose}
                        >
                            <CloseButton />
                    </StyledLink>

                <Pic image={image} />
                <PopName style={{
                        gridColumn: "2 / -1",
                        gridRow: "1"
                    }}>{name}</PopName>
                <PopRole style={{
                        gridColumn: "2 / -1",
                        gridRow: "2"
                    }}>{role}</PopRole>
                
            </Grid>
            <Line />
            <Grid>
                <ContributorText>
                    <PopAboutTitle>About</PopAboutTitle>
                    <StoryParagraph>{bio}</StoryParagraph>

                </ContributorText>
                <WebsiteWrapper hide={hide}>
                    <Website href={link} target="_blank" >Visit website</Website>
                </WebsiteWrapper>
                
            </Grid>
        </Wrapper>
        

	);
};

export default PopoverModal;
