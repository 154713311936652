import "./index.css";

import {
	BookNavigationButtons,
	BottomWrapper,
	ButtonContainer,
	ControlsContainer,
	NextLogo,
	NextPreviousButton,
	PreviousLogo,
	StartButton,
	StartButtonDesktop,
} from "../styles";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { getWithExpiry, setWithExpiry } from "../../../scripts/expiringStorage";

import Card from "./Card";
import Controls from "./Controls";
import { Link } from "react-router-dom";
import React from "react";
import { Wrapper } from "./styles";

SwiperCore.use([Navigation]);

export const StudyMapCarousel = ({
	activeModule,
	courses,
	title,
	getStartedFunction,
	getStartedText,
	...props
}) => {
	const [smallScreenSize, setSmallScreenSize] = React.useState();
	const [slidesPerView, setSlidesPerView] = React.useState();
	const [currentCardIndex, setCurrentCardIndex] = React.useState();

	React.useEffect(() => {
		setSmallScreenSize(window.innerWidth < 700);
		window.addEventListener(
			"resize",
			() => {
				switch (Math.floor((window.innerWidth / 248) * 2)) {
					case 0:
						setSlidesPerView(1);
						break;
					case 1:
						setSlidesPerView(2);
						break;
					default:
						setSlidesPerView(3);
				}
				setSmallScreenSize(window.innerWidth < 700);
			},
			false
		);
	}, []);

	// added function to check what the initial course is from local memory when accessing from all devices.
	const handleInitialIndex = () => {
		let currentIndex = getWithExpiry(`index-${encodeURI(title)}`);
		return currentIndex == null ? 0 : parseInt(currentIndex);
	};

	const CardChangeRegister = (activeIndex) => {
		let id = document.getElementById("StudyMapCarouselWrapper");
		let w = window.innerWidth;
		let lastIndex = 3;
		if (w >= 945) {
			lastIndex = 2;
		}

		if (activeIndex == lastIndex && w >= 700) {
			id.style.margin = "0 25px 0 auto";
		} else if (w >= 700) {
			if (w < 1332) {
				id.style.margin = "0 25px";
			} else if (w >= 1332) {
				id.style.margin = "0 auto";
			}
		}
	};

	return (
		<React.Fragment>
			<Wrapper {...props}>
				<Swiper
					navigation={{
						prevEl: ".study-map-swiper-prev",
						nextEl: ".study-map-swiper-next",
						disabledClass: "disabled",
					}}
					slideToClickedSlide
					spaceBetween={15}
					// initial slide change here if necessary
					breakpoints={{
						320: {
							// initialSlide: 0,
							slidesPerView: 1,
						},
						700: {
							// initialSlide: 0,
							slidesPerView: 1,
						},
						891: {
							// initialSlide: 0,
							slidesPerView: 2,
						},
						1024: {
							// initialSlide: 0,
							slidesPerView: 2,
						},
						1332: {
							// initialSlide: 0,
							slidesPerView: 3,
						},
					}}
					initialSlide={handleInitialIndex()}
					cssMode={smallScreenSize}
					slidesPerView="auto"
					preventClicks={false}
					preventClicksPropagation={false}
					style={{
						overflow: "visible",
						display: "flex",
					}}
					// releaseOnEdges={true}
					// edgeSwipeDetection={true}
					// edgeSwipeThreshold={25}
					fromEdge={true}
					onSlideChange={({ activeIndex }) => {
						CardChangeRegister(activeIndex);
						setWithExpiry(
							`index-${encodeURI(title)}`,
							activeIndex,
							2592000000
						);
					}}
				>
					{courses.map(
						({ title, modules, ...course }, courseIndex) => (
							<SwiperSlide
								className={"study-map"}
								style={{ height: "auto" }}
								key={courseIndex}
								// nav
							>
								<Card
									key={`course-${courseIndex}`}
									{...{
										courseIndex,
										title,
										modules,
										activeModule,
										course,
									}}
								/>
							</SwiperSlide>
						)
					)}
				</Swiper>
				<ControlsContainer>
					<NextPreviousButton
						style={{ margin: "0px 5px" }}
						className="study-map-swiper-prev"
					>
						<PreviousLogo more />
					</NextPreviousButton>

					<NextPreviousButton next className="study-map-swiper-next">
						<NextLogo more />
					</NextPreviousButton>
					<StartButtonDesktop onClick={getStartedFunction}>
						{getStartedText}
					</StartButtonDesktop>
				</ControlsContainer>
			</Wrapper>
		</React.Fragment>
	);
};
