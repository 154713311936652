
import {ModuleTitle, ModuleDescription, BlockTitle, TeamFullName, StoryParagraph} from "../../../../components/text";
import breakpoint from "../../../../breakpoint";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";


export const Wrapper = styled.header`
	margin: 0;
	padding: 0;
	box-sizing: border-box;
    background-color: rgba(244, 241, 238, 1);
    margin-top: 32px;

    ${breakpoint("md")`
        margin-top: 52px;
	`}
    ${breakpoint("lg")`
        margin-top: 80px;
	`}
`;

export const PageTitleWrapper = styled.div`
    grid-column: 1 / -1;
	padding: 0;
	margin: 0;

	${breakpoint("lg")`
		grid-column: 1 / 8;
	`}
`;

export const PageTitle = styled(ModuleTitle)`
    color: #111112;
	margin-bottom: 18px;

	${breakpoint("md")`
		margin-bottom: 53px;
    `};
     
    ${breakpoint("lg")`
		margin-bottom: 53px;
    `};
`;

export const PageDescription = styled(ModuleDescription)`
    color: #111112;
    
	/* margin-bottom: 40px; */
    margin-bottom: 20px;

    ${breakpoint("md")`
		// margin-bottom: 87px;
        margin-bottom: -20px;
    `};
     
    ${breakpoint("lg")`
		// margin-bottom: 100px;
        margin-bottom: -60px;
    `};

`;

export const Underline  = styled.div`
    padding: 0px;
    margin-top: -10px;
    grid-column: 1 / -1;
    border-top: 1px solid #111112;

    ${breakpoint("lg")`
		 margin-top: -20px;
    `};


`;

export const PageSection = styled(BlockTitle)`
    grid-column: 1 / -1;
    padding: 0;
    padding-bottom: 16px;
    margin: 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    padding-top: 44px;
    display: block;

    ${breakpoint("md")`
        font-size: 54px;
        line-height: 63px;
        padding-top: 104px
    `};
     
    ${breakpoint("lg")`
        font-size: 54px;
        line-height: 63px;
        padding-top: 160px;
    `};
`;
export const Section = styled.div`
    grid-column: 1 / -1;
   
    padding: 0;
    padding-top: 20px;
    /* padding-bottom: 10px; */
    /* border-top: 1px solid rgba(17, 17, 18, 0.5); */
    margin: 0;
    ${breakpoint("md")`
		// padding-bottom: 60px;
        padding-top: 44px;
    `};
     
    ${breakpoint("lg")`
		// padding-bottom: 103px;
        padding-top: 51px;
    `};
`;

export const ChapterTitle = styled(TeamFullName)`
    grid-column: 1 / -1; 
    margin-bottom: 18px;
    font-weight: bold;
     
    ${breakpoint("lg")`
        font-size: 40px;
        line-height: 37px;  
        margin-bottom: 37px;
    `};

    ${breakpoint("lg")`
		grid-column: 1 / 5;
        font-size: 40px;
        line-height: 37px;  
    `};

`;

export const Content = styled(StoryParagraph)`
    grid-column: 1 / -1; 

    color: #62636B;

    h4{
        color: #111112;
        font-weight: bold;
        font-size: 13px;
        line-height: 15px;
        margin-bottom: 6px;
    }

    tr, td, th {
        border: 1px solid #555;
        padding: 7px;
        min-width: 200px;

    }

    table{
        overflow: auto;
        width: 100%;
        display: block;
        /* max-height: calc(100vh - 80px); */
        padding-bottom: 20px;
    }

    th{

    }

    ${breakpoint("md")`
        h4{
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 16px;
        }
    `};

    ${breakpoint("lg")`
		grid-column: 5 / -1;
        h4{
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 10px;
        }
    `};
`;