import { Paragraph } from "../../components/text";
import breakpoint from "../../breakpoint";
import styled from "styled-components";

export const Section = styled.section`
	margin-bottom: 25px;

	grid-column: 1 / -1;

	${breakpoint("md")`
		grid-column: 2 / -2;
		margin-bottom: 45px;
    `}

	${breakpoint("lg")`
        grid-column: 4 / 10;
		margin-bottom: 45px;
    `}
`;
export const ButtonWrapper = styled.div`
	margin: 0;
	padding: 0;
	display: none;
	${breakpoint("md")`
		display: none;
    `}

	${breakpoint("lg")`
        display: block;
    `}
`;

export const BottomWrapper = styled.div`
	background: linear-gradient(
		360deg,
		#e9e4e0 0%,
		#e9e4e0 15.56%,
		rgba(233, 228, 224, 0) 100%
	);
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100vw;

	height: 58px;

	${({ mobile }) =>
		mobile &&
		`
		display: block;
		`}

	${breakpoint("md")`
		height: 121px;
    `};

	${breakpoint("lg")`
		height: 272px;
		background: none;

		${({ mobile }) =>
		mobile &&
		`
		display: none;
		`}
    `};

	
`;

export const BookNavigationButtons = styled.div`
	position: ${({nofloat}) => nofloat ? 'static' : 'fixed'};
	
	left: 10px; 
	right: 10px; 
	bottom: 10px;
	z-index: 10;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	${({nofloat}) => nofloat ? 'left: 0;right:0;margin: 0 10px; padding-bottom: 80px' : 'left: 10px;right:10px;margin: 0 10px;'}; 
	${({testis}) =>
		testis && `
		position: relative;
		grid-column: 1 /-1;
		margin-top: 40px;
	`}


	${({ desktop }) =>
		desktop &&
		`
		display: none;
		grid-column: 1 /-1;
	`}

	${breakpoint("md")`
		left: 25px;
		right: 25px;
		bottom: 31px;
		${({nofloat}) => nofloat ? 'left: 0;right:0;margin: 0 25px;padding-bottom: 151px;' : 'left: 10px;right:10px;margin: 0 25px;'}; 

		${({testis}) =>
			testis && `
			margin-top: 60px;
		`}

    `};

	${breakpoint("lg")`
		display: none;
		${({nofloat}) => nofloat && 'left: 94px;right:48px;margin: 0 auto;padding-bottom: 272px;'}; 

		${({testis}) =>
			testis && `
			display: block;
			margin: 0 auto;
			display: grid;
			grid-template-columns: 111px 90px auto auto;
			justify-content: stretch;
			margin-top: 70px;
		`}

		${({ desktop }) =>
			desktop &&
			`
			display: grid;
			left: 94px;
			right: 48px;
			bottom: 182px;
			max-width: 1298px;
			margin: 0 auto;
			grid-template-columns: 111px 90px auto auto;
			justify-content: stretch;
			${StartButton}{
 				grid-column: -1;
 				justify-self: end;
 			}
		`}
    `};
`;

export const ControlsContainer = styled.div`
	display: none;
	z-index: 10;
	/*width: 100%;*/
	position: absolute;
	left: 94px;
	right: 48px;
	padding-top: 30px;
	max-width: 1298px;
	margin: 0 auto;
	grid-template-columns: 111px 90px auto auto;
	/*justify-content: stretch;*/
	${breakpoint("lg")`
		display: grid;
	`}
	
`


export const ControlsContainer1 = styled.div`
	display: none;
	position: absolute;
	width: 100%;
	left: 0px;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 15px; 
	padding: 0px 94px;
	${breakpoint("lg")`
		display: flex;
	`}
`

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
`

export const StartButton = styled.div`
	display: inline-block;
	text-align: center;
	position: relative;
	background: #000000;
	border-radius: 48px;
	font-family: "Cerebri Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 12px;
	color: #f4f1ee;
	padding: 18px 20px;
	width: 60%;

	cursor: pointer;

	${breakpoint("md")`
        font-size: 18px;
		line-height: 12px;
		padding: 26.5px 20px;
    `};
	${breakpoint("lg")`
		align-self: flex-end;
		width: auto;
        font-size: 18px;
		line-height: 12px;
		padding: 27px 60px;
    `};
`;

export const StartButtonDesktop = styled(StartButton)`
	grid-column: -1;
 	justify-self: end;
`

export const NextPreviousButton = styled.div`
	display: inline-block;
	width: 48px;
	height: 48px;
	margin: 0 10px 0 0;
	&.disabled {
		img {
			opacity: 0.15;
		}
	}

	background: #f4f1ee;
	border-radius: 48px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	/* background-image: url(${({ image }) => image});
	background-repeat: no-repeat;
	background-size: 30%;
	background-position: center; */

	position: relative;
	cursor: pointer;

	display: flex;
	justify-content: center;
	align-items: center;

	${({ next }) =>
		next &&
		`
		margin: 0 0 0 10px;
	`}

	${breakpoint("md")`
		margin: 0;
		width: 90px;
		height: 90px;
		${({ next }) =>
			next &&
			`
			margin: 0;
		`}
    `};
`;

export const PreviousLogo = styled.img.attrs({
	src: require("../../assets/icons/Previous-Selected.svg").default,
})`
	position: absolute;
	width: 12px;
	height: 28px;
	display: block;
	opacity: 0.15;

	&:hover,
	:active {
		opacity: 1;
	}

	${breakpoint("md")`
		width: 12px;
		height: 28px;
    `};

	${breakpoint("lg")`
        width: 12px;
		height: 28px;
    `};

	${({ more }) =>
		more &&
		`
		opacity: 1;
	`}
`;

export const NextLogo = styled.img.attrs({
	src: require("../../assets/icons/Next-Selected.svg").default,
})`
	position: absolute;
	width: 12px;
	height: 28px;
	display: block;
	opacity: 0.15;

	&:hover,
	:active {
		opacity: 1;
	}

	${breakpoint("md")`
		width: 12px;
		height: 28px;
    `};

	${breakpoint("lg")`
        width: 12px;
		height: 28px;
    `};

	${({ more }) =>
		more &&
		`
		opacity: 1;
	`}
`;

export const List = styled.ol`
	list-style-type: none;
	margin: 0;
	padding: 0;
`;

export const ListNumber = styled(Paragraph)`
	width: 78px;
	align-self: flex-start;

	display: none;
	margin: 0;
	${breakpoint("md")`
      display: inline-block;  
    `};

	${breakpoint("lg")`
      display: inline-block;
    `};
`;

export const ListMin = styled(Paragraph)`
	display: none;
	width: 134px;
	padding: 0;
	padding-right: 20px;
	margin: 0;

	text-align: right;
	align-self: flex-end;

	${breakpoint("md")`
      display: inline-block;  
    `};

	${breakpoint("lg")`
      display: inline-block;
    `};
`;

export const ListParagraph = styled.div`
	align-self: flex-start;
	width: 250px;

	a {
		text-decoration: none;
	}

	a p {
		transition: 0.23s opacity;
		opacity: 0.6;
		color: #111112;
	}
	a:hover p {
		opacity: 1;
	}

	${breakpoint("md")`
		width:396px;
    `};

	${breakpoint("lg")`
		width: 396px;
    `};
`;

export const ListItem = styled.li`
	border-bottom: 1px solid rgba(98, 99, 107, 0.2);
	padding: 15px 0;
	padding-left: 25px;

	display: flex;

	${breakpoint("md")`
		padding-left: 50px;
    `};

	${breakpoint("lg")`
		padding-left: 50px;
    `};
`;

export const BackgroundImage = styled.div`
	background-image: url(${require("../../assets/images/contents-bg.jpg").default});
	background-size: 100% auto;
`;

export const Wrapper = styled.div`
	background: rgba(244, 241, 238, 0.8);
	backdrop-filter: blur(50px);
	box-sizing: border-box;
`;

export const ContributorSection = styled.section`
	grid-column: 1/ -1;
	text-align: right;
	margin: 0;
	padding: 0;
	margin-bottom: 15px;

	position: absolute;
	top: 15px;
	right: 20px;

	display: flex;
	justify-content: flex-end;

	z-index: 1000;

	${breakpoint("md")`
		position: relative;
		top: 0;
		right: 0;
		grid-column: 3 / -1;
		align-self: start;
		display: flex;
		margin-bottom: 11px;

    `};

	${breakpoint("lg")`
		position: relative;
		top: 0;
		right: 0;
		grid-column: 6 / -1;
		align-self: start;
		display: flex;
		margin-bottom: 11px;

    `};
`;
export const BlockTitleWrapper = styled.div`
	grid-column: 1 / -1;
	display: block;

	margin-bottom: 5px;
	visibility: hidden;

	${ContributorSection} {
		visibility: visible;
	}

	${breakpoint("md")`
		margin-top: 57px;
		margin-bottom: 100px;
		visibility: visible;
	`}

	${breakpoint("lg")`
		visibility: visible;
		margin-top: 69px;
		margin-bottom: 100px;
		max-width: 1247px;
	`}
`;

export const Line = styled.div`
	grid-column: 1 / -1;
	border-bottom: 1px solid #111112;
	display: block;
	
	${breakpoint("md")`
		grid-column: 1 / -1;
	`}
`;

export const ContributorPic = styled.div`
	width: 18px;
	height: 18px;
	border-radius: 50px;

	margin-left: 2.5px;

	background-image: url(${({ image }) => image});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	${breakpoint("md")` 
		width: 36px;
		height: 36px;
		margin-left: 5px;
    `};

	${breakpoint("lg")`
		width: 36px;
		height: 36px;
		margin-left: 5px;
    `};
`;

export const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	margin: 0;
	padding: 0;

	display: flex;
	justify-content: center;
	align-items: flex-start;
	z-index: 15;

	${breakpoint("md")`
        align-items: center;
        
    `};

	${breakpoint("lg")`
        align-items: center;
    `};
`;

export const Card = styled.div`
	background-color: #f4f1ee;
	border: 1px solid #fffef2;
	border-radius: 21px;
	padding: 17px 19px;
	box-sizing: border-box;
	width: 233px;
	height: 310px;

	${breakpoint("md")`
		width: 422px;
		height: 673px;

		padding: 70px 31px 96px;
	`}

	h4 {
		font-size: 18px;
		line-height: 23px;
		border-bottom: 1px solid #11111280;
		margin: 6px 0;
		padding: 12px 0;
		vertical-align: middle;

		${breakpoint("md")`
		height: calc(72px + 25px);
			text-align: center;
			font-size: 28px;
			line-height: 36px;
		padding-bottom: 25px;
		`}
	}

	ul {
		padding: 0;
		list-style-type: none;
	}

	li {
		padding: 12px 4px;
		border-bottom: 1px solid #62636b40;
		font-size: 10px;
		line-height: 12px;
		position: relative;

		button {
			display: none;
			background: #ffffff;
			border-radius: 40px;
			padding: 8px 14px 6px;
			box-sizing: border-box;
			appearance: none;
			border: none;
			font-size: 11px;
			line-height: 12px;
			margin-left: auto;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 8px;
		}

		&:hover {
			background-color: #e4e0dd;
			border-radius: 3px;

			button {
				display: block;
			}
		}

		${breakpoint("md")`
			padding: 17px 21px;
			font-size: 16px;
line-height: 20px;
		`}
	}
`;

export const CardImage = styled.div`
	display: none;

	${breakpoint("md")`
		display: block;
		margin: 0 auto 40px;
		width: 100px;
		height: 100px;
		border-radius: 100px;
		background-color: white;

		// background-image: ${({ src }) => src};
	`}
`;
