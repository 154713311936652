import breakpoint from "../../../breakpoint";
import styled from "styled-components";

export const Wrapper = styled.div`
	box-sizing: border-box;
	margin: 0;

	${breakpoint("lg")`
		margin: 0 0 30px 0;
	`}
`;
