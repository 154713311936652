import Button from "./Button";
import Grid from "./Grid";
import React, { useState } from "react";
import breakpoint from "../breakpoint";
import styled from "styled-components";
import NewsletterModal from "./NewsletterModal";

const Wrapper = styled.footer`
  background-color: #111112;
  padding: 50px 0;
  box-sizing: border-box;
`;

const Logo = styled.div`
  background-image: url(${require("../assets/icons/logo.svg").default});
  background-position: center;
  background-size: 48px 48px;
  background-repeat: no-repeat;
  height: 48px;
  grid-column: 1 / -1;
  margin-bottom: 75px;

  ${breakpoint("md")`
      background-size: 46.4px 46.4px;
           height: 46.4px;
    `};

  ${breakpoint("lg")`
	background-size: 40px 40px;
           height: 40px;
    `};
`;

const SourceWrapper = styled.ul`
  list-style-type: none;
  padding: 0;
  grid-column: 1 / -1;

  ${breakpoint("md")`
		grid-column: span 2;
    `}

  ${breakpoint("lg")`
		grid-column: span 2;
    `}
`;

const Source = styled.li`
  ${({ titleType }) => titleType && `text-transform: uppercase`};

  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: white;
  margin-bottom: 18px;
`;

const Copyright = styled.p`
  grid-column: 1 / -1;
  font-family: "Cerebri Sans";
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 32px;

  grid-column: 1 / -1;

  ${breakpoint("md")`
		grid-column: 1 / 4;
    `}

  ${breakpoint("lg")`
		grid-column: 1 / 6;
    `}

	color: #f4f1ee;
`;
const SocialMedia = styled.div`
  width: 40px;
  height: 40px;

  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  margin-left: 0;
  ${breakpoint("lg")`
		 margin-right: 0;
	 `}
`;

const Item = styled.a`
  color: white;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ButtonWrapper = styled.div`
  grid-column: 1 / -1;
  grid-row: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  ${breakpoint("md")`
		grid-column: 5/-1;
		align-items: flex-end;
		justify-content: flex-start;
		flex-direction: column;
		
    `}
  ${breakpoint("lg")`
		grid-column: 10 / 13;
		align-items: flex-end;
		justify-content: flex-start;
		flex-direction: column;
		
    `}
`;

const SocialMediaWrapper = styled.div`
  grid-column: 1 / -1;
  text-align: center;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-around;
  ${breakpoint("md")`
		grid-column: 5/-1;
	
	`}

  ${breakpoint("lg")`
		grid-column: 11 / -1;

	`}
`;

const references = [
  {
    title: "Privacy Statement",
    link: "/nutsandbolts/#privacy-statement",
  },
  {
    title: "Copyright",
    link: "/nutsandbolts/#copyright",
  },
  { title: "FAQs", link: "nutsandbolts" },
  {
    title: "4 Policies and Procedures",
    link: "/policiesandprocedures",
  },
  {
    title: "Annual Report and Accounts",
    link: "https://bibleforlife.cdn.prismic.io/bibleforlife/Zs8ug0aF0TcGJeaO_ANNUALBLFTrusteesReportandFinancialStatements31stMarch2024-final--approved.pdf",
  },
];

const Footer = () => {
  const [openNewsletter, setOpenNewsletter] = useState(false);

  const handleOpenNewsletter = () => {
    setOpenNewsletter(true);
  };

  return (
    <React.Fragment>
      <NewsletterModal
        openNewsletter={openNewsletter}
        stateChanger={setOpenNewsletter}
      />
      <Wrapper>
        <Grid>
          <Logo />
          <SourceWrapper>
            <Source titleType>References</Source>

            {references.map(({ title, link }, key) => (
              <Source key={key}>
                <Item href={link}>{title}</Item>
              </Source>
            ))}
          </SourceWrapper>
          <SourceWrapper>
            <Source titleType>Contact us</Source>

            <Source>
              <Item href="mailto:info@bibleforlife.co.uk">
                info@bibleforlife.co.uk
              </Item>
            </Source>
          </SourceWrapper>
          <ButtonWrapper>
            <a href="/#search">
              <Button footer>Books</Button>
            </a>
            <a href="/about">
              <Button footer>About</Button>
            </a>
            <a href="/about/#donation">
              <Button footer>Donate</Button>
            </a>
            <Button footer onClick={handleOpenNewsletter}>
              Subscribe
            </Button>
          </ButtonWrapper>
          <Copyright style={{ marginBottom: 0 }}>
            The Bible for Life Discipleship Trust' is a registered charity, in
            England and Wales (1197943); A Charitable Incorporated Organisation.
            Registered address: 27, Carnarvon Road, Bristol BS6 7DU
          </Copyright>
          <Copyright>
            Unless indicated, all Scriptures are taken from the Holy Bible, New
            International Version ®, NIV ® Copyright © 1973, 1978, 1984, 2011 by
            Biblica, Inc.Used with permission. All rights reserved worldwide.
          </Copyright>
          <SocialMediaWrapper>
            <Item
              href="https://www.instagram.com/bible.for.life/"
              target="_blank"
            >
              <SocialMedia
                image={require("../assets/icons/Instagram.svg").default}
              />
            </Item>
            <Item
              href="https://www.facebook.com/bibleforlife.co.uk"
              target="_blank"
            >
              <SocialMedia
                image={require("../assets/icons/Facebook.svg").default}
              />
            </Item>
            <Item href="https://twitter.com/BibleforLife1" target="_blank">
              <SocialMedia
                image={require("../assets/icons/Twitter.svg").default}
              />
            </Item>
          </SocialMediaWrapper>
        </Grid>
      </Wrapper>
    </React.Fragment>
  );
};

export default Footer;
