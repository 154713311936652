import Prismic from "prismic-javascript";
import formatBook from "../format/book";
import formatContributor from "../format/contributor";
import formatModule from "../format/module";

export default async (title) => {
	const api = await Prismic.api("https://bibleforlife.cdn.prismic.io/api/v2");
	const { results } = await api.query(
		Prismic.Predicates.at("my.book.title", decodeURI(title))
	);

	const book = formatBook(results[0]);

	if (
		book.contributors &&
		book.contributors.length &&
		book.contributors[0].hasOwnProperty("id")
	) {
		// console.log("contributors", book.contributors);

		// contributors
		const { results: contributors } = await api.query(
			// Get all the documents that have any of these IDs
			Prismic.Predicates.any(
				"document.id",
				book.contributors.map(
					(cont) =>
						cont &&
						cont.contributor &&
						cont.contributor.id &&
						cont.contributor.id // e.g. ["SDFKLJSEDF", "7878SDFSDF", "SDFJSDFK"]
				)
			)
		);

		book.contributors = contributors.map(formatContributor);
	}

	// courses = book.courses
	let { courses } = book,
		index = 0;

	// Only get modules that are filled
	courses = courses.map(({ modules, ...props }) => ({
		...props,
		modules: modules.filter(({ id }) => typeof id !== "undefined"),
	}));

	for (const { modules } of courses) {
		// For each course

		const { results } = await api.query(
			// Get all the documents that have any of these IDs
			Prismic.Predicates.any(
				"document.id",
				modules.map(({ id }) => id) // e.g. ["SDFKLJSEDF", "7878SDFSDF", "SDFJSDFK"]
			)
		);

		book.courses[index++].modules = modules
			.map(({ id }) => results.find((result) => result.id === id)) // ordering
			.filter(
				({ data }) =>
					!!data.hasOwnProperty("body") &&
					!!data.body.hasOwnProperty("length")
			)
			.map(formatModule)
			.filter((mod) => !!mod);
	}

	return book;
};
