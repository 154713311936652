import Footer from "../components/Footer";
import "../pages/Book/custom.css";

import * as Styles from "./Book/styles";
import sofb from "../data/standardofbehaviour";
import smandwp from "../data/socialmediaandwebsitepolicy";
import fp from "../data/financialpolicy";
import cp from "../data/complaintspolicy";

import Grid from "../components/Grid";
import React from "react";

import {
  Wrapper,
  PageTitleWrapper,
  PageTitle,
  ChapterTitle,
  Section,
  Content,
} from "./Book/modules/Text/styles";

import {
  SliceSummary,
  DownloadDocIcon,
} from "./Book/modules/VerseByVerse/styles";

const references = [
  {
    title: "Complaints Form",
    link: "https://bibleforlife.cdn.prismic.io/bibleforlife/ZtBXeUaF0TcGJgl2_BibleForLifeComplaintsForm.docx",
  },
];

function renderHtmlContent(content) {
  const elements = [];
  let currentElement = null;

  // Regular expression to match HTML tags and text content
  const tagRegex = /(<\/?[^>]+>)|([^<>]+)/g;
  let match;
  while ((match = tagRegex.exec(content)) !== null) {
    const [fullMatch, tag, text] = match;

    if (tag) {
      if (tag.startsWith("<a ")) {
        // Extract href and target from the <a> tag
        const hrefMatch = tag.match(/href=['"]([^'"]+)['"]/);
        const targetMatch = tag.match(/target=['"]([^'"]+)['"]/);
        const href = hrefMatch ? hrefMatch[1] : "#";
        const target = targetMatch ? targetMatch[1] : "_self";

        // Create a new <a> element and store it in currentElement
        currentElement = (
          <a key={elements.length} href={href} target={target}>
            {/* The text will be added later */}
          </a>
        );
      } else if (tag.startsWith("</a>")) {
        // Push the completed <a> element to elements
        elements.push(currentElement);
        currentElement = null;
      } else {
        // Handle other tags or unknown tags
        elements.push(<span key={elements.length}>{tag}</span>);
      }
    } else if (text) {
      // Handle text content
      if (currentElement) {
        // If there's an open <a> tag, complete it with the text
        elements.push(React.cloneElement(currentElement, { children: text }));
        currentElement = null; // Reset currentElement after using it
      } else {
        // Otherwise, just push the text as a <span>
        elements.push(<span key={elements.length}>{text}</span>);
      }
    }
  }

  return elements;
}

const PoliciesAndProcedures = () => {
  return (
    <React.Fragment>
      <Styles.Wrapper id="moduleFooter" light>
        <Styles.ContentWrapper>
          <Wrapper style={{ marginTop: 0 }}>
            <Grid>
              <PageTitleWrapper
                id="standard-of-behaviour"
                style={{ gridColumn: "1/-1", paddingTop: "60px" }}
              >
                <PageTitle>Discipleship Trust Standard of Behaviour</PageTitle>
              </PageTitleWrapper>
              <React.Fragment>
                {sofb.map((p) => (
                  <Section>
                    <Grid fullwidth style={{ padding: 0 }}>
                      <ChapterTitle>{p.question}</ChapterTitle>
                      <Content>
                        {[p.answer, p.answer2, p.answer3, p.answer4, p.answer5]
                          .filter(Boolean) // Filter out undefined or null answers
                          .map((answer, index) => (
                            <React.Fragment key={index}>
                              {index > 0 && <br />}
                              {index > 0 && <br />}
                              {answer}
                            </React.Fragment>
                          ))}
                      </Content>
                    </Grid>
                  </Section>
                ))}
              </React.Fragment>

              <PageTitleWrapper
                id="social-media-and-website-policy"
                style={{ gridColumn: "1/-1", paddingTop: "50px" }}
              >
                <PageTitle>
                  Discipleship Trust Social Media & Website Policy
                </PageTitle>
              </PageTitleWrapper>
              <React.Fragment>
                {smandwp.map((p) => (
                  <Section>
                    <Grid fullwidth style={{ padding: 0 }}>
                      <ChapterTitle>{p.question}</ChapterTitle>
                      <Content>
                        {[p.answer, p.answer2, p.answer3, p.answer4, p.answer5]
                          .filter(Boolean) // Filter out undefined or null answers
                          .map((answer, index) => (
                            <React.Fragment key={index}>
                              {index > 0 && <br />}
                              {index > 0 && <br />}
                              {answer}
                            </React.Fragment>
                          ))}
                      </Content>
                    </Grid>
                  </Section>
                ))}
              </React.Fragment>

              <PageTitleWrapper
                id="financial-policy"
                style={{ gridColumn: "1/-1", paddingTop: "50px" }}
              >
                <PageTitle>Discipleship Trust Financial Policy</PageTitle>
              </PageTitleWrapper>
              <React.Fragment>
                {fp.map((p) => (
                  <Section>
                    <Grid fullwidth style={{ padding: 0 }}>
                      <ChapterTitle>{p.question}</ChapterTitle>
                      <Content>
                        {[p.answer, p.answer2, p.answer3, p.answer4, p.answer5]
                          .filter(Boolean) // Filter out undefined or null answers
                          .map((answer, index) => (
                            <React.Fragment key={index}>
                              {index > 0 && <br />}
                              {index > 0 && <br />}
                              {answer}
                            </React.Fragment>
                          ))}
                      </Content>
                    </Grid>
                  </Section>
                ))}
              </React.Fragment>

              <PageTitleWrapper
                id="complaints-policy"
                style={{ gridColumn: "1/-1", paddingTop: "50px" }}
              >
                <PageTitle>Discipleship Trust Complaints Policy</PageTitle>
              </PageTitleWrapper>
              <React.Fragment>
                {cp.map((p) => (
                  <Section>
                    <Grid fullwidth style={{ padding: 0 }}>
                      <ChapterTitle>{p.question}</ChapterTitle>
                      <Content>
                        {[
                          [p.answer, p.listTitle, p.listItems],
                          [p.answer2, p.listTitle2, p.listItems2],
                          [p.answer3, p.listTitle3, p.listItems3],
                          [p.answer4, p.listTitle4, p.listItems4],
                          [p.answer5, p.listTitle5, p.listItems5],
                          [p.answer6, p.listTitle6, p.listItems6],
                          [p.answer7, p.listTitle7, p.listItems7],
                          [p.answer8, p.listTitle8, p.listItems8],
                        ]
                          .filter(
                            ([answer, title, items]) => answer || title || items
                          )
                          .map(([answer, title, items], index) => (
                            <React.Fragment key={index}>
                              {answer && (
                                <React.Fragment>
                                  {index > 0 && <br />}
                                  {index > 0 && <br />}
                                  {renderHtmlContent(answer)}
                                </React.Fragment>
                              )}

                              {title && (
                                <React.Fragment>
                                  <br />
                                  <br />
                                  {title}
                                  <br />
                                  <br />
                                </React.Fragment>
                              )}

                              {Array.isArray(items) && (
                                <ol>
                                  {items.map((item, itemIndex) => (
                                    <li key={itemIndex}>{item}</li>
                                  ))}
                                </ol>
                              )}
                            </React.Fragment>
                          ))}
                      </Content>
                    </Grid>
                  </Section>
                ))}
              </React.Fragment>

              <PageTitleWrapper
                id="financial-policy"
                style={{ gridColumn: "1/-1", paddingTop: "50px" }}
              >
                <PageTitle>Discipleship Trust Complaints Form</PageTitle>
              </PageTitleWrapper>
              <React.Fragment>
                <Section>
                  {references.map(({ title, link }, key) => (
                    <a
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <SliceSummary key={key}>
                        <DownloadDocIcon />
                        <p>{title}</p>
                      </SliceSummary>
                    </a>
                  ))}
                </Section>
              </React.Fragment>
            </Grid>
          </Wrapper>
        </Styles.ContentWrapper>
      </Styles.Wrapper>
      <Footer />
    </React.Fragment>
  );
};

export default PoliciesAndProcedures;
