import React from "react";
import { StatusText, Form, Email, Submit, StatusWrapper } from "./styles";

const AnimatedEllipse = () => {
	const [delta, setDelta] = React.useState(0);

	React.useEffect(() => {
		const timeout = setTimeout(() => {
			if (delta < 3) setDelta(delta + 1);
			else setDelta(0);
		}, 500);

		// Garbage collection
		return () => clearTimeout(timeout);
	}, [delta]);

	return (
		<span>
			{Array(delta)
				.fill(".")
				.join("")}
		</span>
	);
};

const Status = ({ status, message }) => {
	if (status === "success") {
		if (window.localStorage) {
			window.localStorage.setItem("subscribed", true);
		}
	}

	return (
		<StatusWrapper>
			{status === "sending" && (
				<StatusText>
					Loading
					<AnimatedEllipse />
				</StatusText>
			)}
			{status === "error" && (
				<StatusText
					style={{ color: "red" }}
					dangerouslySetInnerHTML={{ __html: message }}
				/>
			)}
			{/* {status === "success" && <StatusText>Subscribed!</StatusText>} */}
		</StatusWrapper>
	);
};

export const SubscribeForm = ({ onSubmit, status, message, onSuccess, groupTitle}) => {
	const [email, setEmail] = React.useState("");

	const handleFormSubmit = event => {
		event.preventDefault();
		switch(groupTitle){
			case 'Matthew': 
				onSubmit({ EMAIL: email, 'group[76918][1]': true,});
				break;
			case 'Acts':
				onSubmit({ EMAIL: email, 'group[76918][2]': true,});
				break;
			case 'Psalms':
				onSubmit({ EMAIL: email, 'group[76918][4]': true,});
				break;
			case 'Ephesians':
				onSubmit({ EMAIL: email, 'group[76918][8]': true,});
				break;
		}
		
	};

	// 4343539
	// React.useEffect(() => {
	// 	setGroupID(groupNameToID[group]);
	// 	console.log(groupID)

	// },[]);
	React.useEffect(() => {
		
		if (status === "success") onSuccess();
	}, [status]);

	return (
		<React.Fragment>
			<Status status={status} message={message} />
			
			<Form 
				onSubmit={handleFormSubmit} 
				// action="https://bibleforlife.us4.list-manage.com/subscribe/post?u=2c2130f2ccf50cdd9f9f5bacb&amp;id=651dbac338&amp;f_id=001d2ae9f0" 
				// method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_self"
				>
					<Email
						value={email}
						onChange={({ target: { value } }) => setEmail(value)}
					/>
					<div class="mc-field-group input-group" style={{display:'none'}}>
					 <strong>Series </strong>
					 <ul>
						<li>
							<input type="checkbox" value="1" name="group[76918][1]" id="mce-group[76918]-76918-0" />
							<label for="mce-group[76918]-76918-0">Matthew</label>
						</li>
						<li>
							<input type="checkbox" value="2" name="group[76918][2]" id="mce-group[76918]-76918-1" />
							<label for="mce-group[76918]-76918-1">Acts</label>
						</li>
						<li>
							<input type="checkbox" value="4" name="group[76918][4]" id="mce-group[76918]-76918-2" />
							<label for="mce-group[76918]-76918-2">Psalms</label>
						</li>
						<li>
							<input type="checkbox" value="8" name="group[76918][8]" id="mce-group[76918]-76918-3" />
							<label for="mce-group[76918]-76918-3">Ephesians</label>
						</li>
					</ul>
						<span id="mce-group[76910]-HELPERTEXT" class="helper_text"></span>
					</div>
					<div id="mce-responses" class="clear">
						<div class="response" id="mce-error-response" style={{display:'none'}}></div>
						<div class="response" id="mce-success-response" style={{display:'none'}}></div>
					</div>  

					{/* <div id="mce-responses" class="clear foot">
						<div class="response" id="mce-error-response" style={{display:'none'}}></div>
						<div class="response" id="mce-success-response" style={{display:'none'}}></div>
					</div>  */}
					<div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_2c2130f2ccf50cdd9f9f5bacb_651dbac338" tabindex="-1" value="" /></div> 
					{/* <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_d5e5886450c6b35b388b91f77_64c61c11fd" tabindex="-1" value="" /></div> */}
					
					<Submit />
		</Form>
		</React.Fragment>
	);
};
