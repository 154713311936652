import {
	AudioContentWrapper,
	AudioListWrapper,
	Author,
	ButtonWrapper,
	ImageWrapper,
	ListItem,
	Play,
	PlayButton,
	PlayerWrapper,
	Slider,
	TextWrapper,
	Thumbnail,
	Time,
	Title,
	Wrapper,
} from "./styles";
import { ModuleDescription, ModuleTitle } from "../../../../components/text";
import { Range, getTrackBackground } from "react-range";

import Grid from "../../../../components/Grid";
import React from "react";
import ReactPlayer from "react-player";
import format from "format-duration";

const Podcast = ({ book, mod }) => {
	const {items, primary} = mod.slices[0];

	const [playing, setPlaying] = React.useState(false);
	const [hovered, setHovered] = React.useState(false);

	const [activeIndex, setActiveIndex] = React.useState(0);
	const [playerLink, setPlayerLink] = React.useState(
		items[activeIndex].podcast_url.url
	);

	const PlayImgBttn = require("../../../../assets/icons/play.svg").default;
	const PauseImgBttn = require("../../../../assets/icons/pause.svg").default;
	const [buttonImg, setButtonImg] = React.useState(PlayImgBttn);

	const PlayAudio = (url, index) => {
		setPlayerLink(url);
		setPlaying(true);
		setActiveIndex(index);
		setButtonImg(PauseImgBttn);
		if (playerLink === url && buttonImg === PauseImgBttn) {
			setPlaying(false);
			setButtonImg(PlayImgBttn);
		}
	};

	const ChangeHoverState = () => {
		setHovered(!hovered);
	};
	const [duration, setDuration] = React.useState(1);
	const [playedSeconds, setPlayedSeconds] = React.useState(0);
	const [loadedSeconds, setLoadedSeconds] = React.useState(0);

	const player = React.useRef(null);

	const handleProgress = ({ playedSeconds, loadedSeconds }) => {
		setPlayedSeconds(playedSeconds);
		setLoadedSeconds(loadedSeconds);
	};

	const handleChange = ([playedSeconds]) => {
		setPlayedSeconds(playedSeconds);
		if (player.current) {
			player.current.seekTo(playedSeconds, "seconds");
		}
	};

	
	return (
		<Wrapper>
			<Grid>
				<TextWrapper>
					<ModuleTitle>{mod.title}</ModuleTitle>
					<ModuleDescription>{mod.description}</ModuleDescription>
				</TextWrapper>
				<ImageWrapper image={book.image} />

				<PlayerWrapper className="player-wrapper">
					<Play
						image={buttonImg}
						onClick={() =>
							PlayAudio(
								items[activeIndex].podcast_url.url,
								activeIndex
							)
						}
					/>
					<Slider
						style={{
							display: "flex",
							justifyContent: "center",
							flexWrap: "wrap",
						}}
					>
						<Range
							step={1}
							min={0}
							max={duration}
							rtl={false}
							values={[playedSeconds]}
							onChange={handleChange}
							renderTrack={({ props, children }) => (
								<div
									onMouseDown={props.onMouseDown}
									onTouchStart={props.onTouchStart}
									{...props}
									style={{
										...props.style,
										height: "3px",
										width: "100%",
										display: "flex",
										backgroundColor: "#62636B",
									}}
								>
									<div
										ref={props.ref}
										style={{
											height: "3px",
											width: "100%",
											borderRadius: "50%",
											background: getTrackBackground({
												values: [playedSeconds],
												colors: ["#D8D8D8", "#62636B"],
												min: 0,
												max: duration,
												rtl: false,
											}),
											alignSelf: "center",
										}}
									></div>
									{children}
								</div>
							)}
							renderThumb={({ props }) => (
								<div
									{...props}
									style={{
										...props.style,
										height: "25px",
										width: "25px",
										borderRadius: "50%",
										backgroundColor: "#F4F1EE",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								></div>
							)}
						/>
					</Slider>
					<Time>
						{format(playedSeconds * 1000)} /{" "}
						{format(duration * 1000)}
					</Time>

					<ReactPlayer
						ref={player}
						id="player"
						file={{ forceAudio: true }}
						playing={playing}
						seconds={playedSeconds}
						className="react-player"
						width="100%"
						style={{
							display: "none",
						}}
						url={playerLink}
						onProgress={handleProgress}
						onDuration={setDuration}
					/>
				</PlayerWrapper>

				<AudioListWrapper>
					{items.map(
						(
							{ podcast_title, podcast_author, podcast_url },
							index
						) => (
							<AudioContentWrapper
								onClick={() =>
									PlayAudio(podcast_url.url, index)
								}
							>
								<Thumbnail image={book.image}>
									<ButtonWrapper
										key={index}
										active={index === activeIndex}
									>
										<PlayButton
											hoverState={true}
											onMouseOver={ChangeHoverState}
											image={buttonImg}
										></PlayButton>
									</ButtonWrapper>
								</Thumbnail>
								<ListItem>
									<Title>{podcast_title}</Title>
									<Author>{podcast_author}</Author>
								</ListItem>
							</AudioContentWrapper>
						)
					)}
				</AudioListWrapper>
			</Grid>
		</Wrapper>
	);
};

export default Podcast;
