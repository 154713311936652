import { useRef } from "react";
import { Description, Time, Title, Type, Wrapper } from "./styles";

export default ({
	type,
	minutes,
	title,
	shortDescription,
	onOpenModal,
	reading,
	hearing,
	worship,
	studying,
	memorising,
	meaning,
	emailseries,
	email
}) => {
	const self = useRef()
	return( 
	<Wrapper
		onClick={onOpenModal}
		onMouseEnter={() => self.current.click()}
		reading={reading}
		hearing={hearing}
		worship={worship}
		studying={studying}
		memorising={memorising}
		meaning={meaning}
		emailseries={emailseries}
		ref={self}
		email={email}
	>
		<Type>{type}</Type>
		<Time>{minutes}</Time>
		<Title 
		email={email}
		>{title}</Title>
		{/* Ellipsis. Max chars = 50 */}
		<Description>
			{shortDescription.slice(0, 50) +
				(shortDescription.length > 50 ? "..." : "")}
		</Description>
	</Wrapper>
)}
;
