import { createBreakpoint } from "styled-components-breakpoint";

const values = { xs: 0, sm: 350, md: 700, lg: 1050, xl: 1400 };

const breakpoint = createBreakpoint(values);

breakpoint.prototype.getValues = () => {
	return values;
};

export default breakpoint;
