import { BookParagraph, BookSubtitle, BookTitle } from "../components/text";

import Carousel from "../components/Carousel";
import React from "react";
import breakpoint from "../breakpoint";
import getTestimonials from "../scripts/get/testimonials";
import styled from "styled-components";
import { BookNavigationButtons, NextLogo, NextPreviousButton, PreviousLogo, StartButton } from "../pages/BookSummary/styles";
import CarouselControls from "../components/CarouselControls";
import { useGlobalState } from "../scripts/useGlobalState";

const Wrapper = styled.div`
	margin: 0;
	padding: 0;
	box-sizing: border-box;

	/* margin-left: 18px; */
	
	margin-bottom: ${({ nomargin }) => nomargin ? '0' : '40px'};

	${breakpoint("md")`
		margin-bottom: ${({ nomargin }) => nomargin ? '0' : '80px'};
    `}
	${breakpoint("lg")`
		margin-bottom: ${({ nomargin }) => nomargin ? '0' : '80px'};
    `}
`;

const Testimonials = ({ light, dark }) => {
	const [testimonials, setTestimonials] = React.useState(null);
	const [isTestimonialsLoading, setIsTestimonialsLoading] = useGlobalState('testimonials');

	React.useEffect(() => {
		(async function () {
			let testimonials = await getTestimonials();
			setTestimonials(testimonials);
		})();
	}, []);

	React.useEffect(() => {
		testimonials && (testimonials.length > 0) && setIsTestimonialsLoading(false);
	}, [testimonials]);



	return (
		<React.Fragment>
			<Wrapper nomargin>
				{testimonials && (
					testimonials.length ? (
						<Carousel loop id={1}>
							{[...testimonials, ...testimonials].map(
								({ job_title, full_name, review, link }, key) => (
									<Carousel.Testimonial
										key={key}
										light={light}
										dark={dark}
										role={job_title}
										title={full_name}
										body={review}
										link={link}
										hide={link == null}
									/>
								)
							)}
						</Carousel>
					) : (
						"No testimonials"
					)
				)}
			</Wrapper>
			 {/* <CarouselControls id={1} testis nofloat/> */}
		</React.Fragment>
	);
};

export default Testimonials;
