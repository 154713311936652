import breakpoint from "../../breakpoint";
import styled from "styled-components";

const Panel = {
	Wrapper: styled.div`
		padding-top: 100%;
		grid-column: span 1;
		position: relative;

		background-image: url("${({ image }) => image}");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	`,
	Title: styled.h1`
		font-size: 32px;
		line-height: 55px;
		margin: 0 0 45px;

		${breakpoint("md")`
            font-size: 54px;
			line-height: 63px;
        `}

		${breakpoint("lg")`
            font-size: 54px;
			line-height: 63px;
        `}
	`,
	ShortDescriptor: styled.h4`
		font-size: 18px;
		line-height: 21px;
		margin: 0 0 45px;

		${breakpoint("md")`
            font-size: 24px;
			line-height: 28px;
        `}
		${breakpoint("lg")`
            font-size: 24px;
			line-height: 28px;
        `}
	`,
	LongDescriptor: styled.p`
		font-size: 18px;
		line-height: 26px;
		margin: 0;

		display: none;

		${breakpoint("xs", "lg")`
            display: none !important;
        `}
	`,
	Button: styled.button`
		appearance: none;

		background: #f4f1ee;
		border: 1px solid #f4f1ee;
		border-radius: 23.5px;

		padding: 15px 44px;
		box-sizing: border-box;

		font-size: 18px;
		line-height: 18px;
		color: #62636b;

		margin-top: 45px;

		display: none;

		&:hover {
			color: #111112;
		}

		${breakpoint("xs", "lg")`
            position: absolute;
            opacity: 0;
            left: 0; top: 0;
            width: 100%;
            height: 100%;
        `}
	`,
	ButtonMobile: styled.button`
		appearance: none;

		background: #f4f1ee;
		border: 1px solid #f4f1ee;
		border-radius: 23.5px;

		padding: 15px 44px;
		box-sizing: border-box;

		font-size: 18px;
		line-height: 18px;
		color: #111112;

		margin: 0;

		${breakpoint("lg")`
			display: none;
		`}
		

	`,
};

Panel.Content = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	padding: 0 18%;
	box-sizing: border-box;

	text-align: center;
	color: #f4f1ee;

	background: linear-gradient(180deg, #111112 0%, rgba(17, 17, 18, 0.3) 100%);

	&:hover {
		align-items: top;
		/* padding-top: 75px; */
		/* background-color: #111112bb; */
		background: linear-gradient(
			180deg,
			#111112 0%,
			rgba(17, 17, 18, 0.6) 100%
		);

		${Panel.LongDescriptor}, ${Panel.Button} {
			display: block;
		}
	}
`;

export default Panel;
