import { BlockSubtitle, Heading, Subheading } from "../components/text";
import breakpoint from "../breakpoint";
import Button, { CenterButtonWrapper } from "../components/Button";
import Banner from "../components/Banner";
import BookSearch from "../blocks/BookSearch";
import Container from "../components/Container";
import Footer from "../components/Footer";
import React from "react";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Testimonials from "../blocks/Testimonials";
import { gsap } from "gsap";
import styled from "styled-components";
import "../components/banner.css";
import CarouselControls from "../components/CarouselControls";
import { useGlobalState } from "../scripts/useGlobalState";
import NewsletterModal from "../components/NewsletterModal"

// import smoothscroll from 'smoothscroll-polyfill';



export const SearchIcon = styled.img.attrs({
	src: require("../assets/icons/Search.svg").default,
})`
	height: 21px;
	margin-left: 21px;
	text-align: center;
	${breakpoint('lg')`
		height: 33px`}
	
`;

gsap.registerPlugin(ScrollToPlugin);
const ScrollWrapper = styled.div`
	padding: 0;
	margin: 0;
`;

const Home = () => {
	const [testimonLoad, setTestimonLoad] = useGlobalState('testimonials');
	const [bookLoad, setBookLoad] = useGlobalState('books');
	const [loading, setLoading] = useGlobalState('loading');
	const [videoLoading, setVideoLoad] = useGlobalState('video');
	const [heroSearch, setHeroSearch] = React.useState(false);


	React.useEffect(() => {
		setLoading(true);
		setBookLoad(true);
		setVideoLoad(true);
		setTestimonLoad(true);
	}, [])

	React.useEffect(() => {
		!testimonLoad && !videoLoading && setLoading(false);
	}, [testimonLoad, bookLoad, videoLoading])

	const ScrollDown = () => {
		var id = document.getElementById("searchID");

		if(id){
			let offsetY = window.screen.width < 1050 ? 0 : 18; 
			gsap.to(window, {
				duration: 1.5,  
				delay: 0.0,
				scrollTo: { y: id.offsetTop - offsetY }, 
			});
		}
	};

	const ScrollUp = () => {
		var id = document.getElementById("searchID");
		if (id) {
			let offsetY = window.screen.width < 1050 ? -40 : -80; 

			gsap.to(window, {
				duration: 1.5,  
				delay: 0.0,
				scrollTo: { y: id.offsetTop + offsetY }, 
			});
		}
		
	};

	const handleHeroSearch = () => {
		ScrollDown();
		setHeroSearch(true);
	}

	const handleBottomSearch = () => {
		ScrollUp();
		setHeroSearch(true);
	}

	return (
		<React.Fragment>
			<NewsletterModal />
			<Banner
				id='banner'
				className="home"
				aside
				urlThumb={'../assets/images/homevideothumb.png'}
			// playerLink="https://vimeo.com/112866269"
			>
				<Heading>free bible discipleship tool</Heading>
				<Subheading>
					Enabling you to go deeper into God's Word one book at a time.
				</Subheading>
				<CenterButtonWrapper style={{ display: 'flex' }}>
					<Button dark onClick={ScrollDown}>
						Choose book
					</Button>
					<Button
						type="button"
						style={{
							marginLeft: '15px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center'
						}}
						onClick={handleHeroSearch}
					>
						Search
						<SearchIcon />
					</Button>
				</CenterButtonWrapper>
			</Banner>
			<ScrollWrapper id="searchID">
				<BookSearch
					heroSearch={heroSearch}
					setHeroSearch={setHeroSearch}
					onSearchStateChanger={handleBottomSearch} />
			</ScrollWrapper>
			<Container nopad customWidth style={{ display: "block" }}>
				<Testimonials dark />
			</Container>
			<CarouselControls id={1} testis nofloat />
			{/* <Container style={{ display: "block" }}> */}
			{/* 		<Testimonials dark/> */}
			{/* </Container> */}
			<Footer />
		</React.Fragment>
	);
};

export default Home;
