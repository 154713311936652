import React from "react";
import { StatusText, Form, Email, Submit, StatusWrapper } from "./styles";

const AnimatedEllipse = () => {
	const [delta, setDelta] = React.useState(0);

	React.useEffect(() => {
		const timeout = setTimeout(() => {
			if (delta < 3) setDelta(delta + 1);
			else setDelta(0);
		}, 500);

		// Garbage collection
		return () => clearTimeout(timeout);
	}, [delta]);

	return (
		<span>
			{Array(delta)
				.fill(".")
				.join("")}
		</span>
	);
};

const Status = ({ status, message }) => {
	if (status === "success") {
		if (window.localStorage) {
			window.localStorage.setItem("subscribed", true);
		}
	}

	return (
		<StatusWrapper>
			{status === "sending" && (
				<StatusText>
					Loading
					<AnimatedEllipse />
				</StatusText>
			)}
			{status === "error" && (
				<StatusText
					style={{ color: "red" }}
					dangerouslySetInnerHTML={{ __html: message }}
				/>
			)}
			{/* {status === "success" && <StatusText>Subscribed!</StatusText>} */}
		</StatusWrapper>
	);
};

export const SubscribeForm = ({ onSubmit, status, message, onSuccess }) => {
	const [email, setEmail] = React.useState("");

	const handleFormSubmit = event => {
		event.preventDefault();
		onSubmit({ EMAIL: email });
	};

	React.useEffect(() => {
		if (status === "success") onSuccess();
	}, [status]);

	return (
		<React.Fragment>
			<Status status={status} message={message} />
			<Form onSubmit={handleFormSubmit}>
				<Email
					value={email}
					onChange={({ target: { value } }) => setEmail(value)}
				/>
				<Submit />
			</Form>
		</React.Fragment>
	);
};
