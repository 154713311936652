import React from "react";
import breakpoint from "../breakpoint";
import styled from "styled-components";

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	margin: 0 10px;
	column-gap: 10px;
	row-gap: 10px;

	${breakpoint("md", "lg")`
		${({fullWidth}) => fullWidth && "margin: 0"};
		
	`}; 

	${({ noPadding }) =>
		noPadding &&
		`
		padding: 0;
		margin: 0;
    	bottom: 21px;
	`}

	${({ fullWidth2 }) =>
		fullWidth2 &&
		`
		padding: 0;
		width: calc(100vw - 20px);
		display: none;
	`}


	${breakpoint("md")`
		margin: 0  25px;
		${({ banner }) =>
			banner &&
			`
				padding: 0 25px;
				margin: 0;
			`}

			${({ toe }) =>
			toe &&
			`
				padding: 0;
				margin: 0;
			`}
		${({ fullWidth2 }) =>
			fullWidth2 &&
			`
			display: grid;
			padding: 0;
			margin: 0 25px;
			width: calc(100vw - 50px);
		`}

		${({ noPadding }) =>
			noPadding &&
			`
			padding: 0;
		`}

    `}

	${breakpoint("lg")`
		box-sizing: border-box;
		padding: 30px;
		column-gap: 20px;
		max-width: 1252px;
		row-gap: 20px;
		margin: 0 auto;
        grid-template-columns: repeat(12, 1fr);

		${({ banner }) =>
			banner &&
			`
				padding: 30px 0;
				margin: 0 auto;
			`}

			${({ toe }) =>
			toe &&
			`
				padding: 0;
				margin: 0 auto;
			`}

		${({ fullWidth2 }) =>
			fullWidth2 &&
			`
			padding: 0;
			max-width: 1247px;
		`}
		
		${({ noPadding }) =>
			noPadding &&
			`
			max-width: 1298px;
			position: absolute;
			top: 36px;
			left: 105px;
			right: 36px;
			bottom: 55px;
			margin: auto;
			padding: 0;
			align-items: end;
		`}

		${({ bookLanding }) =>
			bookLanding &&
			`
			max-width: 1287px;
			padding: 0;
		`}
    `}


	${({ banner }) =>
		banner &&
		`
		padding: 0 10px;
		margin: 0;
	`}
`;

const Grid = ({ children, fullWidth, fullWidth2, bookLanding, noPadding, banner, toe, style}) => {
	return <Wrapper style={style} banner={banner} bookLanding={bookLanding} noPadding={noPadding} toe={toe} fullWidth={fullWidth} fullWidth2={fullWidth2} >{children}</Wrapper>;
};

export default Grid;
