import Grid from "./Grid";
import React from "react";
import breakpoint from "../breakpoint";
import styled, { keyframes } from "styled-components";
import ReactPlayer from "react-player";
import { Subheading } from "../components/text";
import { CenterButtonWrapper } from "../components/Button";
import { useGlobalState } from "../scripts/useGlobalState";

const Wrapper = styled.header`
    background-image: url(${({ image }) => image});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	min-height: 280px;
	width: 100%;

	padding: 33px 0;
	box-sizing: border-box;

	${breakpoint("md")`
		min-height: 440px;
		padding: 50px 0;
		// height: 80vh;
    `}
	${breakpoint("lg")`
		min-height: 730px;
		padding: 122px 48px;
		// padding-top: 192px;
		height: 80vh;
    `}
`;

const TextWrapper = styled.div`
	grid-column: 1 / -1;

	${Subheading}{
		margin-bottom: 36px;
	}

	${breakpoint("md")`

		${Subheading}{
			margin-bottom: 70px;
		}

		${CenterButtonWrapper}{
			text-align: center;
		}
	`}

	${breakpoint("lg")`
		grid-column: 1 / 7;

		${Subheading}{
			margin-bottom: 140px;
		}

		${CenterButtonWrapper}{
			text-align: left;
		}
	`}
`;

const AsideWrapper = styled.div`
	display: block;
	position: relative;
	width: 100vw;
	height: 100%;

	margin: 0;
	padding: 0;

	${breakpoint("lg")`
		width: 722px;
		height: 417.27px;
		margin: 150px 0;
	`}
`;

export const PlayerWrapper = styled.div`
	position: relative;
	padding-top: 56.25%;
	/* margin: 0 auto; */

	height: 100%;
	width: 100%;
`;

const AboutBanner = ({ image, children, playerLink, className }) => {
	const [videoLoading,setVideoLoading] = useGlobalState('video')
	return (
		<React.Fragment>
		<Wrapper image={image} className={className}>
			<Grid banner>
				<TextWrapper>{children}</TextWrapper>
				
			</Grid>
		</Wrapper>
		<AsideWrapper>
			<PlayerWrapper className="player-wrapper">
						<ReactPlayer
							id="player"
							vimeo
							// onReady={() => setVideoLoading(false)} 
							onReady={() => {setVideoLoading(false)}}
							// playing="false"
							controls ="true"
							className="react-player"
							width="100vw"
							height="100%"
							style={{
								position: "absolute",
								top: "0",
								left: "0",
							}}
							url={playerLink}
							// playsinline="false"
						/>
					</PlayerWrapper>
		</AsideWrapper>
		</React.Fragment>
	);
};

export default AboutBanner;
