import React from "react";
import breakpoint from "../breakpoint";
import styled from "styled-components";
import Carousel from "../components/Carousel";
import fetchTeam from "../scripts/get/theTeam";
import { BookNavigationButtons, NextLogo, NextPreviousButton, PreviousLogo, StartButton } from "../pages/BookSummary/styles";
import CarouselControls from "../components/CarouselControls";
import { useGlobalState } from "../scripts/useGlobalState";

const Wrapper = styled.div`
	margin: 0px 18px;
	padding: 0;
	box-sizing: border-box;
	margin-bottom: 40px;

	${breakpoint("md")`
		margin-left: 18px;	
		margin-bottom: 80px;
    `}
	${breakpoint("lg")`
		margin-left: 50px;
		margin-bottom: 80px;
    `}
`;


const TeamMembers = () => {

	const [team, setTeam] = React.useState([]);
	const [, setTeamLoading] = useGlobalState('team')
	React.useEffect(() => {
		(async function () {
			let team = await fetchTeam();
			setTeam(team);
		})();
	}, []);

	React.useEffect(() => {
		team.length > 0 && setTeamLoading(false)
	} ,[team])

	return (
		<Wrapper>
			<Carousel id={2}>
				{team.map(({ photo, full_name, role, bio, link }) => (
					<Carousel.Team
						photo={photo}
						bio={bio}
						link={link}
						role={role}
						title={full_name}
						hide={link == null}
					/>
				))}
			</Carousel>
		</Wrapper>
	);
};

export default TeamMembers;


