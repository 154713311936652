import Prismic from "prismic-javascript";
import formatBookCategory from "../format/bookCategory";
import getAPIRoot from "../getAPIRoot";

export default async () => {
    const api = await getAPIRoot();
    
    const { results } = await api.query(
		Prismic.Predicates.at("document.type", "book_category")
	);

    return results.map(formatBookCategory);
}