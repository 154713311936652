import breakpoint from "../breakpoint";
import styled from "styled-components";

export const Heading = styled.h1`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  /* line-height: 64px; */

  margin: 0;
  margin-bottom: 12px;

  color: #111112;
  ${breakpoint("md")`
		font-size: 72px;
		line-height: 80px;
		margin-bottom: 21px;
	`}
  ${breakpoint("lg")`
		font-size: 72px;
		line-height: 80px;
		margin-bottom: 38px;
	`}
`;

export const Subheading = styled.h3`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  margin: 0;
  margin-bottom: 37px;

  color: #111112;
  ${breakpoint("md")`
		font-size: 22px;
		line-height: 28px;
		margin-bottom: 39px;	
	`}
  ${breakpoint("lg")`
		font-size: 22px;
		line-height: 28px;
		margin-bottom: 140px;	
	`}
`;

export const Paragraph = styled.p`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;

  margin: 0;
  margin-bottom: 17px;

  color: #62636b;

  ${breakpoint("md")`
		font-size: 14px;
		line-height: 18px;
	`}
  ${breakpoint("lg")`
		font-size: 16px;
		line-height: 20px;
	`}
`;

export const BlockTitle = styled.h4`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;

  color: #111112;

  padding-bottom: 11px;

  display: none;

  grid-column: 1 / 3;

  ${breakpoint("md")`
		display: block;
	`}

  ${breakpoint("lg")`
		display: block;
	`}
`;

export const BlockSubtitle = styled.h5`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #111112;

  padding-bottom: 5px;
  border-bottom: 1px solid rgba(17, 17, 18, 0.4);

  margin: 0;

  ${breakpoint("md")`
		font-size: 22px;
		line-height: 28px;
    `};

  ${breakpoint("lg")`
        font-size: 22px;
		line-height: 28px;
    `};
`;

export const BookTitle = styled.h4`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 63px;
  text-align: center;
  color: #f4f1ee;

  padding-top: 128px;
  padding-bottom: 24px;
  margin: 0;

  ${breakpoint("lg")`
		font-size: 54px;
		padding-top: 236px;
		padding-bottom: 45px;

	`}
`;

export const BookSubtitle = styled.h5`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  text-align: center;

  color: #f4f1ee;
  padding-bottom: 24px;
  margin: 0;

  ${breakpoint("lg")`
		font-size: 24px;
		padding-bottom: 45px;

	`}
`;

export const BookParagraph = styled.h5`
  display: none;

  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  text-align: center;

  color: #f4f1ee;
  max-width: 428px;
  min-height: 200px;

  /* padding-bottom: 15px; */

  margin: 0 auto;

  ${breakpoint("lg")`
		font-size: 18px;

	`}
`;

export const Address = styled.p`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14.4px;
  line-height: 17px;

  color: #111112;

  margin: 0;

  ${breakpoint("lg")`
		font-size: 18px;
		line-height: 21px;
	`}
`;

export const CardTitle = styled.h4`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 30px;

  margin: 0;
  margin-bottom: 40px;

  ${breakpoint("lg")`
		font-size: 40px;
		line-height: 37px;
	`}

  color: #111112;
`;

export const CardParagraph = styled.h4`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14.4px;
  line-height: 21px;

  margin: 0;
  margin-bottom: 19px;

  ${breakpoint("lg")`
		font-size: 18px;
		line-height: 26px;
	`}
`;

export const StoryTitle = styled.h5`
  font-family: "Cerebri Sans";
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 29px;
  padding-bottom: 9px;

  ${breakpoint("md")`
		font-size: 36px;
		line-height: 42px;
		padding-bottom: 17px;
		margin-bottom: 24px;
    `};

  ${breakpoint("lg")`
		font-size: 36px;
		line-height: 42px;
		padding-bottom: 17px;
		margin-bottom: 26px;
    `};

  color: #111112;

  border-bottom: 1px solid #111112;
`;

export const StoryParagraph = styled.p`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #111112;
  ${({ paddingLeft }) =>
    paddingLeft &&
    `
  grid-column: 1/-1;
`}

  ${({ gridColumn }) =>
    gridColumn &&
    `
    grid-column: 1/-1;
	`}

  ${breakpoint("md")`
      	font-size: 18px;
		line-height: 26px;
    `};

  ${breakpoint("lg")`
        font-size: 18px;
		line-height: 26px;
		${({ paddingLeft }) =>
      paddingLeft &&
      `
		margin-left: 50px;
    grid-column: 1/6;
	`}
  ${({ gridColumn }) =>
    gridColumn &&
    `
		margin-left: 50px;
    grid-column: 7/-1;
	`}
    `};
`;

export const SectionTitle = styled.h6`
  font-family: "Cerebri Sans";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;

  color: #a1a0a5;

  padding-bottom: 17px;

  ${breakpoint("md")`
		font-size: 24px;
		line-height: 28px;
		letter-spacing: 3.44444px;
		padding-bottom: 23px;
    `};

  ${breakpoint("lg")`
		font-size: 24px;
		line-height: 28px;
		letter-spacing: 3.44444px;
		padding-bottom: 32px;
    `};
`;

export const SectionHeading = styled.h2`
  font-family: "Cerebri Sans";
  font-size: 32px;
  line-height: 120%;

  color: #ffffff;

  ${breakpoint("md")`
		font-size: 72px;
		line-height: 80px;
    `};

  ${breakpoint("lg")`
        font-size: 72px;
		line-height: 80px;
    `};
`;

export const CourseTitle = styled.h1`
  font-family: "Cerebri Sans";
  font-size: 40px;
  line-height: 44px;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;

  color: #111112;

  ${breakpoint("md")`
		font-size: 88px;
		line-height: 88px;
		margin-bottom: 20px;
    `};

  ${breakpoint("lg")`
		font-size: 88px;
		line-height: 88px;
		margin-bottom: 20px;
    `};
`;

export const ModuleTitle = styled.h1`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;
  margin: 0;
  margin-bottom: 26px;
  padding: 0;

  color: #f4f1ee;

  ${breakpoint("md")`
		font-size: 88px;
		line-height: 81px;
		margin-bottom: 40px;
    `};

  ${breakpoint("lg")`
		font-size: 88px;
		line-height: 81px;
		margin-bottom: 40px;
    `};
`;

export const SliceTitle = styled.h5`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;

  margin: 0;
  margin-bottom: 5px;
  padding: 0;

  color: #111112;

  ${breakpoint("md")`
		font-size: 24px;
		line-height: 28px;
		margin-bottom: 10px;
    `};

  ${breakpoint("lg")`
		font-size: 24px;
		line-height: 28px;
		margin-bottom: 10px;
    `};
`;

export const ModuleDescription = styled.h1`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 50px;

  color: #f4f1ee;

  ${breakpoint("md")`
		font-size: 22px;
		line-height: 28px;
		margin-bottom: 100px;
    `};

  ${breakpoint("lg")`
		font-size: 22px;
		line-height: 28px;
		margin-bottom: 60px;
    `};
`;

export const CourseShortDescription = styled.h6`
  font-family: "Cerebri Sans";
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;

  color: #111112;

  ${breakpoint("md")`
		font-size: 24px;
		line-height: 30px;
		margin-bottom: 35px;
    `};

  ${breakpoint("lg")`
		font-size: 24px;
		line-height: 30px;
		margin-bottom: 35px;
    `};
`;

export const CourseLongDescription = styled.p`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  padding: 0;

  color: #111112;

  ${breakpoint("md")`
		font-size: 22px;
		line-height: 28px;
    `};

  ${breakpoint("lg")`
		font-size: 22px;
		line-height: 28px;
    `};
`;

export const PopName = styled.h6`
  font-family: "Cerebri Sans";
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  margin: 0;
  padding: 0;

  color: #111112;

  ${breakpoint("md")`
		font-size: 24px;
		line-height: 28px;
    `};

  ${breakpoint("lg")`
        font-size: 24px;
		line-height: 28px;
    `};
`;

export const PopRole = styled.h6`
  font-family: "Cerebri Sans";
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;

  margin: 0;
  padding: 0;

  color: rgba(98, 99, 107, 0.695613);

  ${breakpoint("md")`
		font-size: 22px;
		line-height: 28px;
    `};

  ${breakpoint("lg")`
		font-size: 22px;
		line-height: 28px;
    `};
`;

export const PopAboutTitle = styled.h6`
  font-family: "Cerebri Sans";
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #62636b;

  ${breakpoint("md")`
		font-size: 22px;
		line-height: 26px;
    `};

  ${breakpoint("lg")`
		font-size: 22px;
		line-height: 26px;
    `};
`;

export const TeamFullName = styled.h6`
  font-family: "Cerebri Sans";
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;

  color: #111112;

  ${breakpoint("md")`
      font-size: 28px;
	line-height: 33px;
    `};

  ${breakpoint("lg")`
        font-size: 28px;
	line-height: 33px;   
    `};
`;

export const TeamRole = styled.h6`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #111112;

  ${breakpoint("md")`
		font-size: 22px;
		line-height: 28px;
	`};
  ${breakpoint("lg")`
		font-size: 22px;
		line-height: 28px;
	`};
`;

export const VideoModuleDescription = styled.h6`
  font-family: "Cerebri Sans";
  font-size: 13px;
  line-height: 15px;

  color: #f4f1ee;

  mix-blend-mode: normal;
  opacity: 0.5;

  margin-bottom: 33px;

  ${breakpoint("md")`
		font-size: 24px;
		line-height: 28px;
		margin-bottom: 50px;
    `};

  ${breakpoint("lg")`
        font-size: 24px;
		line-height: 28px;
		margin-bottom: 91px;
    `};
`;

export const VideoTitle = styled.p`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #f4f1ee;
`;

export const ArtistName = styled.p`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;

  color: #62636b;

  margin-bottom: 7px;

  ${breakpoint("md")`
		font-size: 18px;
		line-height: 21px;
    `};

  ${breakpoint("lg")`
		font-size: 18px;
		line-height: 21px;
    `};
`;

export const DropDownText = styled.p`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;

  margin: 0;
  padding: 0;

  color: #62636b;

  ${breakpoint("md")`
		font-size: 22px;
		line-height: 26px;
    `};

  ${breakpoint("lg")`
		font-size: 22px;
		line-height: 26px;
    `};
`;
