import breakpoint from "../breakpoint";
import styled from "styled-components";

export default styled.div`
  display: block;
  row-gap: 18px;
  max-width: 1296px;
  margin: 0 ${({ nopadH }) => (nopadH ? "0px" : "43px")};
  padding: 14px 0 ${({ nopad }) => (nopad ? "0" : "80px")} 0;

  ${({ customWidth }) => customWidth && "width: calc(100% - 21px);"}
  ${({ card }) =>
    card &&
    `
			width: 233px;
			margin: 0 auto;
		`}
${({ toi }) =>
    toi &&
    `
			padding: 0 12px;
			width: auto;
		`}

	${breakpoint("md")`
		margin: 0 ${({ nopadH }) => (nopadH ? "0px" : "25px")};
		padding: 0 0 ${({ nopad }) => (nopad ? "0" : "151px")} 0;
		${({ customWidth }) => customWidth && "width: calc(100% - 51px);"}
		${({ card }) =>
      card &&
      `
			width: 422px;
		`}

		${({ toi }) =>
      toi &&
      `
				padding: 0;
			`}

		@media only screen and (min-width: 891px) {
			${({ card }) =>
        card &&
        `
					width: calc(422px + 422px + 15px);
				`}
		}
		
    `}

	/* @media screen and (min-width: 1050px) and (max-width: 1300px){
		padding: 0 0 272px 25px !important;
	} */

	${breakpoint("lg")`
		margin: 0 auto;
		padding: 0 0 ${({ nopad }) => (nopad ? "0" : "272px")} 25px;
		${({ customWidth }) => customWidth && "width: calc(100% - 102px);"}
		${({ card }) =>
      card &&
      `	
			width: calc(422px + 422px + 15px);
			margin: 0 25px;
			padding-left: 0;
		`}

		${({ toi }) =>
      toi &&
      `
			padding: 0;
		`}

		@media only screen and (min-width: 1301px) {
			${({ card }) =>
        card &&
        `
					width: calc(422px + 422px + 15px);
					margin: 0 25px;
				`}
		}

		@media only screen and (min-width: 1332px) {
			${({ card }) =>
        card &&
        `
					width: calc(422px + 422px + 15px + 422px + 15px);
					margin: 0 auto;
				`}
		}
		
    `}
`;
