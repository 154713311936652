const faqs = [
  {
    question: "Is the material free?",
    answer:
      "Yes! All the website content and media are completely free and can be downloaded and used by anyone without cost. We only ask you to credit Bible for Life as the source. If you would like to give a gift in appreciation, please join others giving here ….",
    answer2:
      "<a href='https://www.stewardship.org.uk/partners/20380430' target='_blank'>The Bible for Life Discipleship Trust</a>",
  },
  {
    question: "I’m new to this, where is the best place to start?",
    answer:
      "Choose a Bible book, perhaps one of your favourites 🡺 search for the book 🡺 open the Homepage and enjoy opening and engaging with the books’ content items. ",
    answer2:
      "You will find it easier to begin with the items in ‘What you need to know’",
  },
  {
    question: "I find reading the Bible very hard what shall I do?",
    answer:
      "Why not take a short, more straightforward book in the New Testament and begin reading it, perhaps Paul’s letter to <strong>Titus</strong>, or <strong>Colossians</strong>, or the little letter of <strong>3 John</strong>.",
    answer2:
      "At the base of each of the sections; ‘What you need to know’, The heart of ‘the book’, and ‘Living it’, are ‘Tools’ that give advise about how best to approach and engage with the book.",
  },
  {
    question:
      "I have some in-depth questions about the Bible, what shall I do?",
    answer:
      "Study the ‘Verse by verse’ media in ‘The Heart of “the book’’. If you still have questions you might want to refer to the resources in the ‘Best resources’, or, you are welcome to email: <a href='mailto:info@bibleforlife.co.uk'>info@bibleforlife.co.uk</a> We shall try and do our best but we cannot promise to answer every question.",
  },
  {
    question:
      "Can I use the material to teach others in my Bible study or another context?",
    answer:
      "Yes, please do. The ‘Living it’ and ‘Kingdom Discipleship’ sections have different media to help you to do this, as well as lots of ideas about how you can use the BfL material to teach and help others.",
  },
  {
    question: "I’ve heard about the “PODs” with Nick, how can I do this?",
    answer:
      "“Pods” are coaching sessions talking about your engagement with a Bible book and usually take place on Zoom. Nick runs these with individuals and groups of  people. The purpose is to coach you in your engagement with the Bible so you discover and learn how to get to the heart of each Bible book, understand its message and learn how to live it out as a disciple in the kingdom.",
  },
];

export default faqs;
