import { ContributorPic, ContributorSection } from "./styles";
import { PopoverBody, UncontrolledPopover } from "reactstrap";

import PopoverModal from "../../components/PopoverModal";
import React from "react";

export default ({ contributors, mobile }) => {
	const [popoverOpen, _setPopoverOpen] = React.useState([]);

	const setPopoverOpen = (index, value) => {
		const temp = [...popoverOpen];
		temp[index] = value;

		_setPopoverOpen(temp);
	};

	React.useEffect(() => {
		_setPopoverOpen(contributors.map(() => false));
	}, []);

	return (
		<ContributorSection>
			{contributors.map(
				({ image, name, bio, role, link, content }, index) => (
					<React.Fragment>
						<ContributorPic
							id={`Popover-${index}`}
							// type="button"
							image={image}
						/>
						<UncontrolledPopover
							key={index}
							trigger="legacy"
							target={`Popover-${index}`}
							placement="bottom-end"
							toggle={() =>
								setPopoverOpen(index, !popoverOpen[index])
							}
							isOpen={popoverOpen[index]}
							html="true"
							style={{
								border: "none",
							}}
							flip="false"
							hideArrow="true"
						>
							<PopoverBody
								style={{
									padding: "0",
								}}
							>
								<PopoverModal
									onClose={() => setPopoverOpen(index, false)}
									image={image}
									name={name}
									role={role}
									bio={bio}
									link={link}
									hide={link == null}
								/>
							</PopoverBody>
						</UncontrolledPopover>
					</React.Fragment>
				)
			)}
		</ContributorSection>
	);
};
