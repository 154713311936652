import Order from "./order";

const sort = (books) => ({
	by: (orderName) =>
		books
			.map((book) => ({
				...book,
				index: Order[orderName.toLowerCase()].findIndex(
					(title) => title === book.title
				),
			}))
			.sort((a, b) => (a.index > b.index ? 1 : -1)),
});

export default sort;
