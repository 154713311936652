import React from "react";
import styled from "styled-components";
import breakpoint from "../breakpoint";
import {StyledLink} from "./Link";
import Button from "./Button";
import { RichText } from "prismic-reactjs"; //prismic-dom";

const Overlay = styled.div`
	/* background-color: rgba(250, 177, 78, 0.780212); */
	
	left: 0;
	top: 0;
	width: 100vw;
	min-height: 100vh;
	margin: 0;
	padding: 0;

	display: flex;
	justify-content: center;
	align-items: flex-start;
	z-index: 10000 !important;

    ${breakpoint("md")`
        padding: 75px 0;
        // align-items: center;
        
    `};
     
    ${breakpoint("lg")`
        // align-items: center;
    `};

//red
    ${({ reading }) =>
		reading &&
		`
		background: rgba(162, 53, 53, 0.8);
        //  #A23535;
        // linear-gradient(127deg, #A73737 12.45%, #9C3333 100%);
	`}
//light blue
	${({ hearing }) =>
		hearing &&
		`
		background: rgba(38, 154, 242, 0.8);
        // rgba(76,177,255,1)
        //  #58E3CD
        // 269AF2;
        // linear-gradient(127deg, #4CB1FF 12.45%, #0082E5 100%);
	`}
//orange
	${({ worship }) =>
		worship &&
		`
		background: rgba(255,137,14,0.8);
        //  rgba(88, 227, 205, 0.8);
        //  #58E3CD
        // rgba(255, 137, 14, 0.8);
        // #FF890E;
        // linear-gradient(127deg, #A73737 12.45%, #9C3333 100%);
	`}
//dark night
	${({ studying }) =>
		studying &&
		`
		background: rgba(52, 60, 82, 0.8);
        // #343C52;
        // linear-gradient(127deg, #283048 12.45%, #3F485C 100%);
	`}
//grey
	${({ memorising }) =>
		memorising &&
		`
		background:  rgba(130, 139, 164, 0.8);
        // #828BA4;
        // background: linear-gradient(127deg, #76809A 12.45%, #8D96AD 100%);
	`}
//green
    ${({ meaning }) =>
		meaning &&
		`
		background: linear-gradient(127.49deg, #1D976C 12.45%, #1D976C 80%);
        //  #58E3CD
        // 269AF2;
        // linear-gradient(127deg, #4CB1FF 5.45%, #0082E5 100%);
	`}

    /* backdrop-filter: blur(84.2667px); */
    /* opacity: 80%; */
`;

const Wrapper = styled.div`
    top: 0;
    margin: 6px;

	/* width: 100%; */
	width: calc(100vw - 12px);
    min-height: 129px;

	background-color: #F4F1EE;
    border-radius: 13px;
    box-shadow: 0px 5px 10px 6px rgba(0, 0, 0, 0.1);
	color: #FFFEF2;
	position: relative;

    

	${breakpoint("md")`
        top: 0;
        margin: 0;
        width: 584px;
		// min-height: 766px;
    `};
     
    ${breakpoint("lg")`
        top: 0;
        margin: 0;
        width: 584px;
		// min-height: 766px;
    `};

`;

const MobileButton = styled.button`
    position: absolute;
    left: 10px;
    bottom: 16px;
    width: calc(100vw - 20px);
    text-align: center;

    appearance: none;
    border: none;
    background: #D8D8D8;
    border-radius: 48px;

    font-family: 'Cerebri Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 12px;
	color: #000000;

    padding: 18px 20px;

   

	cursor: pointer;

    ${breakpoint("md")`
        display: none;
    `};
    ${breakpoint("lg")`
        display: none;
    `};
`;

const Top =styled.div`
    width: 100%;
    min-height: 148px;
    height: auto;


    // temporary hid the color of tool of engagment to enable color depended on tool of engagment type
    /* background: linear-gradient(135deg, #FFAD4A 0%, #E18E45 98.16%); */
    border-radius: 13px 13px 0px 0px;

    display: grid;
    grid-template-areas: 
        "type button"
        "title title"
        "description description";

    grid-template-columns: 1fr 0;
    grid-template-rows: 24px 45px auto;

    padding: 23px 20px 17px 20px;
    margin: 0;

    ${breakpoint("md")`
        min-height: 129px;
        height: auto;
        padding: 16px 27px 17px 35px;
        
        grid-template-columns: 445px 77px;
        grid-template-rows: 32px 41px auto;
    `};

    ${breakpoint("lg")`
        min-height: 129px;
        height: auto;
        padding: 16px 27px 17px 35px;
        
        grid-template-columns: 445px 77px;
        grid-template-rows: 32px 41px auto;
    `};

    // added colours for each type of tool of engagment
    ${({ reading }) =>
		reading &&
		`
		background: linear-gradient(127.49deg, #A73737 12.45%, #9C3333 100%);
	`}
	${({ hearing }) =>
		hearing &&
		`
		background: linear-gradient(127.49deg, #4CB1FF 12.45%, #0082E5 100%);
	`}
	${({ worship }) =>
		worship &&
		`
        //orange
		background:  linear-gradient(127deg, #FF8008 12.45%, #FF9214 100%);
		// lilic
        // background: linear-gradient(127deg, #A3BFF7 12.45%, #DAE2F8 100%);
        // linear-gradient(127.49deg, #FF8008 12.45%, #FF9214 100%);
	`}
	${({ studying }) =>
		studying &&
		`
		background: linear-gradient(127.49deg, #283048 12.45%, #3F485C 100%);
	`}
	${({ memorising }) =>
		memorising &&
		`
		background:  linear-gradient(127.49deg, #76809A 12.45%, #8D96AD 100%);
	`}
    ${({ meaning }) =>
		meaning &&
		`
		background:  linear-gradient(127.49deg,#1D976C 12.45%,#93F9B9  100%);
	`}
`;

const Description = styled.p`
    grid-area: description;
    padding: 0;
    margin: 0;

    font-family: 'Cerebri Sans';
    font-size: 16px;
    line-height: 20px;
    font-style: normal;
    font-weight: normal;

    color: #F4F1EE;

    ${breakpoint("md")`
        font-size: 18px;
        line-height: 23px;
    `};
     
    ${breakpoint("lg")` 
        font-size: 18px;
        line-height: 23px;;
    `};
`;

const Type = styled.p`
    grid-area: type;

    font-family: 'Cerebri Sans';
    font-size: 16px;
    line-height: 19px;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;

    color: #E4E0DD;
    padding: 0;
    margin: 0;

    ${breakpoint("md")`
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    `};
     
    ${breakpoint("lg")`
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    `};
`;

const Title = styled.h5`
    grid-area: title;
    padding: 0;
    margin: 0;

    font-family: 'Cerebri Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;

    color: #F4F1EE;

    ${breakpoint("md")`
        font-size: 22px;
        line-height: 26px;
    `};
     
    ${breakpoint("lg")`
        font-size: 22px;
        line-height: 26px;
    `};
    
`;

const ButtonWrapper = styled.div`
	grid-area: button;
    display: none;
	${breakpoint("md")`
        display: block;
    `};

	${breakpoint("lg")`
        display: block;
    `};
`;

const Bottom = styled.div`
    width: 100%;
    
    height: calc(100vh - 40px - 80px - 148px);
    /* height: 450px; */
    padding: 24px 20px 11px 20px;
    overflow-y: scroll;
    /* overflow-x: visible; */
    overflow-wrap: anywhere;

    font-family: 'Cerebri Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    color: #111112;

    ${breakpoint("md")`
        padding: 43px 72px 10px 72px;
        // height: 637px;
        font-size: 18px;
        line-height: 25px;
    `};

    ${breakpoint("lg")`
        padding: 43px 72px 10px 72px;
        // height: 637px;
        font-size: 18px;
        line-height: 25px;
    `};
`;



export default ({ children, onClose, type, title, description, content }) => {

    // console.log(content);
	return (
		<Overlay 
            reading={type == "Reading"}
            hearing={type == "Hearing"}
            worship={type == "Worship"}
            studying={type == "Studying"}
            memorising={type == "Memorising"}
            meaning={type == "Meanings"}
            >
			<Wrapper>
                <Top
                    // passing correct tool of engagment type
                    reading={type == "Reading"}
                    hearing={type == "Hearing"}
                    worship={type == "Worship"}
                    studying={type == "Studying"}
                    memorising={type == "Memorising"}
                    meaning={type == "Meanings"}
                >
                    <Type>{type}</Type>
                    <ButtonWrapper>
                        <StyledLink onClick={onClose}>
							<Button 
								style={{
									padding: "8px 20px",
									minWidth: "77px",
									background: "#D8D8D8",
									border: "none",
                                    fontSize: "14px",
									lineHeight: "12px",
								}}>
								Close
							</Button></StyledLink>
					</ButtonWrapper>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                </Top>
                <Bottom>
                    {RichText.render(content)}
                </Bottom>

                 
			</Wrapper>
            <MobileButton  onClick={onClose}>Close</MobileButton>

		</Overlay>
	);
};
