import { useEffect } from "react";
import { withRouter } from "react-router";
import ReactGA from "react-ga4";

const GAListener = ({ children, history }) => {
  useEffect(() => {
    // Initialize Google Analytics with your GA4 Measurement ID
    ReactGA.initialize("G-J7RY6PTDXS");

    // Track the initial page load
    ReactGA.send({ hitType: "pageview", page: history.location.pathname });

    // Track subsequent page views
    return history.listen((location) => {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    });
  }, [history]);

  return children;
};

export default withRouter(GAListener);
