import BookInAPicture from "./modules/BookInAPicture";
import Picture from "./modules/Picture";
import Podcast from "./modules/Podcast";
import Prayer from "./modules/Prayer";
import React from "react";
import Text from "./modules/Text";
import VerseByVerse from "./modules/VerseByVerse";
import Video from "./modules/Video";

export default (props) => {
	let mod = (() => {
		switch (props.mod.slug) {
			case "book-in-a-picture":
				return <BookInAPicture {...props} />;
			case "podcast":
				return <Podcast {...props} />;
			case "picture":
				return <Picture {...props} />;
			case "video":
				return <Video {...props} />;
			case "text":
				return <Text {...props} />;
			case "verse_by_verse":
				return <VerseByVerse {...props} />;
			case "prayer":
				return <Prayer {...props} />;
			default:
				return <code>No module with slug '{props.mod.slug}'</code>;
		}
	})();

	return (
		<div
			style={{
				gridColumn: "1 / -1",
				// - header - footer - nav
				minHeight: "calc(100vh - 245px - 149px - 40px)",
			}}
		>
			{mod}
		</div>
	);
};
