import getBook from "../../../scripts/get/book";
import getTools from "../../../scripts/get/toolOfEngagement";
export default async (url) => {
	// /book/Book%20Title/course-name/Module%20Title

	const parts = url.split("/");

	// console.log({ url });

	const title = parts[2];
	const book = await getBook(title);

	if (parts.length < 4) {
		return { book };
	}

	const [courseSlug, moduleTitle] = parts.slice(-2);

	const course = book.courses.find(({ slug }) => slug === courseSlug),
		mod = course.modules.find(
			({ title }) => decodeURI(title) === moduleTitle
		);

	if (mod) {
		if (
			mod.tools_of_engagement.hasOwnProperty("length") &&
			mod.tools_of_engagement.length > 0
		) {
			const toolIDs = mod.tools_of_engagement.map(({ tool }) => tool.id);
			// Get tools of engagement for the module

			if (toolIDs.includes(undefined)) {
				mod.tools_of_engagement = null;
			} else {
				const tools = await getTools(toolIDs);

				mod.tools_of_engagement = tools;
			}
		}

		return { book, course, mod };
	} else {
		return null;
	}
};
