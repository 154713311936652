export default ({ book, url }) => {
	const parts = url.split("/");
	const [courseSlug, moduleTitle] = parts.slice(-2),
		courseIndex = book.courses.findIndex(({ slug }) => slug === courseSlug),
		course = book.courses[courseIndex],
		moduleIndex = course.modules.findIndex(({ title }) =>
			title.includes(moduleTitle)
		);

	if (moduleIndex + 1 < course.modules.length) {
		return [
			...parts.slice(0, -1),
			encodeURI(course.modules[moduleIndex + 1].title),
		].join("/");
	} else if (courseIndex + 1 < book.courses.length) {
		let course = book.courses[courseIndex + 1];
		return [
			...parts.slice(0, -2),
			course.slug,
			encodeURI(course.modules[0].title),
		].join("/");
	} else {
		return false;
	}
};
