import styled from "styled-components"
import React from 'react'
import breakpoint from "../breakpoint"

const randomLoadingText = ["the NWWWV - new world wide web version", "lots and lots of things", "a deeeeeeper understanding", "your digital discipleship sidekick"]

export default function Loading({ loading }) {
    const [loadingExtraText, setLoadingExtraText] = React.useState("Bible for Life")
    const [shuffledArray, setShuffledArray] = React.useState(randomLoadingText)
    React.useEffect(() => {
        let shuffled = randomLoadingText
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)
        setShuffledArray(shuffled);
        setLoadingExtraText(shuffled[0])
    }, [])
    
    return <LoadingOverlay loading={loading ? 1 : 0} opac={loading ? 1 : 0}>
        <LoadingText>
            Loading
        </LoadingText>
        <ExtraText>
            {loadingExtraText}
        </ExtraText>
    </LoadingOverlay>
}



export const LoadingText = styled.p`
    display: inline-block;
    font-family: 'Cerebri Sans';
    font-size: 64px;
    line-height: 68.58px;
`
const ExtraText = styled.p`
    display: inline-block;
    font-family: 'Cerebri Sans';
    font-size: 22px;
    line-height: 27.94px;
    text-align: center;
    padding: 0 10px;
    ${breakpoint("md")`
        padding: 0 25px;
    `}
    ${breakpoint("lg")`
        padding: 0 100px;
        max-width: 1440px;
    `}
    `


const LoadingOverlay = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: ${({ loading }) => loading ? '200' : '-200'};
    opacity: ${({ opac }) => opac || 0};
    transition: opacity z-index 0.7s ease-out;
    overflow: hidden;
    background-color: white;
`
